import styled, { css } from 'styled-components'

export const StyledFilter = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  justify-self: center;
  max-width: 1640px;
  width: 100%;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `};
  z-index: 1;
`

export const StyledFilterWrap = styled.div`
  align-items: center;
  display: flex;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledFilterList = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`

export const StyledFilterTextButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(p) => p.theme.colors.default.primary[70]};
  cursor: pointer;
  outline: none;

  &:not(:last-child) {
    margin-right: 16px;
  }
`
