import styled, { css } from 'styled-components'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { Typography } from 'shared/ui/typography'

export const CollapsibleNotificationWrapper = styled.li<{
  isCollapsed: boolean
}>`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    overflow: hidden;
  `}
  ${({ isCollapsed, theme }) =>
    isCollapsed &&
    css`
      max-height: ${theme.tokens.alias.space['space-xxxl-40']}px;
    `}
`

export const StyledNotificationPreview = styled.div`
  ${({ theme }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledNotificationLabel = styled(Typography)`
  flex-grow: 1;
`

export const StyledNotificationArrowIcon = styled(IconChevronDown)<{
  isCollapsed: boolean
}>`
  flex-shrink: 0;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.icon['icon-secondary-3']};
  `}

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      transform: scale(-1);
    `}
`

export const StyledNotificationContent = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-xxs-6']}px;
  `}
`

export const StyledNotificationText = styled.p`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-disabled']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-s-6'
    ]}px;
    padding: 10px;

    & a {
      color: ${theme.tokens.colors.text['text-linkAccent-default']};
    }
  `}
`

// ${customMedia.small} {
//   max-height: 52px;
// }
