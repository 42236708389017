import React, { FC, memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { filterRoutes, isActiveCurrentPath } from 'shared/lib/routes'

import { INavMenuItemProps, INavMenuItemRoute } from './profile-nav-menu.type'
import {
  StyledNavMenu,
  StyledNavMenuItem,
  StyledNavMenuItemCounter,
  StyledNavMenuItemLabel
} from './profile-nav-menu.styled'
import {
  PROFILE_MENU_AUTHORIZED_ROUTES_LIST,
  PROFILE_MENU_NON_AUTHORIZED_ROUTES_LIST
} from './routes'

export const ProfileNavMenu: FC<{ isAuthorized?: boolean }> = memo(
  ({ isAuthorized }) => {
    const [t] = useTranslation()

    const menuItems = useMemo(() => {
      const routeList = isAuthorized
        ? PROFILE_MENU_AUTHORIZED_ROUTES_LIST
        : PROFILE_MENU_NON_AUTHORIZED_ROUTES_LIST

      return filterRoutes({
        routeList,
        t,
        forwardProps: ({ counter }) => ({ counter })
      })
    }, [isAuthorized, t])

    return (
      <StyledNavMenu>
        {menuItems.map((item) => (
          <ProfileNavMenuItem
            counter={item.counter}
            key={item.name}
            route={item}
            {...item}
            icon={item.icon}
          />
        ))}
      </StyledNavMenu>
    )
  }
)

const ProfileNavMenuItem: FC<INavMenuItemProps<INavMenuItemRoute>> = memo(
  ({ counter: Counter, route, icon: Icon = null }) => {
    const { pathname } = useLocation()

    const isActiveItem = useMemo(
      () =>
        isActiveCurrentPath(pathname, route.path) || pathname === route.path,
      [pathname, route.path]
    )

    return (
      <StyledNavMenuItem
        $isActiveItem={isActiveItem}
        key={route.name}
        to={route.path}
      >
        {Icon && <Icon size={24} />}

        <StyledNavMenuItemLabel>{route?.translatedName}</StyledNavMenuItemLabel>

        {Counter ? (
          <Counter>
            {(count) =>
              count > 0 ? (
                <StyledNavMenuItemCounter $isActiveItem={isActiveItem}>
                  {count}
                </StyledNavMenuItemCounter>
              ) : null
            }
          </Counter>
        ) : null}
      </StyledNavMenuItem>
    )
  }
)
