import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconWarningCirle } from 'shared/ui/Icon/General/IconWarningCircle'
import { Typography } from 'shared/ui/typography'

import {
  StyledBlockScreen,
  StyledBlockScreenInner,
  StyledBlockSreenText
} from './BlockBetsScreen.styled'

export const BlockBetsScreen = () => {
  const [t] = useTranslation()

  return (
    <StyledBlockScreen>
      <StyledBlockScreenInner>
        <IconWarningCirle size={40} />
        <StyledBlockSreenText>
          <Typography color="text-primary" font="Body / 16 SemiBold">
            {t('an error has occurred')}
          </Typography>
          <Typography color="text-secondary-3" font="Body / 14 Medium">
            {t('you cannot set a limit')}
          </Typography>
        </StyledBlockSreenText>
      </StyledBlockScreenInner>
    </StyledBlockScreen>
  )
}
