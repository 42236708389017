import styled from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledFAQDetailsPage = styled.div`
  ${typography['Body / 14 Medium']}
  padding: 8px 16px 0px;

  ${({ theme }) =>
    `
      background-color: ${theme.tokens.colors.surface['surface-base']};
      color: ${theme.tokens.colors.text['text-secondary']};
  `}
`
