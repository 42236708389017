import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconEmptyPromotions } from 'shared/ui/Icon/General/IconEmptyPromotions'
import { Typography } from 'shared/ui/typography'

import {
  StyledPromotionsEmptyContent,
  StyledPromotionsEmptyDescWrapper
} from '../../promotions.styled'

export const PromotionsEmptyContent = () => {
  const [t] = useTranslation()

  return (
    <StyledPromotionsEmptyContent>
      <IconEmptyPromotions colorToken="icon-secondary-3" size={40} />
      <StyledPromotionsEmptyDescWrapper>
        <Typography color="text-primary" font="Body / 16 SemiBold">
          {t('no promotions')}
        </Typography>
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {t('past promotions will be included here')}
        </Typography>
      </StyledPromotionsEmptyDescWrapper>
    </StyledPromotionsEmptyContent>
  )
}
