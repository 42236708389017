import styled, { css } from 'styled-components'

import { typography } from '../../../../shared/lib/theme/typography'

export const LineContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const EventDate = styled.span`
  ${typography['Hint / 10 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`

export const EventTime = styled.span`
  ${typography['Body / 14 ExtraBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`
