import styled, { css } from 'styled-components'
import { Form } from 'formik'

export const StyledMyBonuses = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`

export const StyledMyBonusesFormWrapper = styled(Form)`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledInputWrapper = styled.div`
  width: 320px;
`

export const StyledMyBonusesContent = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-page']};
    overflow: auto;
    padding: ${theme.tokens.alias.space['space-l-20']}px;
  `}
`

export const StyledMyBonusesContentWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.tokens.alias.space['space-l-20']}px;
    grid-auto-rows: min-content;

    @media only screen and (max-width: 1059px) {
      grid-template-columns: repeat(1, min-content);
    }

    @media only screen and (min-width: 1060px) and (max-width: 1439px) {
      grid-template-columns: repeat(2, min-content);
    }

    @media only screen and (min-width: 1440px) and (max-width: 1819.9px) {
      grid-template-columns: repeat(3, min-content);
    }

    @media only screen and (min-width: 1820px) {
      grid-template-columns: repeat(4, min-content);
    }
  `}
`

export const StyledBonusesEmptyContent = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.tokens.colors.surface['surface-page']};
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-s-12']}px;
    height: 100%;
    justify-content: center;
  `}
`

export const StyledBonusesEmptyDescWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    justify-content: center;
    text-align: center;
    width: 227px;
  `}
`
