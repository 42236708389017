export interface CarouselProps {
  infinite?: boolean
  childHeight: number
  childMinWidth: number
  childMaxWidth?: number
  childAspectRatio?: number
  slidesGap: number
  autoplayInterval?: number
  isBlockScrolling?: boolean
  leftOffset?: number
  rightOffset?: number
  hideDots?: boolean
}

export enum ScrollDirection {
  NEXT = 1,
  PREV = -1
}

export type TCarouselDotsProps = {
  pagesToScroll: number
  pageIndex: number
}
