import styled from 'styled-components'

import { SelectDeprecated } from 'shared/ui/SelectDeprecated'

export const StyledFilters = styled.div`
  align-items: center;
  column-gap: 15px;
  display: flex;
`

export const StyledSelectPeriod = styled(SelectDeprecated)`
  width: 95px;
`

export const StyledTypesTabsWrapper = styled.div``
