import styled, { css } from 'styled-components'

import { Input } from 'shared/ui/Input'
import { typography } from 'shared/lib/theme/typography'

export const StyledResultSearch = styled.div`
  align-items: center;
  position: relative;
  width: 100%;
`

export const StyledResultSearchInput = styled(Input)`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-active']};
    border: 1px solid ${theme.tokens.colors.border['border-default-gray']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    width: 100%;

    &:focus {
      border-color: ${theme.tokens.colors.border['border-active']};
    }

    &::placeholder {
      ${typography['Body / 12 Medium']}
      color: ${theme.tokens.colors.text['text-description']};
    }
  `}
`

export const StyledIconRightButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 0;
  top: 0px;
  ${({ theme }) => css`
    padding-right: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
