export const enum EConfirmView {
  LIMIT = 'limit',
  BREAK = 'break'
}

export interface IConfirmModal {
  view: EConfirmView
  sum?: number
  period?: number
}
