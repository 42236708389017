import React from 'react'

import { StyledCap, StyledGradientRect } from './table-cap.styled'

export const TableCap = () => {
  return (
    <StyledCap>
      <svg
        fill="none"
        height="17"
        viewBox="0 0 9 17"
        width="9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M9 0H0V16.7619C0 12.2174 3.58172 8.53333 8 8.53333H9V0Z"
          fill="url(#paint0_linear_374_53148)"
          fillRule="evenodd"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_374_53148"
            x1="0"
            x2="9"
            y1="8"
            y2="8"
          >
            <stop stopColor="#222832" />
            <stop offset="1" stopColor="#1D232B" />
          </linearGradient>
        </defs>
      </svg>

      <StyledGradientRect />

      <svg
        fill="none"
        height="17"
        viewBox="0 0 9 17"
        width="9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M0 0H9V16.7619C9 12.2174 5.41828 8.53333 1 8.53333H0V0Z"
          fill="url(#paint0_linear_374_53154)"
          fillRule="evenodd"
        />
        <defs>
          <linearGradient
            gradientUnits="userSpaceOnUse"
            id="paint0_linear_374_53154"
            x1="9"
            x2="0"
            y1="8"
            y2="8"
          >
            <stop stopColor="#222832" />
            <stop offset="1" stopColor="#1D232B" />
          </linearGradient>
        </defs>
      </svg>
    </StyledCap>
  )
}
