import { FC, useEffect } from 'react'
import { useField, useFormikContext } from 'formik'

import { TextInputProps } from 'shared/ui/text-input/text-input.types'
import { TextInput } from 'shared/ui/text-input'
import { FieldProps } from 'features/formik/model/field'

export const TextInputFormik: FC<
  FieldProps<TextInputProps> & {
    preShowErrors?: boolean
    isAuthForm?: boolean
  }
> = (props) => {
  const [field, meta] = useField(props.name)

  const { setFieldTouched } = useFormikContext()

  useEffect(() => {
    setFieldTouched(props.name, true)
  }, [setFieldTouched, props.name, field.value])

  return (
    <TextInput
      {...props}
      {...field}
      error={meta.error}
      touched={meta.touched}
    />
  )
}
