import { useGetCurrencyIcon } from 'astra-core'
import { FC, useEffect } from 'react'
import { useIMask } from 'react-imask'

import { TextInput } from 'shared/ui/text-input'

import { StyledLimitInputWrapper, StyledWinCurrency } from './LimitInput.styled'

interface ILimitInput {
  errorMessage: string
  onChange: (value: string) => void
}

export const LimitInput: FC<ILimitInput> = ({ errorMessage, onChange }) => {
  const currencyIcon = useGetCurrencyIcon()

  const { ref, unmaskedValue } = useIMask({
    mask: 'num',
    radix: ',',
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: ' '
      }
    }
  })

  useEffect(() => {
    onChange(unmaskedValue)
  }, [unmaskedValue, onChange])

  useEffect(() => {
    if (errorMessage) {
      ref.current.focus()
    }
  }, [errorMessage, ref])

  return (
    <StyledLimitInputWrapper>
      <TextInput
        errorMessage={unmaskedValue ? '' : errorMessage}
        label="amount"
        placeholder="0,00"
        ref={ref}
        startAdornment={<StyledWinCurrency>{currencyIcon}</StyledWinCurrency>}
      />
    </StyledLimitInputWrapper>
  )
}
