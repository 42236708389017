import styled, { css } from 'styled-components'

export const StyledResultEmptyWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${tokens.alias.space['space-xxl-32']}px
      ${tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledResultEmptySearchValueWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${tokens.alias.space['space-xl-24']}px;

    & > button {
      width: fit-content;
    }
  `}
`

export const StyledResultEmptyDesc = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${tokens.alias.space['space-xxxs-4']}px;
    justify-content: center;
    max-width: 300px;
  `}
`
