import React from 'react'

import { Typography } from 'shared/ui/typography'

import {
  StyledDashes,
  StyledPromoCard,
  StyledPromoCardContent,
  StyledPromoCardImage,
  StyledPromoCardImageWrapper,
  StyledPromoCardTopDesc
} from './promo-card.styled'

export const PromoCard = ({ content, link, promoDate }) => {
  const { imagePath, name, description } = content

  return (
    <StyledPromoCard href={link} target="_blank">
      <StyledPromoCardImageWrapper>
        <StyledPromoCardImage alt={name} src={imagePath} />
        <StyledDashes />
      </StyledPromoCardImageWrapper>
      <StyledPromoCardContent>
        <StyledPromoCardTopDesc>
          <Typography color="text-secondary-2" font="Hint / 11 Medium">
            {promoDate}
          </Typography>
          <Typography color="text-primary" font="Title / 18 Bold">
            {name}
          </Typography>
        </StyledPromoCardTopDesc>
        <Typography color="text-primary-2" font="Body / 14 Medium">
          {description}
        </Typography>
      </StyledPromoCardContent>
    </StyledPromoCard>
  )
}
