import styled from 'styled-components'

import { StyledGameCardImg } from 'features/game-card/game-card.styled'

/* Width relative to the GameCard width */
export const StyledBoxingImg = styled(StyledGameCardImg)`
  @container game-card (width >= 98px) {
    right: -37px;
  }

  @container game-card (width >= 117px) {
    right: -29px;
  }

  @container game-card (width >= 135px) {
    right: -20px;
  }

  @container game-card (width >= 144px) {
    right: -16px;
  }

  @container game-card (width >= 147px) {
    right: -15px;
  }

  @container game-card (width >= 170px) {
    right: -2px;
  }

  @container game-card (width >= 266px) {
    right: 45px;
  }
`
