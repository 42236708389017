import styled, { css } from 'styled-components'

import { EColorsNames } from 'shared/lib/theme'

export const StyledLoaderPointsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const StyledTwainGames = styled.div`
  ${(props) => css`
    .mwBg1 {
      background-color: ${props.theme.colors.fixed[
        EColorsNames.Primary
      ][95]} !important;
      color: ${props.theme.colors.fixed[EColorsNames.Primary][30]} !important;
    }

    .lmwMenu {
      .lmwMenuIcons li span {
        border-color: ${props.theme.colors.custom[
          EColorsNames.Primary
        ][44]} !important;
      }

      .lmwStudios .lmwHead {
        color: ${props.theme.colors.default[
          EColorsNames.Primary
        ][90]} !important;
        background-color: ${props.theme.colors.custom[
          EColorsNames.Primary
        ][3]} !important;

        &.video span::before {
          color: ${props.theme.colors.fixed[
            EColorsNames.Primary
          ][3]} !important;
        }
      }

      .lmwGameItem {
        color: ${props.theme.colors.default[
          EColorsNames.Primary
        ][90]} !important;
        background-color: ${props.theme.colors.custom[
          EColorsNames.Primary
        ][3]} !important;
        border-color: ${props.theme.colors.custom[
          EColorsNames.Primary
        ][3]} !important;

        &:hover,
        &.activ {
          background-color: ${props.theme.colors.custom[
            EColorsNames.Primary
          ][4]} !important;
          border-color: ${props.theme.colors.custom[
            EColorsNames.Primary
          ][49]} !important;
        }
      }
    }

    .gameName,
    .lmwTicketBg {
      background-color: ${props.theme.colors.fixed[
        EColorsNames.Primary
      ][95]} !important;
      color: ${props.theme.colors.default[EColorsNames.Yellow][30]} !important;
    }

    .lmwPlaySection {
      background-color: ${props.theme.colors.custom[
        EColorsNames.Yellow
      ][7]} !important;
    }

    .magicMenu2 .ball {
      background-color: ${props.theme.colors.default[
        EColorsNames.Primary
      ][0]} !important;
      color: ${props.theme.colors.default[EColorsNames.Primary][90]} !important;

      &.activ {
        background-color: ${props.theme.colors.default[
          EColorsNames.Primary
        ][10]} !important;
      }
    }

    .memory22 {
      background-color: ${props.theme.colors.default[
        EColorsNames.Primary
      ][0]} !important;
    }

    .MagicListic,
    .lmwPromoTicket {
      background-color: ${props.theme.colors.custom[
        EColorsNames.Primary
      ][20]} !important;
    }

    #tmpTicketPayIn,
    #MagicBetSector {
      background-color: ${props.theme.colors.custom[
        EColorsNames.Primary
      ][1]} !important;
    }

    .magicLayOut {
      background: ${props.theme.colors.default[
        EColorsNames.Primary
      ][0]} !important;
    }

    .mwbtn {
      background-color: ${props.theme.colors.custom[
        EColorsNames.Primary
      ][50]} !important;
    }

    .mwBg1-1 {
      background-color: transparent !important;
    }
  `}
`
