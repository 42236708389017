import React, { useEffect, useMemo } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import {
  accountProviderActions,
  selectAccountId,
  selectAccountUser,
  selectCasinoToken,
  selectFetchCasinoTokenRequest,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { selectTheme } from 'shared/lib/theme/select-theme'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { StyledLoaderPointsWrapper } from './Casino.styled'
import { getIframeData } from './utils'

export const Casino = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectAccountUser)
  const accountId = useAppSelector(selectAccountId)
  const { loading: fetchUserLoading } = useAppSelector(selectFetchUserRequest)

  const { loading: fetchCasinoTokenLoading } = useAppSelector(
    selectFetchCasinoTokenRequest
  )
  const { token: casinoToken } = useAppSelector(selectCasinoToken) || {}

  useEffect(() => {
    if (accountId && !casinoToken) {
      dispatch(
        accountProviderActions.getCasinoToken({
          accountId
        })
      )
    }
  }, [accountId, dispatch, casinoToken])

  const loaded =
    fetchUserLoading === false && (!user || fetchCasinoTokenLoading === false)

  return loaded ? (
    <CasinoIframe />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const CasinoIframe = () => {
  const { token } = useAppSelector(selectCasinoToken) ?? {}
  const config = useAppSelector(selectConfig)
  const layoutTheme = useAppSelector(selectTheme)

  const iframeData = useMemo(
    () =>
      getIframeData({
        srcData: {
          launchUrl: config.CASINO_CLIENT_URL,
          customerId: config.CASINO_CUSTOMER_ID,
          token,
          layoutTheme
        }
      }),
    [config.CASINO_CLIENT_URL, config.CASINO_CUSTOMER_ID, layoutTheme, token]
  )

  return (
    <iframe
      src={iframeData.src}
      style={iframeData.styles}
      title={iframeData.id}
    />
  )
}
