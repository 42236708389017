export interface PasswordConditionalProps {
  password: string
  regExp: RegExp
  title: string
  afterSubmit?: boolean
  isPasswordStrength?: boolean | undefined
  isWeak?: boolean | undefined
  strength?: string
  touched?: boolean
}

export enum EPasswordConditional {
  DEFAULT = 'default',
  CORRECT = 'correct',
  INCORRECT = 'incorrect'
}
