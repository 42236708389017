import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ERoutes } from 'shared/types/routes'
import { LayoutProfile } from 'layouts/profile'
import { SUPPORT_CARDS } from 'widgets/support/lib'
import { FAQCategoryParams } from 'entities/faq'

import { FAQPage } from './FAQPage'

export const PageFAQ = () => {
  const { categoryId } = useParams<FAQCategoryParams>()
  const { t } = useTranslation()

  const pageTitle = t(
    SUPPORT_CARDS.find((item) => item.categoryId === +categoryId)?.name || ''
  )

  return (
    <LayoutProfile
      buttonBack={{ to: ERoutes.Support }}
      pageTitle={{ text: pageTitle }}
      isHeaderFilled
    >
      <FAQPage />
    </LayoutProfile>
  )
}

export default PageFAQ
