import React from 'react'

import { LayoutDocument } from 'layouts/document'

import { ResponsibleGamblingPage } from './ResponsibleGamblingPage'

export const PageResponsibleGambling = () => (
  <LayoutDocument pageTitle={{ text: 'responsible gambling' }} isHiddenFooter>
    <ResponsibleGamblingPage />
  </LayoutDocument>
)

export default PageResponsibleGambling
