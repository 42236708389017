import { FC, memo, useCallback, useMemo } from 'react'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { LogoEntityType } from 'betweb-openapi-axios'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { selectBroadcastAvailabilityByEventId } from 'astra-core/containers/BroadcastProvider'
import isEqual from 'react-fast-compare'

import { EntityLogo } from 'features/entity-logo'
import {
  getCompetitorsNames,
  getEventHomeAwayCompetitors
} from 'shared/lib/events'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { RootState } from 'shared/types/store'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import { Tooltip } from 'shared/ui/Tooltip'
import { AppLink } from 'shared/lib/app-link'
import {
  ETagsTop,
  entityTagsColorsSchema,
  getLinkEventPage,
  useGetEntityTagName
} from 'entities/event'
import {
  EventCardLineContent,
  EventCardLiveContent,
  Probs
} from 'features/event-card'
import { matchBroadcastModel } from 'entities/match-broadcast'
import { IconPanel12 } from 'shared/ui/Icon/General/IconPanel12'
import { FavoriteButton } from 'features/favorite-button'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './event-card.styled'
import { EventCardProps } from './event-card.types'

export const EventCard: FC<EventCardProps> = memo(
  ({ event }) => {
    const dispatch = useAppDispatch()

    const isBroadcastFeatureFlagEnabled = useFeatureFlag(
      EFeatureFlags.BROADCAST_ENABLED
    )
    const isHasBroadcast = useAppSelector((state) =>
      selectBroadcastAvailabilityByEventId(state, event.id)
    )
    const isBroadcastEnabled =
      event.live && isHasBroadcast && isBroadcastFeatureFlagEnabled

    const { code } =
      useAppSelector((state: RootState) =>
        selectSport(state, event.tournament.sportId)
      ) ?? {}
    const [tournamentRef, isTournamentOverflowing] =
      useTextOverflow<HTMLSpanElement>()
    const [homeCompetitorRef, isHomeCompetitorOverflowing] =
      useTextOverflow<HTMLDivElement>()
    const [awayCompetitorRef, isAwayCompetitorOverflowing] =
      useTextOverflow<HTMLDivElement>()

    const { homeCompetitors, awayCompetitors } = useMemo(
      () => getEventHomeAwayCompetitors(event),
      [event]
    )
    const eventLink = useMemo(
      () =>
        getLinkEventPage({
          eventId: event.id,
          isEventLive: event.live,
          tournamentSportId: event.tournament.sportId,
          tournamentId: event.tournament.id
        }),
      [event]
    )

    const homeCompetitorsNames = getCompetitorsNames(homeCompetitors)
    const awayCompetitorsNames = getCompetitorsNames(awayCompetitors)

    const entityTagName = useGetEntityTagName({ tags: event.tags })

    const handleOpenBroadcast = useCallback(
      (e) => {
        e.stopPropagation()

        dispatch(
          matchBroadcastModel.liveMatchBroadcastActions.addBroadcast({
            eventId: event.id
          })
        )
      },
      [dispatch, event.id]
    )

    return (
      <S.EventCardWrapper as={AppLink} to={eventLink} forceSelfLink>
        <S.EventCardInfo>
          {entityTagName === ETagsTop.SUPERTOP ? (
            <S.StyledLine
              entityTagColor={entityTagsColorsSchema[entityTagName]}
            >
              <S.StyledLineIndicator>{entityTagName}</S.StyledLineIndicator>
            </S.StyledLine>
          ) : (
            <div />
          )}

          <S.TournamentHeader>
            <SportIcon numericSize={12} size="s" sport={code as ESportsCodes} />

            <Tooltip
              isCommon={!isTournamentOverflowing}
              title={event.tournament.name}
            >
              <S.TournamentName ref={tournamentRef}>
                {event.tournament.name}
              </S.TournamentName>
            </Tooltip>

            <S.IconsWrapper>
              {isBroadcastEnabled && (
                <IconPanel12
                  colorToken="icon-secondary-3"
                  size={12}
                  onClick={handleOpenBroadcast}
                />
              )}

              <FavoriteButton eventId={event.id} />
            </S.IconsWrapper>
          </S.TournamentHeader>

          <S.CompetitorsWrapper>
            <S.EmblemsAndScore>
              <S.EmblemWrapper>
                {homeCompetitors[0] && (
                  <EntityLogo
                    entityId={homeCompetitors[0].id}
                    entityType={LogoEntityType.Competitor}
                    size={32}
                  />
                )}
              </S.EmblemWrapper>
              <S.EmblemWrapper>
                {awayCompetitors[0] && (
                  <EntityLogo
                    entityId={awayCompetitors[0].id}
                    entityType={LogoEntityType.Competitor}
                    size={32}
                  />
                )}
              </S.EmblemWrapper>
              <S.ComponentWrapper>
                {event.live ? (
                  <EventCardLiveContent event={event} />
                ) : (
                  <EventCardLineContent event={event} />
                )}
              </S.ComponentWrapper>
            </S.EmblemsAndScore>
            <S.Competitors>
              <Tooltip
                isCommon={!isHomeCompetitorOverflowing}
                title={homeCompetitorsNames}
              >
                <S.CompetitorName ref={homeCompetitorRef}>
                  {homeCompetitorsNames}
                </S.CompetitorName>
              </Tooltip>
              <Tooltip
                isCommon={!isAwayCompetitorOverflowing}
                title={awayCompetitorsNames}
              >
                <S.CompetitorName ref={awayCompetitorRef}>
                  {awayCompetitorsNames}
                </S.CompetitorName>
              </Tooltip>
            </S.Competitors>
          </S.CompetitorsWrapper>
        </S.EventCardInfo>

        {/* {isSuspended ? (
        <EventCardSuspended />
      ) : (
        <EventCardProbabilities event={event} />
      )} */}

        <Probs event={event} />
      </S.EventCardWrapper>
    )
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
)
