import { useEffect } from 'react'

import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import {
  selectLiveEventsLoaded,
  selectMainPageLiveEvents
} from 'pages/page-main/MainPageContainer/selectors'
import { Skeleton } from 'shared/ui/skeleton'
import { EventsTableLoader, EventsTable } from 'widgets/events-table'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { NothingFound } from '../../live-events.styled'

export const MainPageEventsTable = () => {
  const dispatch = useAppDispatch()

  const events = useAppSelector(selectMainPageLiveEvents)
  const isLoaded = useAppSelector(selectLiveEventsLoaded)

  useEffect(() => {
    return () => {
      dispatch(
        mainPageContainerActions.setLiveEventsFilters({
          key: 'isTop',
          value: false
        })
      )
    }
  }, [dispatch])

  const onResetFilters = () => {
    dispatch(mainPageContainerActions.toggleFilterIsBroadcast(false))
    dispatch(
      mainPageContainerActions.setLiveEventsFilters({
        key: 'isTop',
        value: false
      })
    )
  }

  return (
    <Skeleton
      count={3}
      isLoading={!isLoaded}
      styledFallback={<EventsTableLoader />}
    >
      {events.length ? (
        <EventsTable
          events={events}
          headerProps={{ withIcon: true }}
          sortByTags
        />
      ) : (
        <NothingFound onClickReset={onResetFilters} />
      )}
    </Skeleton>
  )
}
