import styled from 'styled-components'

export const StyledLiveResultsWrapper = styled.div`
  position: relative;
`

export const StyledLoadingIndicatorLiveResultsWrapper = styled.div`
  inset: 0;
  position: absolute;
`
