import styled, { css } from 'styled-components'

import { ColumnLayoutContent } from 'shared/lib/layout'

export const Content = styled(ColumnLayoutContent)`
  grid-template-rows: auto minmax(0, 1fr) !important;
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
  `}
`
