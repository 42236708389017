import { BetStatus } from 'betweb-openapi-axios'

export const SECTION_SELECT_OPTIONS = [
  {
    titleKey: 'current',
    value: BetStatus.Current
  },
  {
    titleKey: 'history computed',
    value: BetStatus.Computed,
    isActive: (value: BetStatus) =>
      [
        BetStatus.Won,
        BetStatus.Computed,
        BetStatus.Lost,
        BetStatus.Returned
      ].includes(value)
  }
]
