import React, { FC, PropsWithChildren } from 'react'

import { NotificationsPanel } from 'widgets/notifications-panel'
import { SportsMenu } from 'widgets/sports-menu'
import { FooterOfPage } from 'widgets/footer-of-page'
import { ColumnLayoutScroll } from 'shared/lib/layout'

import * as S from './game.styled'

export const Game: FC<PropsWithChildren> = ({ children }) => (
  <S.Layout>
    <SportsMenu />

    <NotificationsPanel />
    <ColumnLayoutScroll>
      {children}

      <FooterOfPage />
    </ColumnLayoutScroll>
  </S.Layout>
)
