import { IconShowEye } from 'shared/ui/Icon/General/IconShowEye'
import { IconHideEye } from 'shared/ui/Icon/General/IconHideEye'

import { StyledIconRightButton } from './show-password.styled'

export const ShowPassword = ({ value, onClick }) => {
  return (
    <StyledIconRightButton onClick={onClick}>
      {value ? (
        <IconHideEye colorToken="icon-secondary-2" size={16} />
      ) : (
        <IconShowEye colorToken="icon-secondary-2" size={16} />
      )}
    </StyledIconRightButton>
  )
}
