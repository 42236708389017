import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const NothingFoundContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column; /* For correct wrap text by design */
  max-width: 27.5rem;
`

export const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    margin-bottom: ${theme.tokens.alias.space['space-xxxs-4']}px;
    text-align: center;

    ${typography['Body / 16 SemiBold']};
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    margin-bottom: ${theme.tokens.alias.space['space-m-16']}px;
    ${typography['Body / 14 Medium']};
    text-align: center;
  `}
`
