import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBalance: FC<IconProps> = (props) => (
  <Icon {...props} size={24}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.99994 5C3.89537 5 2.99994 5.89543 2.99994 7V17.4872H3.02454C3.07666 17.7622 3.21033 18.0175 3.41113 18.2183L4.78168 19.5888C5.04492 19.8521 5.40198 20 5.77429 20H18.2257C18.598 20 18.9551 19.8521 19.2183 19.5888L20.5889 18.2183C20.8403 17.9669 20.9521 17.6543 21.0001 17.3114V10.7137C20.9569 10.3609 20.8478 10.0406 20.5889 9.78171L19.2183 8.41116C18.9551 8.14789 18.598 8 18.2257 8H7.49994C7.2238 8 6.99994 7.77614 6.99994 7.5C6.99994 7.22386 7.2238 7 7.49994 7H18.4999C18.7761 7 18.9999 6.77614 18.9999 6.5V5.5C18.9999 5.22386 18.7761 5 18.4999 5H4.99994ZM18 15C18.5523 15 19 14.5523 19 14C19 13.4477 18.5523 13 18 13C17.4477 13 17 13.4477 17 14C17 14.5523 17.4477 15 18 15Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
