import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconFAQ: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7ZM7.00002 2.51937C5.48338 2.51937 4.25391 3.74885 4.25391 5.26549C4.25391 5.69882 4.60519 6.0501 5.03851 6.0501C5.47184 6.0501 5.82312 5.69882 5.82312 5.26549C5.82312 4.6155 6.35004 4.08858 7.00002 4.08858C7.65001 4.08858 8.17693 4.6155 8.17693 5.26549C8.17693 5.76065 8.00627 5.97268 7.85022 6.1052C7.75402 6.18688 7.64096 6.25464 7.50519 6.32567C7.48885 6.33421 7.46715 6.34515 7.44187 6.35789C7.3322 6.41315 7.15326 6.50333 7.03333 6.58928C6.60208 6.89836 6.40286 7.31819 6.31076 7.62058C6.26387 7.77451 6.24048 7.91103 6.22856 8.01137L6.21542 8.20776C6.21542 8.64108 6.5667 8.99236 7.00002 8.99236C7.42892 8.99236 7.77744 8.64823 7.78452 8.22102C7.78473 8.21751 7.78533 8.20898 7.78681 8.19651C7.79021 8.16787 7.79747 8.12511 7.81188 8.07778C7.84238 7.97766 7.88835 7.9071 7.94747 7.86473C7.95184 7.86208 7.97119 7.85044 8.01759 7.82624C8.0433 7.81283 8.07107 7.79875 8.10543 7.78132L8.1119 7.77804C8.14773 7.75987 8.18914 7.73883 8.23258 7.7161C8.40567 7.62555 8.63838 7.49456 8.86594 7.30133C9.35737 6.88402 9.74614 6.24152 9.74614 5.26549C9.74614 3.74885 8.51666 2.51937 7.00002 2.51937ZM7.85189 10.6284C7.85189 11.0991 7.47033 11.4806 6.99965 11.4806C6.52898 11.4806 6.14742 11.0991 6.14742 10.6284C6.14742 10.1577 6.52898 9.77615 6.99965 9.77615C7.47033 9.77615 7.85189 10.1577 7.85189 10.6284Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
