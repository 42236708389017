import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { Button } from 'shared/ui/Button'

export const StyledProfileContentWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: grid;
    grid-template-rows: repeat(2, min-content);
    padding: ${theme.tokens.alias.space['space-m-16']}px;
    row-gap: ${theme.tokens.alias.space['space-xl-24']}px;
  `}
`

export const StyledContentDataWrapper = styled.div`
  ${({ theme }) => css`
    column-gap: ${theme.tokens.alias.space['space-xxxl-40']}px;
    display: grid;
    grid-template-columns: repeat(2, min-content);
  `}
`

export const StyledDataWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    row-gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    white-space: nowrap;
  `}
`

export const StyledDataTitle = styled.div`
  ${typography['Body / 12 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`
export const StyledDataValue = styled.div`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledProfileChangePasswordButton = styled(Button)`
  justify-self: baseline;
`
