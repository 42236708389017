import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconTabbarLineFilled24: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M20.414 20.5858C20.7892 20.2107 21 19.702 21 19.1716V4.82837C21 4.29797 20.7893 3.78931 20.4143 3.41418L18.5858 1.58582C18.2107 1.21069 17.702 1 17.1716 1H6.82976C6.29915 1 5.79027 1.21069 5.41505 1.58582L3.586 3.41418C3.2108 3.78931 3.00002 4.29797 3.00002 4.82837L3 19.1716C3 19.702 3.21071 20.2107 3.58578 20.5858L5.41422 22.4142C5.78929 22.7893 6.29799 23 6.82843 23H17.1703C17.7009 23 18.2098 22.7893 18.585 22.4142L20.414 20.5858ZM8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44771 8 8 8H16C16.5523 8 17 7.55228 17 7C17 6.44772 16.5523 6 16 6H8ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44771 12 8 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H8ZM7 15C7 14.4477 7.44772 14 8 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H8C7.44771 16 7 15.5523 7 15Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
