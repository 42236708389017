import styled, { css } from 'styled-components'

export const StyledResultSetsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const StyledResultCancelled = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding-right: ${tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledResultPeriodScoreWrapper = styled.div<{ isTitle?: boolean }>`
  ${({ theme: { tokens }, isTitle }) => css`
    align-items: center;
    display: flex;
    justify-content: center;

    ${!isTitle &&
    css`
      ${StyledResultPeriodScore}:last-child {
        box-shadow: ${tokens.colors.divider['divider-primary']} -2px 0px 0px -1px
          inset;
      }
    `}
  `}
`

export const StyledResultTotalScore = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${tokens.alias.space['space-none']}px
      ${tokens.alias.space['space-xxxxs-2']}px;
    width: 43px;
  `}
`

export const StyledResultPeriodScore = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${tokens.alias.space['space-none']}px
      ${tokens.alias.space['space-xxxxs-2']}px;
    width: 34px;
  `}
`

export const StyledResultCurrentPeriodScore = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    background-color: ${tokens.colors.surface['surface-default']};
    border-radius: ${tokens.alias.space['space-xxxs-4']}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${tokens.alias.space['space-none']}px
      ${tokens.alias.space['space-xxs-6']}px;
  `}
`

export const StyledCurrentServerPointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5px 0;
`

export const CurrentServerPoint = styled.div<{ isVisible: boolean }>`
  background-color: #0d80f2;
  border-radius: 50%;
  height: 4px;
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  width: 4px;
`
