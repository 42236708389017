import styled, { css } from 'styled-components'
import { Form } from 'formik'

import { typography } from 'shared/lib/theme/typography'
import { EColorsTypes } from 'shared/lib/theme'

export const StyledContainer = styled.section`
  * ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) =>
      props.theme.colors[EColorsTypes.DEFAULT].primary[0]};
    border: 3px solid transparent;
    border-radius: 6px;
  }

  * ::-webkit-scrollbar-thumb:vertical {
    min-height: 50px;
  }

  * ::-webkit-scrollbar {
    background-color: ${(props) =>
      props.theme.colors[EColorsTypes.CUSTOM].primary[0]};
    height: 12px;
    width: 12px;
  }

  padding: 0 32px;

  ${({ theme }) => css`
    margin-top: ${theme.tokens.alias.space['space-xl-24']}px;
  `}
`

export const FormWrapper = styled(Form)`
  display: grid;
  grid-auto-rows: min-content;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
  `}
`

export const DescriptionText = styled.span`
  ${typography['Body / 14 SemiBold']}
  font-weight: 500;
  white-space: pre-line;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
  `}
`

export const InputsWrapper = styled.div`
  display: grid;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const NewPasswordInputWrapper = styled.div`
  display: grid;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`
