import { useTranslation } from 'react-i18next'
import { memo, useCallback, useEffect } from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import {
  getFilterIsBroadcast,
  selectMainPageLiveEvents,
  selectLiveEventsFilters,
  selectIsExistBroadcastForCurrentEvents
} from 'pages/page-main/MainPageContainer/selectors'
import {
  StyledFiltersButtonWrapper,
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper
} from 'pages/page-main/Main/components/live-events/live-events.styled'
import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import { IconVideoFilled } from 'shared/ui/Icon/General/IconVideoFilled'
import { useIsEventListHasTop } from 'entities/event'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const MainPageEventsHeader = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isFeatureFlagBroadcastEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )
  const isBroadcast = useAppSelector(getFilterIsBroadcast)

  const isBroadcastFilterButtonEnabled = useAppSelector(
    selectIsExistBroadcastForCurrentEvents
  )

  const toggleFilterBroadcast = useCallback(() => {
    dispatch(mainPageContainerActions.toggleFilterIsBroadcast())
  }, [dispatch])

  return (
    <StyledMainPageEventsHeaderWrapper>
      <StyledMainPageEventsHeader>
        {t('live events')}
      </StyledMainPageEventsHeader>

      <StyledFiltersButtonWrapper>
        {isFeatureFlagBroadcastEnabled && (
          <Button
            disabled={!isBroadcastFilterButtonEnabled}
            icon={IconVideoFilled}
            isActive={isBroadcast}
            view="filter"
            onClick={toggleFilterBroadcast}
          >
            {t('with broadcast')}
          </Button>
        )}

        <MainPageEventsHeaderTopButton />
      </StyledFiltersButtonWrapper>
    </StyledMainPageEventsHeaderWrapper>
  )
})

export const MainPageEventsHeaderTopButton = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const events = useAppSelector(selectMainPageLiveEvents)
  const filters = useAppSelector(selectLiveEventsFilters)

  const { isEventListHasTop } = useIsEventListHasTop(events)

  const handleFiltersChange = useCallback(
    (key: keyof typeof filters, value: boolean) => () => {
      dispatch(mainPageContainerActions.setLiveEventsFilters({ key, value }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (filters.isTop && !isEventListHasTop) {
      handleFiltersChange('isTop', !filters.isTop)
    }
  }, [filters.isTop, isEventListHasTop, handleFiltersChange])

  return (
    <Button
      disabled={!isEventListHasTop}
      icon={IconTop}
      isActive={filters.isTop}
      view="filter"
      onClick={handleFiltersChange('isTop', !filters.isTop)}
    >
      {t('only top')}
    </Button>
  )
}
