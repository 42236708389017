import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledCardWrapper = styled.div`
  ${typography['Body / 14 Medium']}
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};

    & a {
      color: ${theme.tokens.colors.text['text-linkAccent-default']};
      cursor: pointer;
    }

    & h6 {
      ${typography['Body / 14 SemiBold']}
      color: ${theme.tokens.colors.text['text-primary-2']};
    }
  `}

  & ol li {
    list-style-type: decimal;
    list-style-position: inside;
  }

  & ul li {
    list-style-type: disc;
    list-style-position: inside;

    & > span {
      margin-left: -6px;
    }
  }

  & p > a {
    display: contents;
  }
`

export const StyledCardToggler = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledCardImg = styled.img`
  border-radius: 8px;
`

export const StyledCardSVG = styled.div<{
  src: string
}>`
  ${({ src, theme }) => css`
    background-image: url(${src});
    background-size: cover;
    border-radius: 8px;
    height: 420px;
    outline: 1px solid ${theme.tokens.colors.border['border-header']};
    outline-offset: -1px;
    width: 688px;
  `}
`

export const StyledCardBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledCardLinks = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`
