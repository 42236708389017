import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IconPhone } from 'shared/ui/Icon/General/IconPhone'
import { Typography } from 'shared/ui/typography'
import { IconMail24 } from 'shared/ui/Icon/General/IconMail24'
import { IconDocFilled } from 'shared/ui/Icon/General/IconDocFilled'
import { IconNotificationFilled24 } from 'shared/ui/Icon/General/IconNotificationFilled24'
import { ERoutes } from 'shared/types/routes'

import {
  StyledSupportCard,
  StyledSupportHeader,
  StyledSupportLinkDescr
} from './support-header.styled'

export const SupportHeader = () => {
  const [t] = useTranslation()

  return (
    <StyledSupportHeader>
      <StyledSupportCard as="a" href="tel:7757">
        <StyledSupportLinkDescr>
          <IconPhone size={12} />
          {t('support.call.description')}
        </StyledSupportLinkDescr>
        <Typography color="text-primary" font="Body / 16 SemiBold">
          7757
        </Typography>
      </StyledSupportCard>

      <StyledSupportCard as="a" href="mailto:info@olimpbet.kz">
        <StyledSupportLinkDescr>
          <IconMail24 size={12} />
          {t('support.email.description')}
        </StyledSupportLinkDescr>
        <Typography color="text-primary" font="Body / 16 SemiBold">
          info@olimpbet.kz
        </Typography>
      </StyledSupportCard>

      <StyledSupportCard
        as="button"
        type="button"
        onClick={() => window.LC_API.open_chat_window()}
      >
        <StyledSupportLinkDescr>
          <IconNotificationFilled24 size={12} />
          {t('support.chat.description')}
        </StyledSupportLinkDescr>
        <Typography color="text-primary" font="Body / 16 SemiBold">
          {t('support.chat.heading')}
        </Typography>
      </StyledSupportCard>

      <StyledSupportCard as={NavLink} to={ERoutes.Documents}>
        <StyledSupportLinkDescr>
          <IconDocFilled size={12} />
          {t('support.docs.description')}
        </StyledSupportLinkDescr>
        <Typography color="text-primary" font="Body / 16 SemiBold">
          {t('support.docs.heading')}
        </Typography>
      </StyledSupportCard>
    </StyledSupportHeader>
  )
}
