import React from 'react'
import { useTranslation } from 'react-i18next'

import { Results } from 'widgets/results'
import {
  ColumnLayout,
  ColumnLayoutContent,
  ColumnTypes
} from 'shared/lib/layout'
import { ResultsMenu } from 'widgets/results-menu'
import { NotificationsPanel } from 'widgets/notifications-panel'
import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { ResultHeader } from 'widgets/results/components'
import { FooterOfPage } from 'widgets/footer-of-page'
import { Basket } from 'widgets/basket'

import * as S from './page.styled'

export const PageResults = () => {
  const [t] = useTranslation()

  return (
    <ColumnLayout type={ColumnTypes.THREE_COLUMN_WIDE_FILTER}>
      <ResultsMenu />

      <NotificationsPanel />

      <TitleLinePage
        gapSize={16}
        title={t('results')}
        titleFont="Title / 24 Bold"
      >
        <ResultHeader />
      </TitleLinePage>

      <S.LayoutScroll>
        <ColumnLayoutContent isHideHeader>
          <Results />

          <FooterOfPage />
        </ColumnLayoutContent>
      </S.LayoutScroll>

      <Basket />
    </ColumnLayout>
  )
}
export default PageResults
