import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledLimitInputWrapper = styled.div`
  width: 200px;
`

export const StyledWinCurrency = styled.div`
  ${typography['Body / 14 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary-2']};
    margin-right: 4px;
  `}
`
