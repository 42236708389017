import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { EColorsNames } from 'shared/lib/theme'

import {
  StyledIconClose,
  StyledIconTutorial,
  StyledNotification,
  StyledNotificationLink,
  StyledNotificationModalWindowText,
  StyledNotificationText,
  StyledNotificationWrapper
} from './Notification.styled'
import { NotificationProps } from './Notification.types'

export const Notification: FC<NotificationProps> = ({
  text,
  linkText,
  linkUrl = '/',
  isIconCloseShown,
  colors,
  modalWindowAction,
  ...props
}) => {
  const { t } = useTranslation()
  const { typeLayoutTheme } = useTheme()

  return (
    <StyledNotificationWrapper {...props}>
      <StyledNotification backgroundColor={colors?.backgroundColor}>
        <StyledIconTutorial
          colorProps={
            colors?.iconTutorialColor ||
            (typeLayoutTheme === LayoutTheme.Dark
              ? { name: EColorsNames.Primary, value: 90 }
              : { name: EColorsNames.Blue, value: 51 })
          }
        />
        <StyledNotificationText textColor={colors?.textColor}>
          {t(text)}{' '}
          {!!linkText &&
            (!modalWindowAction ? (
              <StyledNotificationLink to={linkUrl}>
                {t(linkText)}
              </StyledNotificationLink>
            ) : (
              <StyledNotificationModalWindowText
                textColor={colors?.linkColor}
                onClick={modalWindowAction}
              >
                {t(linkText)}
              </StyledNotificationModalWindowText>
            ))}
        </StyledNotificationText>

        {isIconCloseShown && (
          <StyledIconClose
            colorProps={{ name: EColorsNames.Primary, value: 40 }}
            size={10}
            onClick={() => {}}
          />
        )}
      </StyledNotification>
    </StyledNotificationWrapper>
  )
}
