import styled, { css } from 'styled-components'

import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledNotifications = styled.main`
  display: flex;
  flex-direction: column;
`

export const NotificationsTabsWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px ${theme.tokens.colors.border['border-default-gray']};
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px;
    position: sticky;
    top: 112px;
    z-index: 20;
  `}
`

export const NotificationContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledDataList = styled(DataListWithReduxPagination)`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`
