import styled, { css } from 'styled-components'

export const StyledPromoCard = styled.a`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    display: flex;
    flex-direction: column;
    width: 100%;
  `}
`

export const StyledPromoCardImageWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    position: relative;
  `}
`

export const StyledDashes = styled.div`
  ${({ theme }) => css`
    background: repeating-linear-gradient(
      to right,
      ${theme.tokens.colors.border['border-primary']},
      ${theme.tokens.colors.border['border-primary']} 6px,
      transparent 6px,
      transparent 12px
    );
    bottom: 0;
    height: 1px;
    left: 9px;
    position: absolute;
    width: 100%;
  `}
`

export const StyledPromoCardImage = styled.img`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.border['border-primary']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
  `}

  height: 144px;
  width: 344px;
`

export const StyledPromoCardContent = styled.div<{ isMask?: boolean }>`
  ${({ theme, isMask }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-s-12']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-m-16']}px;
    position: relative;

    ::before,
    ::after {
      background: ${theme.tokens.colors.surface['surface-page']};
      border-radius: 50%;
      content: '';
      height: 12px;
      position: absolute;
      width: 12px;
      z-index: 1;
    }

    ::before {
      left: -6px;
      top: -6px;
    }

    ::after {
      right: -6px;
      top: -6px;
    }

    ${isMask &&
    css`
      span {
        color: ${theme.tokens.colors.text['text-disabled']};
      }
    `}
  `}
`

export const StyledPromoCardTopDesc = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`
