import styled, { css } from 'styled-components'

export const StyledBlockScreen = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
  `}
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 16px;
`

export const StyledBlockScreenInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
`

export const StyledBlockSreenText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
`
