import { selectLoyaltyCouponCreatedStatusLength } from 'astra-core/containers/BasketProvider'

import { INavMenuItemCounter } from 'features/profile-nav-menu/profile-nav-menu.type'
import { useAppSelector } from 'shared/lib/@reduxjs'

export const MyBonusesCounter: INavMenuItemCounter = ({ children }) => {
  const bonusCouponsLength = useAppSelector(
    selectLoyaltyCouponCreatedStatusLength
  )

  const count = bonusCouponsLength || 0

  return children ? children(count) : null
}
