import { EChangePassword } from 'astra-core/containers/AccountProvider'
import * as Yup from 'yup'
import { TFunction } from 'i18next'

import {
  EPasswordStrength,
  passwordStrength,
  REG_PASSWORD_SEPARATED
} from 'shared/lib/validation'

const createPasswordValidation = (bannedPassword: string[], t: TFunction) =>
  Yup.string()
    .required('field required')
    .test(
      'wrong letters',
      (d) =>
        t('incorrect password symbol', {
          symbol: d.originalValue?.match(
            REG_PASSWORD_SEPARATED.invalidSymbols
          )?.[0]
        }),
      (value) => !value?.match(REG_PASSWORD_SEPARATED.invalidSymbols)?.[0]
    )
    .matches(REG_PASSWORD_SEPARATED.allAbove, ' ')
    .test('password strength', '', (value) => {
      return (
        passwordStrength(value as string, bannedPassword) !==
        EPasswordStrength.WEAK
      )
    })

export const CHANGE_PASSWORD_FORMIK_SCHEMA = (
  bannedPassword: string[],
  t: TFunction
) =>
  Yup.object({
    [EChangePassword.OLD]: Yup.string().required('field required'),
    [EChangePassword.RENEWED]: createPasswordValidation(bannedPassword, t)
  })
