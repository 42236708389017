import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconProfile: FC<IconProps> = (props) => (
  <Icon {...props} size={24}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4011 3.5C9.19809 3.5 8.26732 4.55436 8.41653 5.74807L8.88244 9.47534C9.00755 10.4762 9.85835 11.2273 10.867 11.2273H13.1313C14.14 11.2273 14.9908 10.4762 15.1159 9.47534L15.5818 5.74807C15.731 4.55436 14.8002 3.5 13.5972 3.5H10.4011Z"
        fill="currentColor"
      />
      <path
        d="M4.05472 16.0571C3.6987 16.4293 3.5 16.9245 3.5 17.4396V18.5007C3.5 19.6053 4.39543 20.5007 5.5 20.5007H18.5C19.6046 20.5007 20.5 19.6053 20.5 18.5007V17.4396C20.5 16.9245 20.3013 16.4293 19.9453 16.0571L17.3951 13.391C17.0177 12.9965 16.4956 12.7734 15.9498 12.7734H8.05023C7.50438 12.7734 6.98225 12.9965 6.60495 13.391L4.05472 16.0571Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
