import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { NothingFound as NothingFoundFromPage } from 'pages/page-main/ui/nothing-found'

export const StyledMainPageEventsWrapper = styled.div`
  display: grid;
`

export const StyledMainPageEventsHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  ${({ theme: { tokens } }) => css`
    gap: ${tokens.alias.space['space-xs-8']}px;
    padding: ${tokens.alias.space['space-xl-24']}px
      ${tokens.alias.space['space-m-16']}px
      ${tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledMainPageEventsHeader = styled.div`
  flex-grow: 1;
  ${typography['Title / 20 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const StyledSportListWrapper = styled.div`
  overflow: auto;
  padding-bottom: 10px;
`

export const StyledFiltersButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const NothingFound = styled(NothingFoundFromPage)`
  ${({ theme }) => css`
    margin: ${theme.tokens.alias.space['space-m-16']}px auto;
  `}
`
