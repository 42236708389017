import {
  selectBetsHistoryDataBetsHasMore,
  selectBetsHistoryPagination,
  selectBetsHistoryDataBetsFetchItems,
  betsHistoryProviderActions
} from 'astra-core/containers/BetsHistoryProvider'

import { FooterOfPage } from 'widgets/footer-of-page'
import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS = {
  setPaginationAction: betsHistoryProviderActions.setPagination,
  selectorHasMore: selectBetsHistoryDataBetsHasMore,
  selectorPagination: selectBetsHistoryPagination,
  selectorRequestStatus: selectBetsHistoryDataBetsFetchItems,
  bottomComponent: <FooterOfPage />
} as DataListWithReduxPaginationProps['pagination']
