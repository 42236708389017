import styled from 'styled-components'

import { StyledGameCardImg } from 'features/game-card/game-card.styled'

export const StyledNHL21Img = styled(StyledGameCardImg)`
  @container game-card (width >= 98px) {
    right: -45px;
  }

  @container game-card (width >= 117px) {
    right: -37px;
  }

  @container game-card (width >= 135px) {
    right: -28px;
  }

  @container game-card (width >= 144px) {
    right: -23px;
  }

  @container game-card (width >= 147px) {
    right: -22px;
  }

  @container game-card (width >= 170px) {
    right: -10px;
  }

  @container game-card (width >= 266px) {
    right: 38px;
  }
`
