import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'

export const StyledDocumentsPage = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
`

export const StyledDocumentsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  max-width: 1608px;
  width: 100%;

  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledDocumentsLink = styled(AppLink)`
  ${typography['Body / 16 Bold']}
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-card-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;

    color: ${theme.tokens.colors.text['text-primary-2']};
    gap: ${theme.tokens.alias.space['space-xs-8']}px;

    padding: 18px ${theme.tokens.alias.space['space-m-16']}px;

    &:hover {
      background: ${theme.tokens.colors.surface['surface-base']};
    }
  `}
`
