import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledInfoTabWrapper = styled.div`
  padding: 16px;
`

export const StyledInfoTabContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 720px;
`

export const StyledInfoTabContainer = styled.div`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    border-radius: 8px;
    color: ${theme.tokens.colors.text['text-primary-2']};
    outline: 1px solid ${theme.tokens.colors.border['border-primary']};
    outline-offset: -1px;
    padding: 16px;
  `}
`

export const StyledInfoTabMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const StyledInfoTabText = styled.div`
  ${typography['Body / 14 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
  `}
`

export const StyledAccordionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
