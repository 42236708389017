import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { typography } from 'shared/lib/theme/typography'

export const EventCardWrapper = styled.div`
  display: grid !important;
  grid-template-rows: 116px auto;
  margin-top: 2px;
  transition: background-color 0.25s ease;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-card-default']};
    border-radius: ${theme.tokens.alias.space['space-xs-8']}px;
    &:hover {
      background: ${theme.tokens.colors.surface['surface-base']};
    }
  `}
`

export const TournamentHeader = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  overflow: hidden;
`

export const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
`

export const EventCardMetaWrapper = styled.div`
  display: grid;
`

export const EventCardInfo = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-rows: 12px auto 1fr;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    padding-top: 0;
  `}
`

export const EventCardTournament = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: grid;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    grid-template-columns: auto minmax(0, 1fr) auto;
    svg {
      height: 12px;
      width: 12px;
    }
  `}
`

export const TournamentName = styled.span`
  ${typography['Hint / 11 Medium']};
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const CompetitorsWrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    margin-top: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const EmblemsAndScore = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
`

export const Competitors = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const CompetitorName = styled.div`
  ${typography['Body / 12 SemiBold']}
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-align: center;
  text-overflow: ellipsis;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const ComponentWrapper = styled.div`
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate3d(-50%, 0, 0);
`

export const EmblemWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const EventCardHeaderWrapper = styled.div`
  display: grid;
  padding: 18px 1rem 8px 1rem;
`

export const EventCardHeader = styled.div`
  align-items: center;
  display: grid;
  font-size: ${(props) => props.theme.fonts.size.s};
  grid-template-columns: minmax(0, 1fr) auto;
  padding-bottom: 4px;
`

export const StyledEventCardCompetitor = styled.div<{
  isLongTeamName?: boolean
}>`
  ${(props) => css`
    align-items: center;
    color: ${props.theme.colors.custom.primary[11]};
    column-gap: 0.25rem;
    display: grid;
    font-size: ${props.theme.fonts.size[props.isLongTeamName ? 's' : 'm']};
    font-weight: ${props.theme.fonts.weight.semiBold};
    grid-template-columns: auto 1fr;
    height: 1.25rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    ${props.isLongTeamName &&
    css`
      ::after {
        background: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
          ? `linear-gradient(
        270deg,
        #262626 0%,
        rgba(38, 38, 38, 0.88) 59.9%,
        rgba(38, 38, 38, 0) 100%
      )`
          : `linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.88) 59.9%, rgba(255, 255, 255, 0) 100%);`};
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 15px;
      }
    `}
  `}
`

export const StyledEventCardDate = styled.div`
  color: ${(props) => props.theme.colors.default.primary[50]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.xxs};
`

export const AdditionalInfoWrapper = styled.div`
  padding: 0 0 0 1rem;
`

export const CurrentServerFlag = styled.div`
  background-color: ${(props) => props.theme.colors.default.green[50]};
  border-radius: 1px;
  flex-shrink: 0;
  height: 100%;
  width: 2px;
`

export const StyledLine = styled.div<{ entityTagColor: string }>`
  ${({ entityTagColor }) => css`
    /* background: ${entityTagColor}; */

    display: flex;
    justify-content: center;
    margin-top: -2px;
    position: relative;
    width: 100%;

    &::before {
      background: ${entityTagColor};
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      content: '';
      height: 2px;
      position: absolute;
      width: 100%;
    }

    ${StyledLineIndicator} {
      background: ${entityTagColor};

      &::before {
        box-shadow: 1px -5px ${entityTagColor};
      }

      &::after {
        box-shadow: -1px -5px ${entityTagColor};
      }
    }
  `}
`
export const StyledLineBorder = styled.div`
  width: 100%;
`

export const StyledLineIndicator = styled.div`
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;

  ${({ theme }) => css`
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;
    color: ${theme.tokens.colors.text['text-alwaysWhite']};
    height: 12px;
    padding: 0 4px;
    position: relative;
    width: fit-content;
    z-index: 1;

    &::before {
      border-top-right-radius: 5px;
      content: '';
      height: 10px;
      left: -10px;
      position: absolute;
      top: 2px;
      width: 10px;
    }

    &::after {
      border-top-left-radius: 5px;
      content: '';
      height: 10px;
      position: absolute;
      right: -10px;
      top: 2px;
      width: 10px;
    }
  `}
`
