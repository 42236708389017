import React from 'react'
import { useTranslation } from 'react-i18next'

import { ServicePageContent } from 'features/service-page-content'
import { EServicePage } from 'features/service-page-content/constants'
import { Button } from 'shared/ui/Button'

export const UnderConstructionPage = () => {
  const [t] = useTranslation()

  const handleBackButton = () => {
    window.history.back()
  }

  return (
    <ServicePageContent variant={EServicePage.NotFound}>
      <Button view="secondary-extra-large" onClick={handleBackButton}>
        {t('go back')}
      </Button>
    </ServicePageContent>
  )
}
