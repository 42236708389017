import { EResponsibleGambling } from './types'

export const SECTION_SELECT_OPTIONS = [
  {
    titleKey: 'responsible gaming',
    value: EResponsibleGambling.INFO
  },
  {
    titleKey: 'betting limit',
    value: EResponsibleGambling.LIMIT
  },
  {
    titleKey: 'game break',
    value: EResponsibleGambling.BREAK
  }
]
