import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { AvailablePanel } from 'shared/ui/AvailablePanel'
import { LayoutGame } from 'layouts/game'

import { LiveGames } from './LiveGames'

export const PageGamesLive = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.LiveGames)
  return (
    <LayoutGame>{isAvailable ? <LiveGames /> : <AvailablePanel />}</LayoutGame>
  )
}

export default PageGamesLive
