import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { IconProps } from '../../types'

export const IconCheckOutline: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.6507 6.75924L7.33325 11.3171L3.34912 7.90209L4.6507 6.38358L7.33325 8.6829L11.3491 5.24072L12.6507 6.75924Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
