import React, { useCallback } from 'react'

import { selectFavouriteEventsFilters } from 'pages/page-favourite-events/FavouriteEventsContainer/selectors'
import { favouriteEventsContainer } from 'pages/page-favourite-events/FavouriteEventsContainer/slice'
import { SegmentedControl } from 'shared/ui/segmented-control'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { FILTER_SPORTS_TYPES_TABS } from './constants'

export const SportsTypesTabs = () => {
  const dispatch = useAppDispatch()
  const { sportsType } = useAppSelector(selectFavouriteEventsFilters)

  const handleSportTypeClick = useCallback(
    (item) => {
      dispatch(favouriteEventsContainer.setFilterSportsType(item))
    },

    [dispatch]
  )

  return (
    <SegmentedControl
      items={FILTER_SPORTS_TYPES_TABS}
      value={sportsType || ''}
      view="secondary"
      onChange={handleSportTypeClick}
    />
  )
}
