import { Event } from 'betweb-openapi-axios'

import { ESportsTypes } from 'typings/exportable'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'

export const filterEvents = (
  events: Event[],
  sportsType: ESportsTypes | null
): Event[] => {
  let filtredEventsArr = events
  if (sportsType?.toLowerCase() === ESportsMenuTabTypes.LINE) {
    filtredEventsArr = events.filter((event) => !event.live)
  }
  if (sportsType?.toLowerCase() === ESportsMenuTabTypes.LIVE) {
    filtredEventsArr = events.filter((event) => event.live)
  }
  return filtredEventsArr
}
