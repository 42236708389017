import React from 'react'

import { LayoutError } from 'layouts/error'

import { UnderConstructionPage } from './under-construction-page'

export const PageUnderConstruction = () => (
  <LayoutError>
    <UnderConstructionPage />
  </LayoutError>
)

export default PageUnderConstruction
