import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconPlusOutline16: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height="12" rx="1" width="2" x="7" y="2" />
      <rect
        fill="currentColor"
        height="12"
        rx="1"
        transform="rotate(90 14 7)"
        width="2"
        x="14"
        y="7"
      />
    </svg>
  </Icon>
)
