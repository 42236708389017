const rulesMap = {
  ru: '/documents/rules_170724_ru.pdf',
  en: '/documents/rules_170724_en.pdf',
  kz: '/documents/rules_170724_kz.pdf',
  uz: '/documents/rules_170724_uz.pdf',
  ch: '/documents/rules_170724_ch.pdf'
}

const consentMap = {
  ru: '/documents/consent_ru.pdf',
  en: '/documents/consent_en.pdf',
  kz: '/documents/consent_kz.pdf',
  uz: '/documents/consent_uz.pdf',
  tr: '/documents/consent_tr.pdf',
  cn: '/documents/consent_cn.pdf'
}

const documentsList = {
  rules: rulesMap,
  consent: consentMap
}

export const getDocumentLink = (documentName: string, locale: string) => {
  if (documentsList[documentName][locale]) {
    return documentsList[documentName][locale]
  }
  return documentsList[documentName].en
}
