import { ContentLoader } from 'shared/ui/content-loader/content-loader'
import { ProbsLoader } from 'features/event-card'

import * as S from './event-card.styled'

export const EventCardLoader = () => {
  return (
    <S.EventCardWrapper>
      <S.EventCardInfo>
        <div />
        <ContentLoader borderRadius={4} height={12} width={146} />
        <S.CompetitorsWrapper>
          <S.EmblemsAndScore>
            <S.EmblemWrapper>
              <ContentLoader borderRadius="50%" height={32} width={32} />
            </S.EmblemWrapper>
            <S.EmblemWrapper>
              <ContentLoader borderRadius="50%" height={32} width={32} />
            </S.EmblemWrapper>
          </S.EmblemsAndScore>
          <S.Competitors>
            <ContentLoader borderRadius={4} height={12} width={91} />
            <ContentLoader borderRadius={4} height={12} width={91} />
          </S.Competitors>
        </S.CompetitorsWrapper>
      </S.EventCardInfo>
      <ProbsLoader />
    </S.EventCardWrapper>
  )
}
