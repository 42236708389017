import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'
import { IconEmptyBonuses } from 'shared/ui/Icon/General/IconEmptyBonuses'

import {
  StyledBonusesEmptyContent,
  StyledBonusesEmptyDescWrapper
} from '../../my-bonuses.styled'

export const MyBonusesEmptyContent = () => {
  const [t] = useTranslation()

  return (
    <StyledBonusesEmptyContent>
      <IconEmptyBonuses colorToken="icon-secondary-3" size={40} />
      <StyledBonusesEmptyDescWrapper>
        <Typography color="text-primary" font="Body / 16 SemiBold">
          {t('no bonuses')}
        </Typography>
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {t('all your bonuses will be here')}
        </Typography>
      </StyledBonusesEmptyDescWrapper>
    </StyledBonusesEmptyContent>
  )
}
