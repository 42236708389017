import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ServicePageContent } from 'features/service-page-content'
import { EServicePage } from 'features/service-page-content/constants'
import { Button } from 'shared/ui/Button'

export const OfflinePage = memo(() => {
  const [t] = useTranslation()
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <ServicePageContent variant={EServicePage.Offline}>
      <Button view="secondary-extra-large" onClick={handleReload}>
        {t('update')}
      </Button>
    </ServicePageContent>
  )
})
