import styled, { css } from 'styled-components'

import { Typography } from 'shared/ui/typography'

export const EmptyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const EmptyContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 320px;
  text-align: center;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const EmptyText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const EmptyTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const EmptyDescription = styled(Typography)`
  text-align: center;
  white-space: break-spaces;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`
