import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'
import { IconFavoritesFilled12 } from 'shared/ui/Icon/General/IconFavoritesFilled12'
import { IconFavoritesFilled40 } from 'shared/ui/Icon/General/IconFavoritesFilled40'

import {
  StyledEmptyAdvice,
  StyledEmptyContainer,
  StyledEmptyIcon
} from './empty-screen.styled'

export const EmptyScreen: FC<{ view: 'line' | 'live' | 'all' }> = ({
  view
}) => {
  const [t] = useTranslation()

  return (
    <StyledEmptyContainer>
      <StyledEmptyIcon>
        <IconFavoritesFilled40 colorToken="icon-warning" size={40} />
      </StyledEmptyIcon>
      <Typography color="text-primary" font="Body / 16 SemiBold">
        {view === 'all' && t('favorites are empty yet')}
        {view === 'line' && t('line are empty yet')}
        {view === 'live' && t('live are empty yet')}
      </Typography>

      <StyledEmptyAdvice>
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {t('add events to your favorites')}
        </Typography>
        <IconFavoritesFilled12 colorToken="icon-secondary-3" size={12} />
      </StyledEmptyAdvice>
    </StyledEmptyContainer>
  )
}
