import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'

import { formatBalance } from 'shared/lib/format/format-balance'

import {
  StyledItemWrapper,
  BalanceReportWrapper,
  StyledItemTitle,
  StyledItemValue,
  BalanceReportBlock,
  Divider
} from './balance-report.styled'
import {
  BalanceReportProps,
  BalanceReportItemProps
} from './balance-report.types'

export const BalanceReportItem: FC<BalanceReportItemProps> = ({
  value,
  title,
  withDivider
}) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()

  const formattedBalance = useMemo(
    () => formatBalance(value, currencyIcon),
    [value, currencyIcon]
  )

  return (
    <>
      <StyledItemWrapper>
        <StyledItemTitle>{t(title)}</StyledItemTitle>
        <StyledItemValue>{formattedBalance}</StyledItemValue>
      </StyledItemWrapper>
      {withDivider && <Divider />}
    </>
  )
}
export const BalanceReport: FC<BalanceReportProps> = ({ data }) => {
  return (
    <BalanceReportWrapper>
      <BalanceReportBlock>
        {data.map((item) => (
          <BalanceReportItem key={item.title} {...item} />
        ))}
      </BalanceReportBlock>
    </BalanceReportWrapper>
  )
}
