import { useField } from 'formik'
import React, { FC, useMemo } from 'react'

import {
  EPasswordStrength,
  REG_PASSWORD_SEPARATED,
  passwordStrength
} from 'shared/lib/validation/password-strength'
import { PasswordConditional } from 'shared/ui/password-conditional'

import { StyledPasswordConditionalWrapper } from './password-validation.styled'

export const PasswordValidation: FC<{
  name: string
  bannedPasswords: string[]
}> = ({ name, bannedPasswords = [] }) => {
  const [field, meta] = useField(name)

  const passwordStrengthResult: EPasswordStrength = useMemo(() => {
    return passwordStrength(field.value, bannedPasswords)
  }, [bannedPasswords, field.value])

  const isWeak = useMemo(() => {
    return passwordStrengthResult === EPasswordStrength.WEAK
  }, [passwordStrengthResult])

  if (!field.value) {
    return null
  }

  return (
    <StyledPasswordConditionalWrapper>
      <PasswordConditional
        afterSubmit={!!meta.error}
        isWeak={isWeak}
        password={field.value}
        regExp={REG_PASSWORD_SEPARATED.allAbove}
        strength={passwordStrengthResult}
        title="password strength"
        touched={meta.touched}
        isPasswordStrength
      />
      {(meta.error || isWeak) && (
        <>
          <PasswordConditional
            afterSubmit={!!meta.error}
            password={field.value}
            regExp={REG_PASSWORD_SEPARATED.symbolsCounts}
            title="at least 6 characters"
            touched={meta.touched}
          />
          <PasswordConditional
            afterSubmit={!!meta.error}
            password={field.value}
            regExp={REG_PASSWORD_SEPARATED.latinLetter}
            title="latin letter"
            touched={meta.touched}
          />
          <PasswordConditional
            afterSubmit={!!meta.error}
            password={field.value}
            regExp={REG_PASSWORD_SEPARATED.atLeastOneDigit}
            title="contains a number"
            touched={meta.touched}
          />
          <PasswordConditional
            afterSubmit={!!meta.error}
            password={field.value}
            regExp={REG_PASSWORD_SEPARATED.uppercaseAndLowercaseLetter}
            title="at least one uppercase and one lowercase letter"
            touched={meta.touched}
          />
        </>
      )}
    </StyledPasswordConditionalWrapper>
  )
}
