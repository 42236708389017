import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconNotificationFilled24: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.65382 3C8.80592 3 7.99274 3.33683 7.39318 3.93639L4.0753 7.25426C3.47574 7.85382 3.13892 8.667 3.13892 9.5149L3.14035 20.3595C3.14052 20.9207 3.81179 21.2094 4.21931 20.8237L5.71669 19.4063C6.08765 19.0551 6.67072 19.0631 7.03191 19.4243L7.39318 19.7856C7.99274 20.3852 8.80591 20.722 9.65382 20.722L14.346 20.722C15.1939 20.722 16.0071 20.3852 16.6066 19.7856L19.9245 16.4677C20.5241 15.8682 20.8609 15.055 20.8609 14.2071V9.5149C20.8609 8.667 20.5241 7.85382 19.9245 7.25427L16.6066 3.93639C16.0071 3.33683 15.1939 3 14.346 3H9.65382ZM7.00024 14.013C7.00024 13.4607 7.44796 13.013 8.00024 13.013H14C14.5523 13.013 15 13.4607 15 14.013C15 14.5653 14.5523 15.013 14 15.013H8.00024C7.44796 15.013 7.00024 14.5653 7.00024 14.013ZM8.00024 8.987C7.44796 8.987 7.00024 9.43471 7.00024 9.987C7.00024 10.5393 7.44796 10.987 8.00024 10.987H15.9998C16.552 10.987 16.9998 10.5393 16.9998 9.987C16.9998 9.43471 16.552 8.987 15.9998 8.987H8.00024Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
