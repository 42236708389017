import styled, { css } from 'styled-components'

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 32px 24px;
  position: relative;
`

export const StyledLoaderWrapper = styled.div`
  align-items: center;
  bottom: -40px;
  display: flex;
  justify-content: center;
  left: 1px;
  position: absolute;
  right: 1px;
  top: 0;
  z-index: 10;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-modal']};
  `}
`

export const StyledModalIconWrapper = styled.div`
  padding-top: 2px;
`

export const StyledModalWarning = styled.div`
  display: flex;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-warning']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    outline: 1px solid ${theme.tokens.colors.border['border-warning-opacity']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledModalBottom = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 0 32px;

  & > button {
    width: 100%;
  }
`

export const StyledModalSMS = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 32px 24px;
  position: relative;
  white-space: break-spaces;
`

export const StyledModalInputBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
