import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconPhone: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5556 3.48381C11.0605 3.43923 10.6562 3.84646 10.6562 4.34351C10.6562 4.84057 11.0608 5.23826 11.5548 5.29279C15.3104 5.70727 18.2925 8.68934 18.707 12.445C18.7615 12.939 19.1592 13.3435 19.6562 13.3435C20.1533 13.3435 20.5605 12.9392 20.516 12.4442C20.0882 7.69422 16.3055 3.91153 11.5556 3.48381Z"
        fill="currentColor"
      />
      <path
        d="M11.6102 6.95395C11.0983 6.84803 10.6562 7.26924 10.6562 7.79197C10.6562 8.26684 11.0255 8.65255 11.4848 8.77317C13.2288 9.23119 14.579 10.6609 14.9216 12.4487C15.0152 12.9369 15.4092 13.3435 15.9062 13.3435C16.4033 13.3435 16.8128 12.9384 16.745 12.446C16.3681 9.70583 14.2897 7.50839 11.6102 6.95395Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M3.58701 4.48229C3.44485 4.54686 3.23749 4.70319 3.12623 4.82975C2.80787 5.19204 2.75704 5.43459 2.80626 6.35622C3.0182 10.3251 4.61653 13.8541 7.43387 16.5736C10.1355 19.1814 13.4918 20.6312 17.297 20.834C18.2194 20.8832 18.4622 20.8324 18.8248 20.5144C19.2691 20.1246 19.29 20.0122 19.29 18.0235C19.29 16.0317 19.2695 15.9229 18.8201 15.5286C18.5306 15.2746 18.2881 15.2114 17.4216 15.1639C16.6719 15.1229 15.9325 14.997 15.294 14.8018C14.8034 14.6518 14.405 14.6378 14.1148 14.7602C14.0007 14.8084 13.4444 15.1977 12.8786 15.6254L11.8499 16.4029L11.5564 16.2348C9.72245 15.1845 8.46448 13.927 7.40775 12.0882L7.23721 11.7915L8.01693 10.7672C8.44578 10.2038 8.83638 9.64959 8.88495 9.53553C9.01626 9.22699 8.99363 8.89686 8.78581 8.08794C8.58464 7.30511 8.45855 6.43491 8.45597 5.81202C8.45332 5.17619 8.14535 4.68785 7.60466 4.46218C7.22057 4.30192 3.94839 4.31829 3.58701 4.48229Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
