import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { TFunction } from 'i18next'
import {
  CompletionStatus,
  EventResult,
  EventStatus
} from 'betweb-openapi-axios/dist/api'
import { TResults } from 'astra-core/containers/ResultsProvider/types'

import { EEventStatisticsCodes } from 'shared/lib/events/types'
import {
  isCurrentYear,
  isDateToday,
  isDateYesterday
} from 'shared/lib/todayDate'

import {
  IGameScore,
  IResultDateAndTimeResponse,
  TChampionships,
  TResultWithFilteredEvents
} from '../results.types'

export const RESULT_EVENT_OTHER_YEAR_DATE_FORMAT = 'D MMMM YYYY'
export const RESULT_EVENT_CURRENT_YEAR_DATE_FORMAT = 'D MMMM'
export const RESULT_EVENT_TIME_FORMAT = 'HH:mm'

export const getResultDateAndTime = (
  eventDate: Date | string,
  t: TFunction
): IResultDateAndTimeResponse => {
  const resultDate = isDateToday(eventDate)
    ? t('today')
    : isDateYesterday(eventDate)
    ? t('yesterday')
    : dayjs(eventDate).format(
        isCurrentYear(eventDate)
          ? RESULT_EVENT_CURRENT_YEAR_DATE_FORMAT
          : RESULT_EVENT_OTHER_YEAR_DATE_FORMAT
      )

  const resultTime = dayjs(eventDate).format(RESULT_EVENT_TIME_FORMAT)

  return { resultDate, resultTime }
}

export const getEventStatusDesc = (status: EventStatus) => {
  switch (status) {
    case EventStatus.Interrupted:
      return 'match interrupted'
    case EventStatus.Postponed:
      return 'match postponed'
    default:
      return null
  }
}

export const parseGameScore = (gameScore: string): IGameScore => {
  const [homeScoreStr, awayScoreStr] = gameScore.split(':')

  if (homeScoreStr.trim() === '' || awayScoreStr.trim() === '') {
    return { homeScore: '-', awayScore: '-' }
  }

  const homeScore = Number(homeScoreStr)
  const awayScore = Number(awayScoreStr)

  if (Number.isNaN(homeScore) || Number.isNaN(awayScore)) {
    return { homeScore: '-', awayScore: '-' }
  }

  return { homeScore, awayScore }
}

export const hasGameScore = (events: EventResult[]): boolean => {
  return events.some((event) =>
    event.liveStatistics?.some(
      (stat) => stat.code === EEventStatisticsCodes.GameScore
    )
  )
}

export const getGameScore = (event: EventResult): IGameScore | null => {
  const gameScore = event?.liveStatistics?.find(
    (item) => item.code === EEventStatisticsCodes.GameScore
  )

  if (gameScore && gameScore.value) {
    return parseGameScore(gameScore.value)
  }

  return null
}

export function filterChampionshipsByEndedStatus(data: TChampionships) {
  return data
    .map((tournamentData: TResults | TResultWithFilteredEvents | undefined) => {
      if (typeof tournamentData === 'undefined') return null

      const { events } = tournamentData

      if (!events) return null

      const hasEndedResults = events.some(
        (event) => event.completionStatus === CompletionStatus.Ended
      )

      if (!hasEndedResults) {
        return null
      }

      const endedEvents = events.filter(
        (event) => event.completionStatus === CompletionStatus.Ended
      )

      if (!endedEvents.length) {
        return null
      }

      return {
        ...tournamentData,
        events: endedEvents
      }
    })
    .filter((tournamentData) => tournamentData !== null)
}
