import React from 'react'
import { useTranslation } from 'react-i18next'

import { ERoutes } from 'shared/types/routes'
import { Skeleton } from 'shared/ui/skeleton'
import { ESportGamesProductIds } from 'pages/page-sport-games/page.types'

import { StyledGameCardText, StyledGameCardLink } from '../../game-card.styled'
import { GameCardLoader } from '../../game-card.loader'
import image1x from '../lib/darts@1x.png'
import image2x from '../lib/darts@2x.png'
import image3x from '../lib/darts@3x.png'

import { StyledDartsImg } from './darts-game-card.styled'

export const DartsGameCard = () => {
  const { t } = useTranslation()

  return (
    <StyledGameCardLink
      to={{
        pathname: ERoutes.SportGamesLobby,
        state: { productId: ESportGamesProductIds.DARTS }
      }}
    >
      <Skeleton isLoading={false} styledFallback={<GameCardLoader />}>
        <StyledDartsImg
          alt="darts image"
          srcSet={`${image1x} 1x, ${image2x} 2x, ${image3x} 3x`}
        />
        <StyledGameCardText>{t('darts')}</StyledGameCardText>
      </Skeleton>
    </StyledGameCardLink>
  )
}
