import { baseLegacyApi } from '../api'

import { sendCodeRequest, sendCodeResponse } from './sms.types'

export const smsApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    sendCode: builder.mutation<sendCodeResponse, sendCodeRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/sendcode/send',
        body: {
          ...args,
          platforma: 'NEWOLIMPBET'
        }
      })
    })
  })
})
