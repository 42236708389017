import { EventsGroup } from 'astra-core/hooks/useEvents/types'

export const groupEventGpoupsBySport = (
  events: EventsGroup[]
): EventsGroup[][] => {
  const groupedEvents: { [sportId: number]: EventsGroup[] } = {}

  events.forEach((event) => {
    const { sportId } = event
    if (!groupedEvents[sportId]) {
      groupedEvents[sportId] = []
    }

    groupedEvents[sportId].push(event)
  })

  const result: EventsGroup[][] = Object.values(groupedEvents)

  return result
}
