import React from 'react'

import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { PromoCard } from 'shared/ui/promo-and-bonus-card'

import {
  StyledPromotionsContent,
  StyledPromotionsContentWrapper
} from '../../promotions.styled'
import { EPromoType, formatPromoDateRange } from '../../constants'
import { useGetPromotions } from '../../hooks'
import { PromotionsEmptyContent } from '../promotions-empty-content'

export const PromotionsCurrentContent = () => {
  const { promoData, isLoading } = useGetPromotions(EPromoType.CURRENT)

  if (isLoading) return <LoaderLogo />

  return (
    <StyledPromotionsContent>
      <StyledPromotionsContentWrapper>
        {promoData.map((promoCard) => {
          const promoDate = formatPromoDateRange(
            promoCard.startAt,
            promoCard.endsIn
          )

          return (
            <PromoCard
              content={promoCard.html}
              key={promoCard.id}
              link={promoCard.link}
              promoDate={promoDate}
            />
          )
        })}
      </StyledPromotionsContentWrapper>
    </StyledPromotionsContent>
  )
}

export const PromotionsPastContent = () => {
  const { promoData, isLoading } = useGetPromotions(EPromoType.PAST)

  if (isLoading) return <LoaderLogo />

  if (!promoData.length) return <PromotionsEmptyContent />

  return (
    <StyledPromotionsContent>
      <StyledPromotionsContentWrapper>
        {promoData.map((promoCard) => {
          const promoDate = formatPromoDateRange(
            promoCard.startAt,
            promoCard.endsIn
          )

          return (
            <PromoCard
              content={promoCard.html[0]}
              key={promoCard.id}
              link={promoCard.link}
              promoDate={promoDate}
            />
          )
        })}
      </StyledPromotionsContentWrapper>
    </StyledPromotionsContent>
  )
}
