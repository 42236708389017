import React from 'react'

import { IconChevronDoubleUp } from 'shared/ui/Icon/General/IconChevronDoubleUp'
import { IconButton } from 'shared/ui/buttons/IconButton'
import { useBetsHistoryContext } from 'entities/bet-history/lib/bet-history.context'

export const ButtonToggleCollapse = () => {
  const { allCollapsed, toggleCollapseAll, collapseAllDisabled } =
    useBetsHistoryContext()

  return (
    <IconButton
      disabled={collapseAllDisabled}
      icon={IconChevronDoubleUp}
      twist={allCollapsed ? 0 : 180}
      view="outline-l"
      onClick={toggleCollapseAll}
    />
  )
}
