import {
  IdentificationConstraints,
  useGetIsBlockedConstraint
} from 'entities/constration/model'

import { BlockBetsScreen } from '../blockBets-screen'

import { StyledBreakWrapper } from './BreakTab.styled'
import { BreakContent } from './ui'

export const BreakTab = () => {
  const isBetsBlocked = useGetIsBlockedConstraint(
    IdentificationConstraints.BETS
  )

  return (
    <StyledBreakWrapper>
      {isBetsBlocked ? <BlockBetsScreen /> : <BreakContent />}
    </StyledBreakWrapper>
  )
}
