import styled, { css } from 'styled-components'
import get from 'lodash/get'

import { StyledIcon } from 'shared/ui/Icon'
import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'
import { SPORT_MAIN_COLOR_MAP } from 'shared/ui/Icon/sport-icon/constants'

import { StyledResultsMenuProps } from './results-menu-list.types'

export const StyledResultsMenuItem = styled(AppLink)<StyledResultsMenuProps>`
  display: flex;
  text-decoration: none;
  transition: background-color 0.25s ease-in-out;

  ${({ theme: { tokens }, $isActive, sport }) => css`
    background-color: ${tokens.colors.surface['surface-default-opacity']};
    padding: ${tokens.alias.space['space-xs-8']}px
      ${tokens.alias.space['space-s-12']}px;

    ${StyledIcon} {
      margin-right: ${tokens.alias.space['space-xxs-6']}px;
    }

    &:hover {
      background-color: ${tokens.colors.surface['surface-menu-active']};
    }

    ${$isActive &&
    css`
      background-color: ${tokens.colors.surface['surface-menu-active']};

      &:hover {
        background-color: ${tokens.colors.surface['surface-menu-active']};
      }

      position: relative;

      &:before {
        background-color: ${get(tokens.colors, SPORT_MAIN_COLOR_MAP[sport])};
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;
      }

      ${StyledResultsMenuItemCount} {
        color: ${tokens.colors.text['text-secondary-3']};
      }

      ${StyledResultsMenuItemText} {
        color: ${tokens.colors.text['text-primary-2']};
      }
    `}
  `}
`

export const StyledResultsMenuItemText = styled.div`
  ${typography['Body / 14 Medium']}
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  flex: 1;
  overflow: hidden;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  transition: color 0.25s ease-in-out;

  ${({ theme: { tokens } }) => css`
    color: ${tokens.colors.text['text-secondary-3']};
    padding: ${tokens.alias.space['space-xxxxs-2']}px 0;
  `}
`

export const StyledResultsMenuItemCount = styled.span`
  ${typography['Body / 12 Medium']}
  align-self: baseline;
  transition: color 0.25s ease-in-out;
  ${({ theme: { tokens } }) => css`
    color: ${tokens.colors.text['text-description']};
    margin-left: ${tokens.alias.space['space-xxxs-4']}px;
    padding: ${tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const StyledResultsMenuList = styled.div`
  display: grid;
  gap: 1px;
  grid-auto-rows: min-content;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias.space['space-xs-8']}px;

    ${StyledResultsMenuItem}:first-child {
      border-radius: ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xs-8']}px 0 0;
      overflow: hidden;
    }

    ${StyledResultsMenuItem}:last-child {
      border-radius: 0 0 ${theme.tokens.alias.space['space-xs-8']}px
        ${theme.tokens.alias.space['space-xs-8']}px;
      overflow: hidden;
    }
  `}
`
