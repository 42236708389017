import styled, { css } from 'styled-components'

export const StyledResultEventWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    background: ${tokens.colors.surface['surface-base']};
    box-shadow: ${tokens.colors.divider['divider-primary']} 0px -1px inset;
    display: flex;
    gap: ${tokens.alias.space['space-m-16']}px;
    padding: ${tokens.alias.space['space-xxs-6']}px
      ${tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledEventInfo = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: start;
    display: flex;
    gap: ${tokens.alias.space['space-m-16']}px;
    width: 100%;
  `}
`

export const StyledEventResult = styled.div`
  align-items: start;
  display: flex;
`

export const StyledEventLiveTrack = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
`

export const StyledEvenLiveTimeWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    gap: ${tokens.alias.space['space-xxxxs-2']}px;
    justify-content: center;
  `}
`

export const StyledEventCompetitors = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const IconWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    height: 15px;
    padding: ${tokens.alias.space['space-xxxxs-2']}px
      ${tokens.alias.space['space-none']} 1px
      ${tokens.alias.space['space-none']};
  `}
`
