import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconMail24: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M18.1716 3.5C18.702 3.5 19.2107 3.71071 19.5858 4.08579L21.4142 5.91421C21.7893 6.28929 22 6.79799 22 7.32843V16.6716C22 17.202 21.7893 17.7107 21.4142 18.0858L19.5858 19.9142C19.2107 20.2893 18.702 20.5 18.1716 20.5H5.82843C5.29799 20.5 4.78929 20.2893 4.41421 19.9142L2.58579 18.0858C2.21071 17.7107 2 17.202 2 16.6716V7.32843C2 6.79799 2.21071 6.28929 2.58579 5.91421L4.41421 4.08579C4.78929 3.71071 5.29799 3.5 5.82843 3.5H18.1716ZM5.37531 7.71917C5.80657 7.37416 6.43587 7.44408 6.78088 7.87534L10.4806 12.5H13.5194L17.2191 7.87534C17.5641 7.44408 18.1934 7.37416 18.6247 7.71917C19.056 8.06418 19.1259 8.69347 18.7809 9.12473L15.0811 13.7494C14.7016 14.2239 14.1269 14.5 13.5194 14.5H10.4806C9.87306 14.5 9.29844 14.2239 8.91889 13.7494L5.21914 9.12473C4.87413 8.69347 4.94405 8.06418 5.37531 7.71917Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
