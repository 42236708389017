import React from 'react'

import { LayoutEventsTable } from 'layouts/events-table'

import { FavouriteEventsContainer } from './FavouriteEventsContainer'

export const PageFavouriteEvents = () => (
  <LayoutEventsTable>
    <FavouriteEventsContainer />
  </LayoutEventsTable>
)

export default PageFavouriteEvents
