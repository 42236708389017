import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconMyBonuses: FC<IconProps> = (props) => (
  <Icon {...props} size={24}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M16.5334 3.80546C16.0177 3.28973 15.3182 3 14.5888 3H9.41116C8.68182 3 7.98235 3.28973 7.46662 3.80546L3.80546 7.46662C3.28973 7.98235 3 8.68182 3 9.41117V14.5888C3 15.3182 3.28973 16.0177 3.80546 16.5334L7.46662 20.1945C7.98235 20.7103 8.68182 21 9.41117 21H14.5888C15.3182 21 16.0177 20.7103 16.5334 20.1945L20.1945 16.5334C20.7103 16.0177 21 15.3182 21 14.5888V9.41117C21 8.68182 20.7103 7.98235 20.1945 7.46662L16.5334 3.80546ZM6.97539 9.35569C6.67975 9.35569 6.41773 9.54606 6.32637 9.82723L6.12017 10.4619C6.02881 10.743 6.12889 11.0511 6.36807 11.2248L8.46394 12.7476C8.70312 12.9214 8.80321 13.2294 8.71185 13.5105L7.91125 15.9745C7.81989 16.2557 7.91997 16.5637 8.15915 16.7375L8.69893 17.1297C8.93811 17.3035 9.26198 17.3035 9.50116 17.1297L11.5972 15.6069C11.8363 15.4331 12.1602 15.4331 12.3994 15.6069L14.4962 17.1303C14.7354 17.3041 15.0593 17.3041 15.2985 17.1303L15.8377 16.7385C16.0769 16.5647 16.177 16.2567 16.0856 15.9755L15.2847 13.5105C15.1933 13.2294 15.2934 12.9214 15.5326 12.7476L17.6299 11.2238C17.869 11.0501 17.9691 10.742 17.8778 10.4609L17.6719 9.82723C17.5805 9.54606 17.3185 9.35569 17.0229 9.35569H14.4305C14.1349 9.35569 13.8729 9.16532 13.7815 8.88415L12.9806 6.41939C12.8893 6.13822 12.6273 5.94785 12.3316 5.94785H11.6649C11.3693 5.94785 11.1073 6.13822 11.0159 6.41939L10.2151 8.88415C10.1237 9.16532 9.86168 9.35569 9.56604 9.35569H6.97539Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
