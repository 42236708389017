import { FC } from 'react'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'

import { SLIDES_HEIGHT, SLIDES_MIN_WIDTH } from './Banners.constants'

export const BannersLoader: FC = () => {
  return (
    <div
      style={{
        padding: '0 16px',
        display: 'flex',
        gap: 4
      }}
    >
      <ContentLoader
        borderRadius={8}
        height={SLIDES_HEIGHT}
        width={SLIDES_MIN_WIDTH}
      />
      <ContentLoader
        borderRadius={8}
        height={SLIDES_HEIGHT}
        width={SLIDES_MIN_WIDTH}
      />
      <ContentLoader
        borderRadius={8}
        height={SLIDES_HEIGHT}
        width={SLIDES_MIN_WIDTH}
      />
      <ContentLoader
        borderRadius={8}
        height={SLIDES_HEIGHT}
        width={SLIDES_MIN_WIDTH}
      />
    </div>
  )
}
