import { useState, memo, useEffect } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'
import { IconSetting } from 'shared/ui/Icon/General/IconSetting'

import {
  CollapsibleNotificationWrapper,
  StyledNotificationArrowIcon,
  StyledNotificationContent,
  StyledNotificationLabel,
  StyledNotificationPreview,
  StyledNotificationText
} from './notification.styled'
import { NotificationProps } from './notification.types'

export const Notification = memo<NotificationProps>(
  ({ date: dateProp, text, onRead }) => {
    const { t } = useTranslation()
    const [isCollapsed, setIsCollapsed] = useState(true)

    const handleCollapseToggle = () => setIsCollapsed((state) => !state)

    useEffect(() => {
      if (!isCollapsed) {
        onRead?.()
      }
    }, [isCollapsed]) // eslint-disable-line react-hooks/exhaustive-deps

    const date = dayjs.unix(dateProp).format('DD.MM.YY H:mm')

    return (
      <CollapsibleNotificationWrapper isCollapsed={isCollapsed}>
        <StyledNotificationPreview onClick={handleCollapseToggle}>
          <IconSetting colorToken="icon-primary" size={24} />
          <StyledNotificationLabel
            color="text-primary-2"
            font="Body / 14 SemiBold"
          >
            {t('notification.system')}
          </StyledNotificationLabel>
          <Typography color="text-secondary-2" font="Body / 14 Medium">
            {date}
          </Typography>
          <StyledNotificationArrowIcon isCollapsed={isCollapsed} />
        </StyledNotificationPreview>

        <StyledNotificationContent>
          <StyledNotificationText>
            <Typography
              color="text-secondary"
              dangerouslySetInnerHTML={{ __html: text }}
              font="Body / 14 Medium"
            />
          </StyledNotificationText>
        </StyledNotificationContent>
      </CollapsibleNotificationWrapper>
    )
  }
)
