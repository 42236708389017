import { memo, PropsWithChildren, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { IconUnderDevelopment } from 'shared/ui/Icon/General/icon-under-development'

import {
  StyledServiceContainer,
  StyledServiceDesc,
  StyledServiceTitle
} from './service-page-content.styled'
import { EServicePage, SERVICE_PAGE_MAP } from './constants'

export type ServicePageContentProps = {
  icon?: ReactNode
  variant: EServicePage
}
export const ServicePageContent = memo<
  PropsWithChildren<ServicePageContentProps>
>(({ children, icon = <IconUnderDevelopment size={40} />, variant }) => {
  const [t] = useTranslation()

  const { title: titleToken, desc: descToken } = SERVICE_PAGE_MAP[variant]

  return (
    <StyledServiceContainer>
      {icon}
      <StyledServiceTitle>{t(titleToken)}</StyledServiceTitle>
      <StyledServiceDesc>{t(descToken)}</StyledServiceDesc>
      {children}
    </StyledServiceContainer>
  )
})
