import {
  ETurboGamesIds,
  TTurboGamesListItem
} from 'pages/page-games-turbo/TurboGames/types'

export const TURBO_GAMES_LIST: TTurboGamesListItem[] = [
  {
    gameId: ETurboGamesIds.AVIATOR,
    iconSrc: '/images/turbo-games/aviator.png'
  },
  {
    gameId: ETurboGamesIds.DICE,
    iconSrc: '/images/turbo-games/dice.png'
  },
  {
    gameId: ETurboGamesIds.GOAL,
    iconSrc: '/images/turbo-games/goal.png'
  },
  {
    gameId: ETurboGamesIds.HI_LO,
    iconSrc: '/images/turbo-games/hilo.png'
  },
  {
    gameId: ETurboGamesIds.KENO,
    iconSrc: '/images/turbo-games/keno.png'
  },
  {
    gameId: ETurboGamesIds.MINE_ROULETTE,
    iconSrc: '/images/turbo-games/miniRoulette.png'
  },
  {
    gameId: ETurboGamesIds.MINES,
    iconSrc: '/images/turbo-games/mines.png'
  },
  {
    gameId: ETurboGamesIds.PLINKO,
    iconSrc: '/images/turbo-games/plinko.png'
  }
]
