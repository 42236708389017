import { BetType } from 'betweb-openapi-axios'
import { TFunction } from 'react-i18next'

export const ALL_OPTION_VALUE = 'ALL'
export const BET_TYPES_OPTIONS = (t: TFunction) => [
  { value: ALL_OPTION_VALUE, label: t('all types') },
  { value: BetType.Ordinar, label: t('ordinar') },
  { value: BetType.Express, label: t('express') },
  { value: BetType.System, label: t('system') }
]
