export const DOCUMENTS = [
  {
    name: 'rules',
    linkName: 'rules document link',
    title: 'rules documnt title',
    hideInIframe: true
  },
  {
    name: 'consent',
    linkName: 'consent document link',
    title: 'consent document link'
  }
]
