import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconEmptyPromotions: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6439 10.3287C16.971 10.658 17.4147 10.843 17.8773 10.843H22.3001C22.7627 10.843 23.2064 10.658 23.5335 10.3287L25.986 7.85984C26.6672 7.17408 27.7717 7.17408 28.4529 7.85984C29.1341 8.54561 29.1341 9.65745 28.4529 10.3432L27.9556 10.8438H32.1593C32.8017 8.99379 32.3885 6.85502 30.9198 5.37647C28.8761 3.31917 25.5627 3.31917 23.5191 5.37647L20.0887 8.82979L16.6583 5.37647C14.6147 3.31917 11.3013 3.31917 9.25765 5.37647C7.78891 6.85502 7.37573 8.99381 8.01813 10.8438H12.2218L11.7245 10.3432C11.0433 9.65745 11.0433 8.54561 11.7245 7.85984C12.4057 7.17408 13.5102 7.17408 14.1914 7.85984L16.6439 10.3287Z"
        fill="currentColor"
      />
      <path
        d="M18.351 13.716C18.5037 13.8697 18.5895 14.0782 18.5895 14.2956V18.2985C18.5895 18.5159 18.5037 18.7244 18.351 18.8781L17.6647 19.569C17.512 19.7227 17.3049 19.8091 17.0889 19.8091L4.83394 19.8091C4.61798 19.8091 4.41087 19.7227 4.25814 19.569L3.57188 18.8781C3.41916 18.7244 3.33337 18.5157 3.33337 18.2983V14.2956C3.33337 14.0782 3.41916 13.8697 3.57188 13.716L4.25814 13.0251C4.41087 12.8714 4.61798 12.785 4.83394 12.785L17.0889 12.785C17.3049 12.785 17.512 12.8714 17.6647 13.0251L18.351 13.716Z"
        fill="currentColor"
      />
      <path
        d="M36.4282 13.716C36.5809 13.8697 36.6667 14.0782 36.6667 14.2956V18.2985C36.6667 18.5159 36.5809 18.7244 36.4282 18.8781L35.7419 19.569C35.5892 19.7227 35.3821 19.8091 35.1661 19.8091L22.9112 19.8091C22.6952 19.8091 22.4881 19.7227 22.3354 19.569L21.6491 18.8781C21.4964 18.7244 21.4106 18.5157 21.4106 18.2983V14.2956C21.4106 14.0782 21.4964 13.8697 21.6491 13.716L22.3354 13.0251C22.4881 12.8714 22.6952 12.785 22.9112 12.785L35.1661 12.785C35.3821 12.785 35.5892 12.8714 35.7419 13.0251L36.4282 13.716Z"
        fill="currentColor"
      />
      <path
        d="M18.6013 28.2161V31.5963C18.6026 31.6153 18.6033 31.6344 18.6033 31.6536V35.6562C18.6033 35.8736 18.5175 36.0821 18.3648 36.2359L17.6785 36.9267C17.5258 37.0805 17.3187 37.1668 17.1027 37.1668L10.5704 37.1668C9.79791 37.1668 9.05033 36.8648 8.5021 36.3538L6.03509 34.0307C5.48687 33.5196 5.18783 32.8227 5.18783 32.0793V24.6724L5.18858 24.156C5.18858 23.9386 5.27455 23.7301 5.42761 23.5764L6.11535 22.8855C6.2684 22.7318 6.47596 22.6454 6.69239 22.6454H17.0995C17.3159 22.6454 17.5235 22.7318 17.6765 22.8856L18.3642 23.5764C18.5173 23.7301 18.6033 23.9388 18.6033 24.1562V28.1589C18.6033 28.178 18.6026 28.1971 18.6013 28.2161Z"
        fill="currentColor"
      />
      <path
        d="M34.9913 28.2161V32.0793C34.9913 32.8227 34.6923 33.5196 34.1441 34.0307L31.6771 36.3538C31.1289 36.8648 30.3813 37.1668 29.6088 37.1668H22.9198C22.7038 37.1668 22.4967 37.0805 22.344 36.9267L21.6577 36.2359C21.505 36.0821 21.4192 35.8736 21.4192 35.6562L21.4188 28.1921C21.4184 28.1811 21.4182 28.17 21.4182 28.1589V24.156C21.4182 23.9386 21.5039 23.7301 21.6567 23.5764L22.3429 22.8855C22.4956 22.7318 22.7028 22.6454 22.9187 22.6454H33.4928C33.7087 22.6454 33.9158 22.7318 34.0686 22.8856L34.7548 23.5764C34.9075 23.7301 34.9933 23.9388 34.9933 24.1562V28.1589C34.9933 28.178 34.9927 28.1971 34.9913 28.2161Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
