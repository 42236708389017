import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconTabbarCouponFilled24: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M24 9C24 9.55229 23.5523 10 23 10H22C21.4477 10 21 10.4477 21 11V13C21 13.5523 21.4477 14 22 14H23C23.5523 14 24 14.4477 24 15V18.5858C24 18.851 23.8946 19.1054 23.7071 19.2929L22.2929 20.7071C22.1054 20.8946 21.851 21 21.5858 21L9 21C8.44772 21 8 20.5523 8 20C8 19.4477 7.55228 19 7 19C6.44772 19 6 19.4477 6 20C6 20.5523 5.55228 21 5 21H2.41421C2.149 21 1.89464 20.8946 1.70711 20.7071L0.292893 19.2929C0.105357 19.1054 -1.1593e-08 18.851 0 18.5858L1.5674e-07 15C1.80881e-07 14.4477 0.447715 14 1 14H2C2.55229 14 3 13.5523 3 13V11C3 10.4477 2.55229 10 2 10H1C0.447718 10 2.30222e-06 9.55229 2.32636e-06 9L2.4831e-06 5.41421C2.49469e-06 5.149 0.105359 4.89464 0.292895 4.70711L1.70711 3.29289C1.89465 3.10536 2.149 3 2.41422 3L5 3C5.55229 3 6 3.44772 6 4C6 4.55228 6.44772 5 7 5C7.55229 5 8 4.55228 8 4C8 3.44772 8.44772 3 9 3L21.5858 3C21.851 3 22.1054 3.10536 22.2929 3.2929L23.7071 4.70711C23.8946 4.89465 24 5.149 24 5.41422V9ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17C7.55228 17 8 16.5523 8 16C8 15.4477 7.55228 15 7 15ZM7 13C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11C7.55229 11 8 11.4477 8 12C8 12.5523 7.55229 13 7 13ZM7 7C6.44772 7 6 7.44771 6 8C6 8.55228 6.44772 9 7 9C7.55229 9 8 8.55228 8 8C8 7.44772 7.55229 7 7 7Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
