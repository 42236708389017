import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconUnderDevelopment: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="41"
      viewBox="0 0 40 41"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M25.548.5c1.756 0 3.424.689 4.691 1.956l7.782 7.8A6.59 6.59 0 0 1 40 14.966v11.045c0 1.778-.712 3.445-1.979 4.711l-7.804 7.8a6.6 6.6 0 0 1-4.714 1.978H14.475c-1.78 0-3.447-.711-4.714-1.978l-7.804-7.8A6.57 6.57 0 0 1 0 26.012V14.988c0-1.778.69-3.445 1.957-4.711l7.848-7.822C11.028 1.189 12.74.5 14.497.5z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.428 10.5a1 1 0 0 0-.997.925L8.065 29.64a.8.8 0 0 0 .797.86h1.71a1 1 0 0 0 .997-.925l1.367-18.215a.8.8 0 0 0-.798-.86zm17.434 0a.8.8 0 0 0-.797.86l1.366 18.215a1 1 0 0 0 .997.925h1.71a.8.8 0 0 0 .798-.86l-1.38-18.4a.8.8 0 0 0-.798-.74z"
        fill="#C4C9D4"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 14.15v2.274l5.072 5.072h3.837l-8.335-8.335-.281.281A1 1 0 0 0 6 14.15m28 5.697v-2.288L28.941 12.5h-3.837l8.328 8.328.275-.274a1 1 0 0 0 .293-.707M21.265 12.5h-3.837l8.996 8.996h3.837zm-7.676 0H9.752l8.996 8.996h3.837z"
        fill="#A7AFBE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 16.425v3.421a1 1 0 0 0 .293.708l.65.65a1 1 0 0 0 .707.292h3.421zm28 1.133V14.15a1 1 0 0 0-.293-.708l-.65-.65a1 1 0 0 0-.707-.292h-3.408zM25.103 12.5h-3.837l8.996 8.996h2.088a1 1 0 0 0 .708-.293l.374-.374zm-7.676 0H13.59l8.996 8.996h3.837zm-7.676 0H7.65a1 1 0 0 0-.708.293l-.367.368 8.335 8.335h3.837z"
        fill="#576175"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
