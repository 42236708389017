import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledLimitContainer = styled.div<{ maxHeight: boolean }>`
  ${({ theme, maxHeight }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    ${maxHeight &&
    css`
      background: none;
      height: 100%;
    `}
  `}
`

export const StyledLimitAction = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`

export const StyledOpenChatBtn = styled.span`
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-linkAccent-default']};
  `}
`

export const StyledLimitContent = styled.div`
  ${typography['Body / 14 Medium']}
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 720px;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
  `}
`

export const StyledLimitInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
