import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledAccordionWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
  `}
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 16px;
`

export const StyledAccordionTitle = styled.div`
  ${typography['Body / 14 Bold']}
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}

  & svg {
    color: #6c7993;
  }
`

export const StyledAccordionContent = styled.div``
