import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBonusClub: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M10.5099 0.101506L16.6382 2.63938C16.9649 2.77472 17.2244 3.0342 17.3598 3.36092L19.8985 9.48924C20.0338 9.81595 20.0338 10.1823 19.8985 10.509L17.3598 16.6374C17.2244 16.9641 16.9649 17.2236 16.6382 17.3589L10.5099 19.8976C10.184 20.033 9.81681 20.033 9.4901 19.8976L3.36178 17.3589C3.03506 17.2236 2.77559 16.9641 2.64024 16.6374L0.101506 10.509C-0.0338355 10.1823 -0.0338355 9.81595 0.101506 9.48924L2.63938 3.36092C2.77472 3.0342 3.0342 2.77472 3.36092 2.63938L9.4901 0.101506C9.81595 -0.0338355 10.1832 -0.0338355 10.5099 0.101506ZM17.0971 10.319L15.2824 11.8966L15.2842 11.8957H16.9436C17.0496 11.8957 17.1212 12.0035 17.0807 12.1009L16.1583 14.3276C16.0229 14.6543 15.7635 14.9138 15.4368 15.0491L10.5119 17.0887C10.1851 17.2241 9.81877 17.2241 9.49205 17.0887L4.56716 15.0491C4.24044 14.9138 3.98096 14.6543 3.84562 14.3276L2.92323 12.1009C2.88271 12.0026 2.95512 11.8957 3.06029 11.8957H4.71974L2.90512 10.3182C2.47238 9.94232 2.32927 9.33198 2.54824 8.80268L3.57149 6.33291C3.60942 6.23895 3.72838 6.21136 3.80511 6.27774L4.92922 7.2553V5.68723C4.92922 5.14759 5.25335 4.66139 5.75162 4.45536L8.21105 3.43642C8.30932 3.3959 8.41621 3.46832 8.41621 3.57349V4.57519C8.41621 4.98984 8.22311 5.38034 7.89467 5.63292L5.32835 7.60185V11.8957L10.0015 15.9577L14.6747 11.8957V7.60185L12.1084 5.63292C11.7791 5.38034 11.5868 4.98984 11.5868 4.57519V3.57349C11.5868 3.46745 11.6946 3.3959 11.792 3.43642L14.2514 4.45536C14.7488 4.66226 15.0738 5.14845 15.0738 5.68723V7.25617L16.1979 6.2786C16.2738 6.21222 16.3928 6.24067 16.4316 6.33377L17.454 8.80355C17.6729 9.33285 17.5298 9.94318 17.0971 10.319Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
