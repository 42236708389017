import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  EPasswordConditional,
  PasswordConditionalProps
} from 'shared/ui/password-conditional/password-conditional.types'
import {
  StyledPasswordConditional,
  StyledPasswordStrength,
  StyledPasswordStrengthWrapper
} from 'shared/ui/password-conditional/password-conditional.styled'

import { IconCheckOutline, IconDotFilled } from '../Icon/change-password'

export const PasswordConditional: FC<PasswordConditionalProps> = memo(
  ({
    password,
    title,
    regExp,
    afterSubmit,
    touched,
    isPasswordStrength,
    strength,
    isWeak
  }) => {
    const isCorrectConditional = password.match(regExp)
    const [t] = useTranslation()

    let type = EPasswordConditional.DEFAULT

    if (isPasswordStrength) {
      if (isCorrectConditional && !isWeak && password.length) {
        type = EPasswordConditional.CORRECT
      }
      if ((!isCorrectConditional || isWeak) && password.length) {
        type = EPasswordConditional.INCORRECT
      }
    } else {
      if (isCorrectConditional && password.length) {
        type = EPasswordConditional.CORRECT
      }
      if (!isCorrectConditional && afterSubmit && password.length) {
        type = EPasswordConditional.INCORRECT
      }
    }

    if (!touched && !isCorrectConditional) {
      type = EPasswordConditional.DEFAULT
    }

    return (
      <StyledPasswordConditional
        isPasswordStrength={isPasswordStrength}
        type={type}
      >
        {type === EPasswordConditional.CORRECT ? (
          <IconCheckOutline />
        ) : (
          <IconDotFilled />
        )}
        <StyledPasswordStrengthWrapper>
          {t(title)}
          {` `}
          {isPasswordStrength && strength && (
            <StyledPasswordStrength isWeak={isWeak}>
              {t(strength)}
            </StyledPasswordStrength>
          )}
        </StyledPasswordStrengthWrapper>
      </StyledPasswordConditional>
    )
  }
)
