import { zxcvbn, ZxcvbnResult } from '@zxcvbn-ts/core'

export const REG_PASSWORD_SEPARATED = {
  symbolsCounts: /.{6}/,
  latinLetter: /^[a-zA-Z0-9()*_+-=!@;:#$%^&*,.<>/?"|'\][{}]+$/,
  atLeastOneDigit: /(?=\D*\d)/,
  uppercaseAndLowercaseLetter: /(?=.*?[A-Z])(?=.*?[a-z])/,
  allAbove:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=\D*\d)([a-zA-Z0-9()*_+-=!@;:#$%^&*,.<>/?"|'\][{}]{6,})$/,
  invalidSymbols: /[^a-zA-Z0-9()*_+-=!@;:#$%^&*,.<>/?"|'\][{}]/
}

export enum EPasswordStrength {
  WEAK = 'weak',
  MEDIUM = 'medium',
  STRONG = 'strong'
}

interface PasswordConditions {
  uniqueChars: boolean
  noRepeatChars: boolean
  atLeastThreeLetters: boolean
  specialChar: boolean
  upperAndLower: boolean
}

const SCORE_INCREMENT = 20
const MEDIUM_SCORE_THRESHOLD = 40
const STRONG_SCORE_THRESHOLD = 80

interface IOptionalConditions {
  specialCharRegex: RegExp
  repeatCharRegex: RegExp
  upperLowerRegex: RegExp
  checkAllConditions(password: string): PasswordConditions
}

const optionalConditions: IOptionalConditions = {
  specialCharRegex: /[~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?/]/,
  repeatCharRegex: /(.)\1{2,}/,
  upperLowerRegex: /(?=.*[a-z])(?=.*[A-Z])/,

  checkAllConditions(password: string) {
    const uniqueCharCount = new Set(password).size
    const letterMatches = password.match(/[a-zA-Z]/g) || []
    const atLeastThreeLetters = letterMatches.length >= 3

    return {
      uniqueChars: uniqueCharCount >= 3,
      noRepeatChars: !this.repeatCharRegex.test(password),
      atLeastThreeLetters,
      specialChar: this.specialCharRegex.test(password),
      upperAndLower: this.upperLowerRegex.test(password)
    }
  }
}

const calculateScore = (conditions: PasswordConditions): number => {
  return Object.values(conditions).filter(Boolean).length * SCORE_INCREMENT
}

export const passwordStrength = (
  password: string,
  bannedPasswords: string[]
): EPasswordStrength => {
  const zxcvbnResult: ZxcvbnResult | null = zxcvbn(password)

  const isPasswordBanned = bannedPasswords.some((bannedPassword) =>
    password.includes(bannedPassword)
  )

  if (isPasswordBanned) {
    return EPasswordStrength.WEAK
  }

  if (
    zxcvbnResult.score < 2 ||
    !REG_PASSWORD_SEPARATED.allAbove.test(password)
  ) {
    return EPasswordStrength.WEAK
  }

  const conditions = optionalConditions.checkAllConditions(password)
  const score = calculateScore(conditions)

  if (score >= MEDIUM_SCORE_THRESHOLD && score < STRONG_SCORE_THRESHOLD) {
    return EPasswordStrength.MEDIUM
  }

  if (score >= STRONG_SCORE_THRESHOLD) {
    return EPasswordStrength.STRONG
  }

  return EPasswordStrength.WEAK
}
