import React from 'react'
import {
  reducer,
  sliceKey
} from 'astra-core/containers/BetsHistoryProvider/slice'
import { betsHistoryProviderSaga } from 'astra-core/containers/BetsHistoryProvider/saga'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import {
  useAppSelector,
  useInjectReducer,
  useInjectSaga
} from 'shared/lib/@reduxjs'
import { BetsHistory } from 'widgets/bets-history'
import { BetsHistoryLegacy } from 'widgets/bets-history-legacy'

export const BetsHistoryPageContainer = () => {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: betsHistoryProviderSaga })
  const config = useAppSelector(selectConfig)

  if (config.IS_TRANSIT) {
    return <BetsHistoryLegacy />
  }

  return <BetsHistory />
}
