import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { Button } from 'shared/ui/Button'

export const StyledProfileMenuWrapper = styled.aside`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset -1px 0 ${theme.tokens.colors.border['border-default-gray']};
    display: grid;
    grid-area: profile-menu;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    width: 100%;
  `}
`

export const StyledProfilePhoneContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: ${theme.tokens.alias.space['space-xl-24']}px
      ${theme.tokens.alias.space['space-l-20']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledProfileBalanceContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledNavAndExitContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledProfileUserTitle = styled.div`
  ${typography['Hint / 10 EXTRABOLD']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
  `}
`

export const StyledProfileLogin = styled.div`
  ${typography['Title / 18 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledButtonExit = styled(Button)`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    background-color: transparent;
    color: ${theme.tokens.colors.text['text-secondary']};
    column-gap: ${theme.tokens.alias.space['space-xs-8']}px;
    justify-content: flex-start;
    width: 100%;
    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-default']};
    }
    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-2']};
    }
  `}
`
