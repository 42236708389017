import { getCurrentLng } from 'astra-core'

import { i18nLocalToLegacyLang } from 'shared/lib/locale'

import { baseLegacyApi } from '../api'

import {
  UserRegPromocodesRequest,
  UserRegPromocodesResponse
} from './bonuses.types'

export const bonusesApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    regPromocodes: builder.mutation<
      UserRegPromocodesResponse,
      UserRegPromocodesRequest
    >({
      query: (args) => ({
        method: 'POST',
        url: 'actions/regpromocodes',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    })
  })
})

export const { useRegPromocodesMutation } = bonusesApi
