import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsTypes
} from 'astra-core/containers/BetsHistoryProvider'

import { Select } from 'shared/ui/select'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { ALL_OPTION_VALUE, BET_TYPES_OPTIONS } from './constants'

export const SelectBetTypes = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const betsTypes = useAppSelector(selectBetsHistoryBetsTypes)

  const optionsSelectCheckbox = useMemo(() => BET_TYPES_OPTIONS(t), [t])

  const handleBetsTypesChange = useCallback(
    (value) => {
      if (value.value === ALL_OPTION_VALUE) {
        dispatch(betsHistoryProviderActions.setFilterBetsTypes(null))
      } else {
        dispatch(betsHistoryProviderActions.setFilterBetsTypes([value]))
      }
    },
    [dispatch]
  )

  return (
    <Select
      options={optionsSelectCheckbox}
      value={betsTypes?.length ? betsTypes[0] : optionsSelectCheckbox[0]}
      onChange={handleBetsTypesChange}
    />
  )
}
