import { useMemo } from 'react'

import { responsibleGamblingApi } from 'entities/legacy-api/lib/responsible-gambling'

const { useGetPeriodsQuery, useGetStatusQuery } = responsibleGamblingApi

export const useGetBreakData = () => {
  const { data: dataPeriods, isLoading: periodsLoading } = useGetPeriodsQuery(
    {}
  )
  const { data: statusData, isLoading: statusLoading } = useGetStatusQuery({})

  const block = useMemo(
    () => (dataPeriods ? dataPeriods?.data.block : []),
    [dataPeriods]
  )

  const blockOptions = useMemo(
    () =>
      block.map((item) => {
        return {
          value: item.key,
          label: item.period
        }
      }),
    [block]
  )

  const blockStatus = useMemo(() => statusData?.data?.block, [statusData])

  const periodBlockLabel = useMemo(
    () =>
      blockOptions.find((item) => item.value === blockStatus?.sum_period)
        ?.label,
    [blockOptions, blockStatus?.sum_period]
  )

  return {
    periodBlockLabel,
    blockStatus,
    blockOptions,
    periodsLoading,
    statusLoading
  }
}
