import { getCurrentLng } from 'astra-core'

import ru from 'entities/responsible-gambling/locales/ru/translations.json'
import en from 'entities/responsible-gambling/locales/en/translations.json'
import kz from 'entities/responsible-gambling/locales/kz/translations.json'
import ch from 'entities/responsible-gambling/locales/ch/translations.json'
import uz from 'entities/responsible-gambling/locales/uz/translations.json'

import { Card1, Card2, Card3, Card4, Card5, Card6, Card7 } from '../ui'

export const InfoTabLocales = {
  ru,
  en,
  kz,
  uz,
  ch
}[getCurrentLng() as 'ru' | 'en' | 'kz' | 'ch' | 'uz']

export const CARDS = [
  {
    title: InfoTabLocales['card-1-title'],
    card: <Card1 />
  },
  {
    title: InfoTabLocales['card-2-title'],
    card: <Card2 />
  },
  {
    title: InfoTabLocales['card-3-title'],
    card: <Card3 />
  },
  {
    title: InfoTabLocales['card-4-title'],
    card: <Card4 />
  },
  {
    title: InfoTabLocales['card-5-title'],
    card: <Card5 />
  },
  {
    title: InfoTabLocales['card-6-title'],
    card: <Card6 />
  },
  {
    title: InfoTabLocales['card-7-title'],
    card: <Card7 />
  }
]
