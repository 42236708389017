import styled, { css } from 'styled-components'
import { range } from 'lodash'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'

export const SportResultsLoader = () => {
  return <ResultsItem />
}

const ResultsItem = () => (
  <StyledResultWrapper>
    <StyledResultTitle>
      <ContentLoader borderRadius={4} height={16} width={16} />
      <ContentLoader borderRadius={4} height={12} width={64} />
    </StyledResultTitle>
    <StyledResultEventWrapper>
      {range(4).map((idx) => (
        <ResultsItemEvent key={idx} />
      ))}
    </StyledResultEventWrapper>
  </StyledResultWrapper>
)

const ResultsItemEvent = () => (
  <StyledResultEvent>
    <ContentLoader borderRadius={4} height={12} width={120} />
    <StyledResultEventCenter>
      <ContentLoader borderRadius={4} height={12} width={167} />
      <ContentLoader borderRadius={4} height={12} width={127} />
      <ContentLoader borderRadius={4} height={12} width={39} />
    </StyledResultEventCenter>
    <StyledResultEventRight>
      {range(4).map((idx) => (
        <ContentLoader borderRadius={4} height={32} key={idx} width={43} />
      ))}
    </StyledResultEventRight>
  </StyledResultEvent>
)

const StyledResultWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias.space['space-xs-8']}px;
    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    overflow: auto;
  `}
`

const StyledResultTitle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-event-title']};
    box-shadow: ${theme.tokens.colors.divider['divider-primary']} 0px -1px inset;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

const StyledResultEventWrapper = styled.div`
  & > div:last-child {
    box-shadow: none;
  }
`

const StyledResultEvent = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: ${theme.tokens.colors.divider['divider-primary']} 0px -1px inset;
    display: grid;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    grid-template-columns: auto 1fr auto;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

const StyledResultEventCenter = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxs-6']}px;
  `}
`

const StyledResultEventRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`
