import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledCalendarTitleDate = styled.span`
  ${typography['Body / 14 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding: 6px 0px;
    text-transform: capitalize;
  `};
`
