import { StyledMainPageEventsWrapper } from 'pages/page-main/Main/components/live-events/live-events.styled'

import {
  MainPageEventsHeader,
  MainPageEventsTable,
  MainPageSportList
} from './components'

export const MainPageLiveEvents = () => {
  return (
    <StyledMainPageEventsWrapper>
      <MainPageEventsHeader />

      <MainPageSportList />

      <MainPageEventsTable />
    </StyledMainPageEventsWrapper>
  )
}
