import { memo } from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { Typography } from 'shared/ui/typography'
import { IconChevronRightOutline16 } from 'shared/ui/Icon/General/icon-chevron-right-outline-16'
import { ERoutes } from 'shared/types/routes'
import { FAQCategoryParams, FAQ_CATEGORIES } from 'entities/faq'
import { useFAQTranslation } from 'entities/faq/lib/utils'

import {
  StyledFAQContainer,
  StyledFAQList,
  StyledFAQListItem
} from './FAQPage.styled'

export const FAQPage = memo(() => {
  const { categoryId } = useParams<FAQCategoryParams>()

  return (
    <StyledFAQContainer>
      <StyledFAQList>
        {FAQ_CATEGORIES[categoryId]?.map((item) => (
          <LinkFAQ faqId={item} key={item} />
        ))}
      </StyledFAQList>
    </StyledFAQContainer>
  )
})

const LinkFAQ = ({ faqId }) => {
  const { categoryId } = useParams<FAQCategoryParams>()
  const translation = useFAQTranslation(faqId)

  const title = translation?.title

  if (!title) return null

  return (
    <StyledFAQListItem
      to={generatePath(ERoutes.DetailsFAQ, {
        categoryId,
        faqId
      })}
    >
      <Typography color="text-primary-2" font="Body / 14 Medium">
        {title}
      </Typography>
      <IconChevronRightOutline16 />
    </StyledFAQListItem>
  )
}
