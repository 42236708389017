import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconBalanceOfFilled24: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M2.90675 5.25044C2.90675 4.00756 3.9063 3 5.13931 3H20.2091C20.5174 3 20.7672 3.25189 20.7672 3.56261V4.68783C20.7672 4.99855 20.5174 5.25044 20.2091 5.25044H7.93001C7.62176 5.25044 7.37187 5.50233 7.37187 5.81305C7.37187 6.12377 7.62176 6.37566 7.93002 6.37566H19.903C20.3186 6.37566 20.7172 6.54207 21.011 6.83831L22.541 8.38047C22.83 8.67176 22.9518 9.03217 22.9999 9.42922V16.853C22.9464 17.2389 22.8216 17.5906 22.541 17.8735L21.011 19.4156C20.7172 19.7119 20.3186 19.8783 19.903 19.8783H10.9241L11.6734 19.123C12.1882 18.6041 12.4791 17.9117 12.4791 17.1765V14.2605C12.4791 13.5358 12.1954 12.8295 11.6701 12.3107L9.63853 10.254L9.63703 10.2525C9.12207 9.73343 8.4315 9.43701 7.69479 9.43701H4.80191C4.59957 9.43701 4.399 9.45944 4.20385 9.50321C3.72086 9.61154 3.26996 9.85098 2.90675 10.2053V5.25044ZM20.7673 13.127C20.7673 13.7484 20.2675 14.2522 19.651 14.2522C19.0345 14.2522 18.5348 13.7484 18.5348 13.127C18.5348 12.5055 19.0345 12.0018 19.651 12.0018C20.2675 12.0018 20.7673 12.5055 20.7673 13.127Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M3.56951 10.9568C3.8929 10.622 4.33867 10.437 4.80191 10.437H5.52036L5.5239 16.7166L4.24903 15.4417C3.9783 15.171 3.5353 15.171 3.26457 15.4417C2.99384 15.7124 2.99384 16.1555 3.26457 16.4262L5.72571 18.8873C5.7897 18.9513 5.86847 19.0005 5.95215 19.035C6.0309 19.0744 6.12442 19.0892 6.21795 19.094C6.40498 19.094 6.5822 19.0153 6.70524 18.8922L9.17134 16.4262C9.44205 16.1555 9.44205 15.7124 9.17134 15.4417C8.9006 15.171 8.45758 15.171 8.18687 15.4417L6.91199 16.7166L6.91197 10.437H7.69479C8.158 10.437 8.59499 10.622 8.9271 10.9568L10.9635 13.0183C11.2956 13.3443 11.4791 13.7936 11.4791 14.2605V17.1765C11.4791 17.6434 11.2956 18.0839 10.9635 18.4187L8.91829 20.4802C8.59491 20.815 8.14918 21 7.68597 21H4.79309C4.32987 21 3.89288 20.815 3.56077 20.4802L1.51565 18.4187C1.18354 18.0928 1 17.6435 1 17.1765V14.2605C1 13.7936 1.18354 13.3531 1.51565 13.0183L3.56951 10.9568Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
