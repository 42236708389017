import React from 'react'

import {
  ColumnLayout,
  ColumnTypes,
  ColumnLayoutContent
} from 'shared/lib/layout'

import { SportGames } from './SportGames'

export const PageSportGames = () => {
  return (
    <ColumnLayout type={ColumnTypes.ONE_COLUMN}>
      <ColumnLayoutContent>
        <SportGames />
      </ColumnLayoutContent>
    </ColumnLayout>
  )
}

export default PageSportGames
