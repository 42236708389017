import { ESportsCodes } from 'astra-core/containers/CommonDataProvider/types'
import { TResults } from 'astra-core/containers/ResultsProvider/types'
import { EventResult } from 'betweb-openapi-axios/dist/api'

type TIsNotLive = {
  isNotLive: boolean
}

export interface IResultDateAndTimeResponse {
  resultDate: string
  resultTime: string
}

export interface IResultTitleSetsProps {
  events: EventResult[]
  sportName: ESportsCodes
}

export interface IGameScore {
  homeScore: number | string
  awayScore: number | string
}

export interface IResultGameScore {
  isGameScore: boolean
  gameScore: IGameScore | null
}

export type TChampionshipWithResults = {
  events: TResults['events']
  tournament: TResults['tournament'] & Partial<TIsNotLive>
}

export type TChampionshipProps = {
  championship: TChampionshipWithResults
}

export type TEventProps = {
  event: EventResult
}

export enum EResultMenuTabTypes {
  COMPLETED = 'completed',
  LIVE = 'live'
}

export type TResultWithFilteredEvents = TResults & {
  filteredEvents: EventResult[]
}

export type TChampionships =
  | TResults[]
  | (TResultWithFilteredEvents | undefined)[]
