import { getCurrentLng } from 'astra-core/utils'

export const getIframeData = ({
  srcData: {
    currencyName,
    gameNameId,
    token,
    userId,
    launchUrl,
    demoLaunchUrl,
    operator
  }
}) => {
  const langName = getCurrentLng()

  // TODO: find out default currency for demo

  const src =
    userId && token
      ? `${launchUrl}/${gameNameId}?user=${userId}&token=${token}&lang=${langName}&currency=${currencyName}&operator=${operator}`
      : `${demoLaunchUrl}/${gameNameId}?currency=USD&lang=${langName}&return_url=https://demo.spribe.io/game-browser/`

  return {
    id: 'turbo-games-iframe',
    styles: {
      width: '100%',
      height: '100%',
      border: 'none',
      outline: 'none'
    },
    src
  }
}
