import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { favouritesProviderActions } from 'astra-core/containers/FavouritesProvider'

import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { Button } from 'shared/ui/Button'
import { IconTrashFilled } from 'shared/ui/Icon/General/IconTrashFilled'
import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'
import { BasketButtonOpenBasketForSmallWindow } from 'features/basket'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { StyledFilters } from './Filters.styled'
import { SportsTypesTabs } from './components'
import { NotificationClearSuccess } from './components/NotificationClear'

export const Filters = memo(() => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()

  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  const notifyClearSuccess = usePopUpNotification({
    content: {
      children: (props) => <NotificationClearSuccess {...props} />
    },
    options: {
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: 5000
    }
  })

  const handleClearFavouriteEvents = useCallback(() => {
    dispatch(favouritesProviderActions.deleteFavouriteEvents())
    notifyClearSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <StyledFilters>
      <SportsTypesTabs />

      <Button
        icon={IconTrashFilled}
        view="outline"
        onClick={handleClearFavouriteEvents}
      >
        {t('clear all')}
      </Button>

      {(isTabletOrMobile || isSmall) && (
        <BasketButtonOpenBasketForSmallWindow isSmall />
      )}
    </StyledFilters>
  )
})
