import React, { useRef } from 'react'

import { Modal } from 'shared/ui/modal'
import { ModalBody } from 'shared/ui/modal/modal.styled'
import { Button } from 'shared/ui/Button'
import { ModalRef } from 'shared/ui/modal/modal.types'

import { StyledTestCoin, TestCoinContent } from './test-coin.styled'

const animationSrc = '/animations/bonus-club-coin.webm'
const fallbackSrc = '/images/banner-first-deposit.png'

export const TestCoin = () => {
  const modalRef = useRef<ModalRef>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <StyledTestCoin>
      <TestCoinContent>
        <video
          height="180px"
          id="banner-video"
          ref={videoRef}
          muted
          playsInline
          onMouseEnter={() => {
            videoRef.current?.play()
          }}
        >
          <source src={animationSrc} type="video/webm" />
          <img alt="banner-first-deposit" src={fallbackSrc} />
        </video>

        <Button onClick={() => modalRef.current?.open()}>Click me</Button>

        <Modal ref={modalRef} title="Ура! Монетка вращается!">
          <ModalBody>
            <video
              height="180px"
              id="banner-video"
              preload="none"
              autoPlay
              muted
              playsInline
            >
              <source src={animationSrc} type="video/webm" />
            </video>
          </ModalBody>
        </Modal>
      </TestCoinContent>
    </StyledTestCoin>
  )
}
