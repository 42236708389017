import React from 'react'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'
import {
  useMainPageLiveEventsData,
  useMainPageTopEventsData
} from 'pages/page-main/MainPageContainer/hooks'
import { useFetchBroadcastsAvailability } from 'hooks'

import { Main } from '../Main'

import { reducer, sliceKey } from './slice'
import { mainPageContainerSaga } from './saga'

export function MainPageContainer() {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: mainPageContainerSaga })

  useFetchBroadcastsAvailability()
  useMainPageTopEventsData()
  useMainPageLiveEventsData()

  return <Main />
}
