import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ERoutes } from 'shared/types/routes'
import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { Button } from 'shared/ui/Button'

import {
  BalanceValueContainer,
  StyledBalanceTitle,
  StyledBalanceControlsWrapper,
  BalanceButtonContainer
} from './balance-controls.styled'
import { BalanceValue } from './balance-value'

export const BalanceControls = memo(() => {
  const [t] = useTranslation()
  const routeRedirect = useRouteRedirect()
  const handleRedirectList = (route: ERoutes) => () => {
    routeRedirect({ route })
  }

  return (
    <StyledBalanceControlsWrapper>
      <BalanceValueContainer>
        <StyledBalanceTitle>{t('balance')}</StyledBalanceTitle>
        <BalanceValue />
      </BalanceValueContainer>
      <BalanceButtonContainer>
        <Button onClick={handleRedirectList(ERoutes.ProfileBalance)}>
          {t('top up')}
        </Button>
        <Button
          view="secondary-large"
          onClick={handleRedirectList(ERoutes.Withdraw)}
        >
          {t('withdraw')}
        </Button>
      </BalanceButtonContainer>
    </StyledBalanceControlsWrapper>
  )
})
