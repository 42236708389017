import styled, { css } from 'styled-components'

export const Content = styled.div<{ hideHeader: boolean }>`
  display: grid;
  grid-area: content;
  min-height: 100vh;

  ${(p) =>
    !p.hideHeader &&
    css`
      padding-top: 48px;
    `}
`
