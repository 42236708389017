import React from 'react'

import { Casino } from './Casino'
import * as S from './page.styled'

export const PageCasino = () => (
  <S.Page>
    <S.PageContent>
      <Casino />
    </S.PageContent>
  </S.Page>
)

export default PageCasino
