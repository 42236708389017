import { useTranslation } from 'react-i18next'

import ru from '../locales/ru/questions.json'
import en from '../locales/en/questions.json'
import ch from '../locales/ch/questions.json'
import kz from '../locales/kz/questions.json'
import tr from '../locales/tr/questions.json'
import uz from '../locales/uz/questions.json'

const locales = {
  ru,
  en,
  ch,
  kz,
  tr,
  uz
}

export const getFAQTranslationJSON = (faqId: string, lang: string) =>
  locales[lang][faqId]

export const useFAQTranslation = (faqId: string) => {
  const { i18n } = useTranslation()

  return getFAQTranslationJSON(faqId, i18n.language)
}
