import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from 'shared/ui/Carousel'
import {
  NardGameCard,
  DartsGameCard,
  NHL21GameCard,
  BoxingGameCard,
  BridgeGameCard,
  CrashBoxingGameCard
} from 'features/game-card'

import {
  StyledBetGamesTitle,
  StyledBetGamesTitleWrapper,
  StyledCarouselWrapper
} from './BetGames.styled'
import {
  CHILD_MAX_WIDTH,
  SLIDES_GAP,
  SLIDES_HEIGHT,
  SLIDES_MIN_WIDTH
} from './BetGames.constants'

export const BetGames: FC = () => {
  const [t] = useTranslation()

  return (
    <div>
      <StyledBetGamesTitleWrapper>
        <StyledBetGamesTitle>{t('bets 24/7')}</StyledBetGamesTitle>
      </StyledBetGamesTitleWrapper>

      <StyledCarouselWrapper>
        <Carousel
          childHeight={SLIDES_HEIGHT}
          childMaxWidth={CHILD_MAX_WIDTH}
          childMinWidth={SLIDES_MIN_WIDTH}
          leftOffset={0}
          rightOffset={0}
          slidesGap={SLIDES_GAP}
        >
          <NardGameCard />
          <DartsGameCard />
          <NHL21GameCard />
          <BoxingGameCard />
          <BridgeGameCard />
          <CrashBoxingGameCard />
        </Carousel>
      </StyledCarouselWrapper>
    </div>
  )
}
