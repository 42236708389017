import React, { FC } from 'react'

import { LayoutError } from 'layouts/error'

import { NotFoundPage } from './not-found-page'

export const PageNotFound: FC = () => (
  <LayoutError>
    <NotFoundPage />
  </LayoutError>
)

export default PageNotFound
