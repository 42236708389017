import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconExit: FC<IconProps> = (props) => (
  <Icon {...props} size={24}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58994 2.41006C4.85251 2.14749 5.20859 2 5.5799 2H11.4201C11.7914 2 12.1475 2.14749 12.4101 2.41006L13.5899 3.58994C13.8525 3.85251 14 4.20859 14 4.5799V9.39999H11C9.56409 9.39999 8.40002 10.5641 8.40002 12C8.40002 13.4359 9.56409 14.6 11 14.6H14V19.4201C14 19.7914 13.8525 20.1475 13.5899 20.4101L12.4101 21.5899C12.1475 21.8525 11.7914 22 11.4201 22H5.5799C5.20859 22 4.85251 21.8525 4.58994 21.5899L3.41006 20.4101C3.14749 20.1475 3 19.7914 3 19.4201V4.5799C3 4.20859 3.14749 3.85251 3.41006 3.58994L4.58994 2.41006Z"
        fill="currentColor"
      />
      <path
        d="M18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289C16.9024 8.68342 16.9024 9.31658 17.2929 9.70711L18.5858 11H11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H18.5858L17.2929 14.2929C16.9024 14.6834 16.9024 15.3166 17.2929 15.7071C17.6834 16.0976 18.3166 16.0976 18.7071 15.7071L21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L18.7071 8.29289Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
