import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { INavMenuItemActive } from './profile-nav-menu.type'

export const StyledNavMenu = styled.nav`
  display: grid;
  row-gap: 1px;
`

export const StyledNavMenuItem = styled(NavLink)<INavMenuItemActive>`
  ${typography['Body / 14 Medium']}
  ${({ theme, $isActiveItem }) => css`
    align-items: center;
    background: ${$isActiveItem
      ? theme.tokens.colors.surface['surface-hover']
      : ''};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    color: ${$isActiveItem
      ? theme.tokens.colors.text['text-primary']
      : theme.tokens.colors.text['text-secondary']};
    column-gap: ${theme.tokens.alias.space['space-xs-8']}px;
    display: flex;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
    transition: background-color 0.25s ease;

    &:hover {
      background: ${$isActiveItem
        ? ''
        : theme.tokens.colors.surface['surface-default']};
    }

    & svg {
      color: ${$isActiveItem
        ? theme.tokens.colors.icon['icon-primary']
        : theme.tokens.colors.icon['icon-secondary-2']};
    }
  `}
`

export const StyledNavMenuItemLabel = styled.span`
  display: inline-block;
  flex-grow: 1;
`

export const StyledNavMenuItemCounter = styled.div<{ $isActiveItem?: boolean }>`
  ${({ theme, $isActiveItem }) => css`
    ${typography['Hint / 10 Bold']}
    align-items: center;
    background-color: ${$isActiveItem
      ? theme.tokens.colors.surface['surface-active2']
      : theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-xs-4'
    ]}px;
    color: ${$isActiveItem
      ? theme.tokens.colors.text['text-invert']
      : theme.tokens.colors.text['text-secondary']};
    display: flex;
    flex-shrink: 0;
    height: ${theme.tokens.alias.space['space-m-16']}px;
    justify-content: center;
    min-width: ${theme.tokens.alias.space['space-m-16']}px;
    padding: 0 ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`
