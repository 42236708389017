import styled, { css } from 'styled-components'

import { IconSupportBanner } from 'shared/ui/Icon/General/IconSupportBanner'
import { AppLink } from 'shared/lib/app-link'

export const StyledSupportPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  ${({ theme }) => `
    background: ${theme.tokens.colors.surface['surface-base']};
  `}
`

export const StyledBanner = styled.div`
  align-items: flex-end;
  background: #262626;
  border-radius: 4px;
  color: white;
  display: flex;
  grid-area: banner;
  min-height: 340px;
  overflow: hidden;
  padding: 0 0 24px 24px;
`
export const StyledBannerContent = styled.div`
  max-width: 270px;
  position: relative;
`
export const StyledBannerLabel = styled.h2`
  ${({ theme }) => `
font-size: ${theme.fonts.size.xl};
font-weight: ${theme.fonts.weight.bold};
`};
  color: #fff;
  padding-bottom: 24px;
`
export const StyledBannerPhone = styled.div`
  ${({ theme }) => `
font-size: ${theme.fonts.size.xxl};
font-weight: ${theme.fonts.weight.bold};
`};
  color: #fff;
  display: flex;
  padding-bottom: 8px;

  & span {
    margin-right: 8px;
  }
`
export const StyledBannerValue = styled.p`
  ${({ theme }) => `
font-size: ${theme.fonts.size.m};
font-weight: ${theme.fonts.weight.regular};
`};
  color: #fff;
`

export const StyledItem = styled.div`
  ${({ theme }) => `
border: 1px solid ${theme.colors.default.primary[20]};
`};
  ${(props) =>
    props.id === 'support-faq' &&
    css`
      grid-area: faq;
    `}
  ${(props) =>
    props.id === 'support-rules' &&
    css`
      grid-area: rules;
    `}
  ${(props) =>
    props.id === 'support-chat' &&
    css`
      grid-area: chat;
    `}
  border-radius: 4px;
  padding: 16px;
`

export const StyledItemContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
`
export const StyledItemLabel = styled.h2`
  ${({ theme }) => `
color: ${theme.colors.default.primary[90]};
font-size: ${theme.fonts.size.l};
font-weight: ${theme.fonts.weight.bold};
`};
  align-items: center;
  display: flex;
  padding-bottom: 16px;

  & span {
    margin-right: 8px;
  }
`
export const StyledItemDescr = styled.p`
  ${({ theme }) => `
color: ${theme.colors.fixed.primary[50]};
font-size: ${theme.fonts.size.m};
font-weight: ${theme.fonts.weight.regular};
`};
  margin-bottom: auto;
  padding-bottom: 16px;
`

export const StyledIconSupportUnion = styled(IconSupportBanner)`
  height: 355px;
  left: -827px;
  position: absolute;
  top: -165px;
  width: 2073px;
`

export const StyledItemRout = styled(AppLink)`
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;

  ${({ theme }) => `
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
