import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CompletionStatus, EventStatus } from 'betweb-openapi-axios/dist/api'

import { Typography } from 'shared/ui/typography'
import {
  ESportColumn,
  sportColumnName
} from 'features/event-sets/lib/constants'
import { getGameScore, hasGameScore } from 'widgets/results/utils/constants'

import { IResultTitleSetsProps, TEventProps } from '../../results.types'
import { EEventStatisticsCodes } from '../../../../shared/lib/events/types'

import {
  StyledResultSetsWrapper,
  StyledResultCancelled,
  StyledResultPeriodScore,
  StyledResultCurrentPeriodScore,
  StyledResultTotalScore,
  StyledResultPeriodScoreWrapper,
  StyledCurrentServerPointWrapper,
  CurrentServerPoint
} from './result-sets.styled'

export const ResultSets: FC<TEventProps> = ({ event }) => {
  const [t] = useTranslation()

  const home = event?.basicStatistics?.currentScore?.homeScore
  const away = event?.basicStatistics?.currentScore?.awayScore
  const server = event.liveStatistics?.find(
    (s) => s.code === EEventStatisticsCodes.CurrentServer
  )

  const gameScore = useMemo(() => getGameScore(event), [event])
  const isMatchCancelled = useMemo(
    () => event.status === EventStatus.Cancelled,
    [event.status]
  )

  const isLive = useMemo(
    () => CompletionStatus.Live === event.completionStatus,
    [event.completionStatus]
  )

  const periodScores = event?.basicStatistics?.scoresByPeriod || []

  if (isMatchCancelled) {
    return (
      <StyledResultCancelled>
        <Typography color="text-secondary-2" font="Body / 12 Regular">
          {t('match cancelled')}
        </Typography>
      </StyledResultCancelled>
    )
  }

  return (
    <StyledResultSetsWrapper>
      {isLive && (
        <StyledCurrentServerPointWrapper>
          <CurrentServerPoint isVisible={server?.value === '1'} />
          <CurrentServerPoint isVisible={server?.value === '2'} />
        </StyledCurrentServerPointWrapper>
      )}
      {gameScore && (
        <StyledResultPeriodScoreWrapper>
          <StyledResultPeriodScore>
            <Typography color="text-secondary-2" font="Body / 12 Regular">
              {gameScore.homeScore}
            </Typography>
            <Typography color="text-secondary-2" font="Body / 12 Regular">
              {gameScore.awayScore}
            </Typography>
          </StyledResultPeriodScore>
        </StyledResultPeriodScoreWrapper>
      )}
      <StyledResultPeriodScoreWrapper>
        {periodScores
          .slice()
          .reverse()
          .map((period, i, array) => {
            const keyId = i + array.length

            if (i === 0 && isLive)
              return (
                <StyledResultPeriodScore key={keyId}>
                  <StyledResultCurrentPeriodScore>
                    <Typography
                      color="text-secondary-2"
                      font="Body / 12 Regular"
                    >
                      {period.homeScore}
                    </Typography>
                    <Typography
                      color="text-secondary-2"
                      font="Body / 12 Regular"
                    >
                      {period.awayScore}
                    </Typography>
                  </StyledResultCurrentPeriodScore>
                </StyledResultPeriodScore>
              )

            return (
              <StyledResultPeriodScore key={keyId}>
                <Typography color="text-secondary-2" font="Body / 12 Regular">
                  {period.homeScore}
                </Typography>
                <Typography color="text-secondary-2" font="Body / 12 Regular">
                  {period.awayScore}
                </Typography>
              </StyledResultPeriodScore>
            )
          })}
      </StyledResultPeriodScoreWrapper>
      <StyledResultTotalScore>
        <Typography color="text-primary-2" font="Body / 12 Bold">
          {home}
        </Typography>
        <Typography color="text-primary-2" font="Body / 12 Bold">
          {away}
        </Typography>
      </StyledResultTotalScore>
    </StyledResultSetsWrapper>
  )
}

export const ResultTitleSets: FC<IResultTitleSetsProps> = ({
  events,
  sportName
}) => {
  const [t] = useTranslation()

  const eventWithMaxScoresByPeriod = useMemo(() => {
    return events.reduce((maxEvent, currentEvent) => {
      const maxLength = maxEvent.basicStatistics.scoresByPeriod.length
      const currentLength = currentEvent.basicStatistics.scoresByPeriod.length

      return currentLength > maxLength ? currentEvent : maxEvent
    }, events[0])
  }, [events])

  const titleSetsLetter = useMemo(
    () => t(sportColumnName[sportName] ?? ESportColumn.T),
    [sportName, t]
  )

  const periodCount =
    eventWithMaxScoresByPeriod?.basicStatistics?.scoresByPeriod || []

  const isGameScore = useMemo(() => hasGameScore(events), [events])

  return (
    <StyledResultSetsWrapper>
      <StyledResultPeriodScoreWrapper isTitle>
        {isGameScore && (
          <StyledResultPeriodScore>
            <Typography color="text-secondary-2" font="Hint / 11 Medium">
              {t('game_score')}
            </Typography>
          </StyledResultPeriodScore>
        )}
      </StyledResultPeriodScoreWrapper>
      {!!periodCount.length && (
        <StyledResultPeriodScoreWrapper isTitle>
          {periodCount.map((period, i, array) => {
            const reverseIndex = array.length - i
            return (
              <StyledResultPeriodScore
                key={`${reverseIndex}-${period.homeScore}-${period.awayScore}`}
              >
                <Typography color="text-secondary-2" font="Hint / 11 Medium">
                  {`${reverseIndex}${titleSetsLetter}`}
                </Typography>
              </StyledResultPeriodScore>
            )
          })}
        </StyledResultPeriodScoreWrapper>
      )}
      <StyledResultTotalScore>
        <Typography color="text-secondary-2" font="Hint / 11 Medium">
          {t('score')}
        </Typography>
      </StyledResultTotalScore>
    </StyledResultSetsWrapper>
  )
}
