import React from 'react'

import { LayoutExternalFeatureProvider } from 'layouts/external-feature-provider'

import { LiveResults } from './LiveResults'

export const PageLiveResults = () => (
  <LayoutExternalFeatureProvider>
    <LiveResults />
  </LayoutExternalFeatureProvider>
)

export default PageLiveResults
