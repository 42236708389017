import React from 'react'

import { LayoutExternalFeatureProvider } from 'layouts/external-feature-provider'

import { Statistics } from './Statistics/Statistics'

export const PageStatistics = () => (
  <LayoutExternalFeatureProvider>
    <Statistics />
  </LayoutExternalFeatureProvider>
)

export default PageStatistics
