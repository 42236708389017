import React, { FC, useEffect } from 'react'
import {
  accountProviderActions,
  selectAccountId,
  selectAccountUser,
  selectFetchLiveGamesTokenRequest,
  selectFetchUserRequest,
  selectLiveGamesToken,
  selectTwainGamesToken
} from 'astra-core/containers/AccountProvider'
import { useTranslation } from 'react-i18next'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { RootState } from 'shared/types/store'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledTwainGames,
  StyledLoaderPointsWrapper
} from './TwainGames.styled'

export const TwainGames: FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectAccountUser)
  const accountId = useAppSelector(selectAccountId)
  const { loading: fetchUserLoading } = useAppSelector(selectFetchUserRequest)
  const { loading: fetchLiveGamesTokenLoading } = useAppSelector(
    selectFetchLiveGamesTokenRequest
  )
  const { token: liveGamesToken } = useAppSelector(selectLiveGamesToken) || {}

  useEffect(() => {
    if (accountId && !liveGamesToken) {
      dispatch(
        accountProviderActions.getTwainGamesToken({
          accountId
        })
      )
    }
  }, [accountId, dispatch, liveGamesToken])

  const loaded =
    fetchUserLoading === false &&
    (!user || fetchLiveGamesTokenLoading === false)

  return loaded ? (
    <LiveGamesContainer />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const TWAIN_GAMES_CONTAINER_ID = 'twain_sport_container'

const LiveGamesContainer = () => {
  const { token: twainGamesToken } = useAppSelector(selectTwainGamesToken) || {}
  const { i18n } = useTranslation()
  const config = useAppSelector(selectConfig)
  const isBalanceHidden = useAppSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  useEffect(() => {
    const script = document.createElement('script')

    script.src = `${
      config.TWAIN_GAMES_CLIENT_URL
    }/public/ts-loader.js?${Date.now()}`
    script.onload = () => {
      window.TwainSports.setup({
        containerElement: document.getElementById(TWAIN_GAMES_CONTAINER_ID),
        clientUrl: config.TWAIN_GAMES_CLIENT_URL,
        apiUrl: config.TWAIN_GAMES_API_URL,
        wsUrl: config.TWAIN_GAMES_WS_URL,
        partnerCode: config.TWAIN_GAMES_PARTNER_CODE,
        token: twainGamesToken ?? '-',
        locale: i18n.languages[0] || 'en',
        showBalance: isBalanceHidden ? 0 : 1,
        theme: 'dark',
        timezone: dayjs().format('Z')
      })
    }

    document.head.appendChild(script)
  }, [
    config.TWAIN_GAMES_API_URL,
    config.TWAIN_GAMES_CLIENT_URL,
    config.TWAIN_GAMES_PARTNER_CODE,
    config.TWAIN_GAMES_WS_URL,
    i18n.languages,
    isBalanceHidden,
    twainGamesToken
  ])

  return <StyledTwainGames id={TWAIN_GAMES_CONTAINER_ID} />
}
