import React from 'react'

import { DocumentsGeneralDisplay } from 'widgets/documents-general-display'

export const PagePolicyAml = () => (
  <DocumentsGeneralDisplay
    contentLocale="aml policy text"
    titleLocale="aml policy title"
  />
)

export default PagePolicyAml
