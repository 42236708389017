import React, { FC, useEffect } from 'react'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'

import { useAppDispatch } from 'shared/lib/@reduxjs'

import { StyledWrapper } from './bets-history-legacy.styled'
import { HistoryLegacy } from './components'

export const BetsHistoryLegacy: FC = () => {
  const dispatch = useAppDispatch()
  const isCashoutEnabled = useFeatureFlag(EFeatureFlags.CASHOUT_ENABLED)

  useEffect(() => {
    if (isCashoutEnabled) {
      dispatch(betsHistoryProviderActions.subscribeCashoutSSE())

      return () => {
        dispatch(betsHistoryProviderActions.unsubscribeCashoutSSE())
      }
    }
  }, [dispatch, isCashoutEnabled])

  return (
    <StyledWrapper>
      <HistoryLegacy />
    </StyledWrapper>
  )
}
