import styled, { css } from 'styled-components'

import { Input } from 'shared/ui/Input'

export const StyledSearchDivider = styled.div`
  ${({ theme: { tokens } }) => css`
    background-color: ${tokens.colors.divider['divider-secondary']};
    height: 16px;
    width: 1px;
  `}
`

export const StyledSearchInput = styled(Input)`
  width: 100%;
`

export const StyledHeaderWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    align-items: center;
    display: flex;
    gap: ${tokens.alias.space['space-xs-8']}px;
    width: 100%;
  `}
`
