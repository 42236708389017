/* eslint-disable better-styled-components/sort-declarations-alphabetically */
import styled, { css } from 'styled-components'

export const StyledResultsMenu = styled.div`
  ${({ theme }) => css`
    grid-area: sports-menu;
    overflow: hidden;
    height: 100%;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    box-shadow: rgba(108, 121, 147, 0.3) -1px 0px inset;
    display: grid;
  `}
`
