import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledSupportContent = styled.div`
  padding-top: 24px;
  ${({ theme }) => css`
    box-shadow: 0px -1px 0px 0px ${theme.tokens.colors.divider['divider-primary']};
  `}
`

export const StyledSupportTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledContentGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 16px;
`

export const StyleSupportCard = styled(Link)`
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 162px;
  justify-content: center;

  ${({ theme }) => css`
    outline: 1px solid ${theme.tokens.colors.border['border-primary']};
    outline-offset: -1px;

    &:hover {
      background-color: ${theme.tokens.colors.surface['surface-hover-light']};
    }

    &:active {
      background-color: ${theme.tokens.colors.surface['surface-hover']};
    }
  `}
`

export const StyleSupportCardIcon = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};

    & svg {
      color: ${theme.tokens.colors.icon['icon-primary']};
    }
  `}
`

export const StyleSupportCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`
