import styled from 'styled-components'

import { StyledGameCardImg } from 'features/game-card/game-card.styled'

export const StyledNardImg = styled(StyledGameCardImg)`
  @container game-card (width >= 98px) {
    right: -60px;
  }

  @container game-card (width >= 117px) {
    right: -52px;
  }

  @container game-card (width >= 135px) {
    right: -43px;
  }

  @container game-card (width >= 144px) {
    right: -38px;
  }

  @container game-card (width >= 147px) {
    right: -37px;
  }

  @container game-card (width >= 170px) {
    right: -25px;
  }

  @container game-card (width >= 266px) {
    right: 23px;
  }
`
