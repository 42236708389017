import React from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'
import { SUPPORT_CARDS } from 'widgets/support/lib'
import { ERoutes } from 'shared/types/routes'

import {
  StyleSupportCard,
  StyleSupportCardContent,
  StyleSupportCardIcon,
  StyledContentGrid,
  StyledSupportContent,
  StyledSupportTitle
} from './support-content.styled'

export const SupportContent = () => {
  const [t] = useTranslation()

  return (
    <StyledSupportContent>
      <StyledSupportTitle>
        <Typography color="text-primary" font="Title / 20 Bold">
          {t('support.faq.heading')}
        </Typography>
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {t('support.faq.subheading')}
        </Typography>
      </StyledSupportTitle>

      <StyledContentGrid>
        {SUPPORT_CARDS.map((item) => {
          const { categoryId, icon: Icon } = item

          return (
            <StyleSupportCard
              to={generatePath(ERoutes.CategoryFAQ, {
                categoryId
              })}
              key={categoryId}
            >
              <StyleSupportCardIcon>
                <Icon size={20} />
              </StyleSupportCardIcon>
              <StyleSupportCardContent>
                <Typography color="text-primary-2" font="Body / 16 Bold">
                  {t(item.name)}
                </Typography>
                <Typography color="text-secondary-3" font="Body / 14 Medium">
                  {t(item.text)}
                </Typography>
              </StyleSupportCardContent>
            </StyleSupportCard>
          )
        })}
      </StyledContentGrid>
    </StyledSupportContent>
  )
}
