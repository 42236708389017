import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'shared/ui/Button'

import { NothingFoundProps } from './nothing-found.types'
import * as S from './nothing-found.styled'

export const NothingFound: FC<NothingFoundProps> = ({
  onClickReset,
  className
}) => {
  const [t] = useTranslation()

  return (
    <S.NothingFoundContainer className={className}>
      <S.Title>{t('main_page_events.nothing_found.title')}</S.Title>

      <S.Description>
        {t('main_page_events.nothing_found.description')}
      </S.Description>

      {onClickReset && (
        <Button view="secondary-large" onClick={onClickReset}>
          {t('reset filter')}
        </Button>
      )}
    </S.NothingFoundContainer>
  )
}
