/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

import { range } from 'lodash'
import { useCallback, useState } from 'react'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { Trans, useTranslation } from 'react-i18next'

import { SegmentedControl } from 'shared/ui/segmented-control'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { InfoTabLocales } from '../../lib'

import {
  StyledCardBlock,
  StyledCardLinks,
  StyledCardSVG,
  StyledCardToggler,
  StyledCardWrapper
} from './Cards.styled'

export const Card1 = () => {
  const t = InfoTabLocales

  return (
    <StyledCardWrapper>
      <p>{t['card-1-p-1']}</p>
      <ol>
        {range(10).map((idx) => (
          <li key={idx}>{t[`card-1-li-${idx + 1}`]}</li>
        ))}
      </ol>
      <p>{t['card-1-p-2']}</p>
    </StyledCardWrapper>
  )
}

export const Card2 = () => {
  const t = InfoTabLocales

  return (
    <StyledCardWrapper>
      <p>{t['card-2-p-1']}</p>
      <StyledCardLinks>
        <a href="https://www.b17.ru/">b17.ru</a>
        <a href="http://www.gamblingtherapy.org/?ReferrerID=311s">
          Gambling Therapy Helpline
        </a>
      </StyledCardLinks>
    </StyledCardWrapper>
  )
}

export const Card3 = () => {
  const t = InfoTabLocales

  return (
    <StyledCardWrapper>
      <p>{t['card-3-p-1']}</p>
      <div />
      <StyledCardBlock>
        <h6>{t['card-3-h6-1']}</h6>
        <p>{t['card-3-p-2']}</p>
      </StyledCardBlock>
      <div />
      <StyledCardBlock>
        <h6>{t['card-3-h6-2']}</h6>
        <p>{t['card-3-p-3']}</p>
      </StyledCardBlock>
      <div />
      <StyledCardBlock>
        <h6>{t['card-3-h6-3']}</h6>
        <p>{t['card-3-p-4']}</p>
      </StyledCardBlock>
    </StyledCardWrapper>
  )
}

export const Card4 = () => {
  const t = InfoTabLocales
  const { i18n } = useTranslation()
  const [activeTab, setActiveTab] = useState<'web' | 'mob'>('web')
  const themeType = useAppSelector(selectUserSettingLayoutTheme)

  const handleSportTypeClick = useCallback((item) => {
    setActiveTab(item)
  }, [])

  return (
    <StyledCardWrapper>
      <StyledCardToggler>
        <SegmentedControl
          items={[
            { label: t['card-4-toggle-1'], value: 'web' },
            { label: t['card-4-toggle-2'], value: 'mob' }
          ]}
          value={activeTab}
          view="secondary"
          equalItems
          onChange={handleSportTypeClick}
        />
        <StyledCardSVG
          src={`/images/responsible-gaming/${i18n.language}/${activeTab}.${themeType}.svg`}
        />
      </StyledCardToggler>
    </StyledCardWrapper>
  )
}

export const Card5 = () => {
  const t = (key) => InfoTabLocales[key]

  return (
    <StyledCardWrapper>
      <p>
        <Trans
          components={[
            <a href="https://online.zakon.kz/document/?doc_id=31577399#sub_id=4440200" />
          ]}
          i18nKey="card-5-p-1"
          t={t}
        />
      </p>
    </StyledCardWrapper>
  )
}

export const Card6 = () => {
  const t = InfoTabLocales

  return (
    <StyledCardWrapper>
      <p>{t['card-6-p-1']}</p>
      <StyledCardLinks>
        <a href="http://www.gamblock.com/index.html">GamBlock</a>
        <a href="http://www.cyberpatrol.com/">Cyber Patrol</a>
        <a href="http://www.netnanny.com/">Net Nanny</a>
        <a href="http://www.gamban.com/">Gamban</a>
        <a href="https://www.cybersitter.com/">Cybersitter</a>
        <a href="http://www1.k9webprotection.com/">K9 Web Protection</a>
      </StyledCardLinks>
    </StyledCardWrapper>
  )
}

export const Card7 = () => {
  const t = InfoTabLocales

  return (
    <StyledCardWrapper>
      <ul>
        {range(11).map((idx) => (
          <li key={idx}>
            <span>{t[`card-7-li-${idx + 1}`]}</span>
          </li>
        ))}
      </ul>
    </StyledCardWrapper>
  )
}
