import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { EPasswordConditional } from 'shared/ui/password-conditional/password-conditional.types'

export const StyledPasswordConditional = styled.div<{
  type: EPasswordConditional
  isPasswordStrength: boolean | undefined
}>`
  ${typography['Body / 12 SemiBold']};
  display: flex;
  ${({ theme, type, isPasswordStrength }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;

    ${isPasswordStrength
      ? css`
          & svg {
            color: ${theme.tokens.colors.icon['icon-secondary-2']};
          }
        `
      : css`
          ${type === EPasswordConditional.DEFAULT &&
          css`
            color: ${theme.tokens.colors.icon['icon-secondary-2']};
          `}
        `}

    ${type === EPasswordConditional.CORRECT &&
    css`
      color: ${theme.tokens.colors.text['text-green']};
      ${isPasswordStrength &&
      css`
        & svg {
          color: ${theme.tokens.colors.text['text-green']};
        }
      `}
    `}
    
    ${type === EPasswordConditional.INCORRECT &&
    css`
      ${isPasswordStrength
        ? css`
            & span {
              color: ${theme.tokens.colors.text['text-error']};
            }
          `
        : css`
            color: ${theme.tokens.colors.text['text-error']};
          `}
    `}
  `}
`

export const StyledPasswordStrength = styled.span<{
  isWeak?: boolean
}>`
  ${({ theme, isWeak }) =>
    css`
      ${isWeak &&
      css`
        color: ${theme.tokens.colors.text['text-error']};
      `}
    `}
`

export const StyledPasswordStrengthWrapper = styled.div`
  font-weight: 500;
`
