import React from 'react'
import styled from 'styled-components'

import { IconStar } from 'shared/ui/Icon/General/IconStar'
import { EColorsNames, EColorsTypes } from 'shared/lib/theme'

export const StyledCalendarEvents = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
`

const StyledIconCalendar = styled(IconStar)`
  background: ${(props) => props.theme.colors.custom.yellow[2]};
  border-radius: 4px;
  padding: 5px;
`

export const TitleLinePageIcon = (
  <StyledIconCalendar
    colorProps={{
      name: EColorsNames.Yellow,
      type: EColorsTypes.FIXED,
      value: 50
    }}
    size={24}
  />
)
