import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { TestCoin } from './test-coin'

export const PageTestCoin = () => (
  <LayoutProfile pageTitle={{ text: 'notifications' }} isHeaderFilled>
    <TestCoin />
  </LayoutProfile>
)

export default PageTestCoin
