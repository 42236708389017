import styled from 'styled-components'

import { ColumnLayout, ColumnLayoutContent } from 'shared/lib/layout'

export const Error = styled(ColumnLayout)`
  grid-template-columns: 1fr;
`

export const Content = styled(ColumnLayoutContent)`
  background-color: transparent;
  width: 101%;
`
