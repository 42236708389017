import { getCurrentLng } from 'astra-core'

import { i18nLocalToLegacyLang } from 'shared/lib/locale'

import { baseLegacyApi } from '../api'

import {
  IGetCashoutRequest,
  IGetOddinGamesRequest,
  IGetSportGamesResponse,
  TGetOddinGamesResponse
} from './sport-games.types'

export const sportGamesApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getSportGames: builder.query<IGetSportGamesResponse, IGetCashoutRequest>({
      query: (args) => ({
        method: 'POST',
        url: 'games/sportgamestv/lobby',
        body: {
          ...args,
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    }),
    getOddinGames: builder.query<TGetOddinGamesResponse, IGetOddinGamesRequest>(
      {
        query: (args) => ({
          method: 'POST',
          url: 'games/oddin/getIframeUrl',
          body: {
            ...args,
            lang_id: i18nLocalToLegacyLang(getCurrentLng())
          }
        }),
        transformErrorResponse: async (error) => {
          console.log('transformErrorResponse error', error)
        }
      }
    )
  })
})

export const { useGetSportGamesQuery, useGetOddinGamesQuery } = sportGamesApi
