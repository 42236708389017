import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledSupportHeader = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
`

export const StyledSupportCard = styled.div`
  ${({ theme }) => css`
    appearance: none;
    background: ${theme.tokens.colors.surface['surface-default']};
    border: none;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-l-12'
    ]}px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 16px;
    transition: all 0.3s;

    &:hover {
      background: ${theme.tokens.colors.surface['surface-hover']};
    }

    &:active {
      background: ${theme.tokens.colors.surface['surface-active-4']};
    }
  `}
`

export const StyledSupportLinkDescr = styled.div`
  ${typography['Hint / 11 Medium']}
  align-items: center;
  display: flex;
  gap: 4px;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};

    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-2']};
    }
  `}
`
