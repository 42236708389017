import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSupportBanner: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="311"
      viewBox="0 0 380 311"
      width="380"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M337.877 75.0534C314.013 64.7037 290.059 54.085 266.413 43.3322C242.173 32.283 218.241 21.0997 194.211 9.83567C201.246 21.0822 208.914 31.684 216.785 42.2592C225.643 53.4539 234.72 64.2454 244.097 75.0911C259.201 74.3269 274.381 74.2081 289.356 74.116C305.643 74.1329 321.711 74.5527 337.877 75.0534Z"
        fill="white"
      />
      <path
        d="M410.866 157.03C387.348 148.133 363.936 139.128 340.531 129.935C316.328 120.472 292.336 110.795 268.555 100.902C279.628 112.368 291.122 123.404 302.722 134.332C314.812 145.665 327.21 156.863 339.714 167.954C352.679 165.037 359.406 163.78 373.659 161.536C387.912 159.292 395.332 158.412 410.866 157.03Z"
        fill="white"
      />
      <path
        d="M475.407 179.737C497.546 187.692 519.686 195.646 541.832 203.413L541.842 203.416L541.849 203.419C563.199 210.726 584.55 218.033 606.005 225.232C590.977 226.642 583.76 227.495 570.012 229.766C556.166 231.956 549.642 233.186 537.076 236.239C526.781 227.184 516.676 218.104 506.49 208.951L505.432 208C495.285 198.66 485.342 189.292 475.407 179.737Z"
        fill="white"
      />
      <path
        d="M802.941 272.54C803.859 273.543 804.837 274.609 805.9 275.773C819.385 275.034 833.057 274.645 846.421 274.39C860.998 274.163 875.552 274.501 890 274.946C873.446 270.362 856.904 265.486 840.431 260.631C838.185 259.969 835.941 259.307 833.699 258.647C814.211 252.685 794.528 246.561 775.048 240.41C780.735 247.434 783.83 250.96 790.022 258.011C795.111 263.997 798.252 267.424 802.941 272.54Z"
        fill="white"
      />
      <path
        d="M639.555 223.707C639.063 223.206 638.584 222.719 638.118 222.244L638.116 222.242C630.74 214.738 626.376 210.299 618.973 202.095C610.537 192.998 606.673 188.45 599.14 179.515C620.677 187.362 642.213 195.208 663.961 202.84C684.812 210.122 705.768 217.295 726.83 224.362C724.745 224.307 722.661 224.25 720.58 224.193C707.831 223.843 695.137 223.495 682.347 223.644C668.486 223.683 654.61 224.099 640.719 224.891C640.323 224.488 639.935 224.093 639.555 223.707Z"
        fill="white"
      />
      <path
        d="M427.63 130.025C435.847 139.525 444.48 148.784 453.323 157.827C467.719 157.062 482.099 156.674 496.457 156.85C509.523 156.795 522.429 157.162 535.373 157.53C537.765 157.598 540.158 157.666 542.553 157.731C519.842 148.915 497.129 140.099 474.531 130.988C451.337 121.58 428.046 112.091 405.063 102.468C412.271 111.913 419.788 121.225 427.63 130.025Z"
        fill="white"
      />
      <path
        d="M675.303 254.498C697.442 262.453 719.581 270.408 741.728 278.174L741.737 278.178L741.745 278.18C763.095 285.487 784.445 292.794 805.9 299.993C790.872 301.403 783.655 302.256 769.907 304.527C756.061 306.718 749.537 307.948 736.971 311C726.677 301.945 716.572 292.866 706.385 283.712L705.327 282.762C695.181 273.421 685.238 264.054 675.303 254.498Z"
        fill="white"
      />
      <path
        d="M211.11 80.7854C187.593 71.8881 164.181 62.8832 140.776 53.6902C116.573 44.2274 92.5811 34.5498 68.7999 24.6573C79.8726 36.1231 91.3671 47.1591 102.967 58.0877C115.057 69.4201 127.455 80.6184 139.959 91.7092C152.924 88.7918 159.651 87.535 173.904 85.2911C188.157 83.0472 195.577 82.1676 211.11 80.7854Z"
        fill="white"
      />
      <path
        d="M143.666 -13.7823C119.802 -24.132 95.8474 -34.7507 72.2019 -45.5035C47.9613 -56.5527 24.0297 -67.7359 5.34252e-05 -79C7.03501 -67.7535 14.7027 -57.1517 22.5739 -46.5765C31.4318 -35.3818 40.5083 -24.5903 49.8861 -13.7446C64.99 -14.5088 80.1692 -14.6276 95.1451 -14.7197C111.432 -14.7029 127.5 -14.283 143.666 -13.7823Z"
        fill="white"
      />
    </svg>
  </Icon>
)
