import React from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { FAQDetailsParams } from 'entities/faq'
import { useFAQTranslation } from 'entities/faq/lib/utils'
import { LayoutProfile } from 'layouts/profile'

import { FAQDetailsPage } from './FAQDetailsPage'

export const PageFAQDetails = () => {
  const { faqId, categoryId } = useParams<FAQDetailsParams>()
  const translation = useFAQTranslation(faqId)

  return (
    <LayoutProfile
      buttonBack={{
        to: generatePath(ERoutes.CategoryFAQ, {
          categoryId
        })
      }}
      pageTitle={{ text: translation?.title }}
      isHeaderFilled
    >
      <FAQDetailsPage />
    </LayoutProfile>
  )
}

export default PageFAQDetails
