import styled, { css } from 'styled-components'

export const StyledDocumentFrame = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-m-16']}px 0;
  `}
`
