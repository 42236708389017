import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'

import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/Button'
import { ERoutes } from 'shared/types/routes'
import { useRouteRedirect } from 'hooks/useRouteRedirect'
import { IconFAQ } from 'shared/ui/Icon/General/IconFAQ'
import { Tooltip } from 'shared/ui/Tooltip'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'

import { IBonusCardProps } from './bonus-card.types'
import {
  BonusesFAQButton,
  StyledBonusCard,
  StyledBonusCardContent,
  StyledBonusCardDesc,
  StyledBonusCardImageWrapper,
  StyledDashes,
  StyledPromoCardImage,
  StyledTooltipWrapper
} from './bonus-card.styled'

// const LateralTrapezoidForBonusType: FC<ILateralTrapezoidSVG> = ({
//   fillColor,
//   rotateDeg
// }) => {
//   return (
//     <StyledLateralTrapezoidForBonusType
//       fill="none"
//       fillColor={fillColor}
//       height="20"
//       rotateDeg={rotateDeg}
//       viewBox="0 0 6 20"
//       width="6"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M4.41421 0H5V20H4.41421C4.149 20 3.89464 19.8946 3.70711 19.7071L0.292893 16.2929C0.105357 16.1054 0 15.851 0 15.5858V4.41421C0 4.149 0.105357 3.89464 0.292893 3.70711L3.70711 0.292893C3.89464 0.105357 4.149 0 4.41421 0Z"
//         fill="none"
//       />
//       <rect fill="none" height="20" width="1" x="5" y="0" />
//     </StyledLateralTrapezoidForBonusType>
//   )
// }

export const BonusCard: FC<IBonusCardProps> = ({
  bonusCoupon,
  isLastInRow
}) => {
  const [t] = useTranslation()
  const routeRedirect = useRouteRedirect()
  const currencyIcon = useGetCurrencyIcon()

  const { amount, description } = bonusCoupon?.coupon
  const bonusExpiredAt = bonusCoupon?.expiredAt
  const bonusAmount = `${formatMonetaryAmount(amount)} ${currencyIcon}`

  const handleRedirectList = (route: ERoutes) => () => {
    routeRedirect({ route })
  }

  return (
    <StyledBonusCard>
      <StyledBonusCardImageWrapper>
        <StyledPromoCardImage />

        <StyledDashes />

        <StyledTooltipWrapper>
          <Tooltip
            placement={isLastInRow ? 'right' : 'center'}
            title={description}
            isClick
          >
            <BonusesFAQButton icon={IconFAQ} view="outline-l" />
          </Tooltip>
        </StyledTooltipWrapper>

        {/* <StyledBonusTypeWrapper>
          <LateralTrapezoidForBonusType />

          <StyledBonusType>
            <Typography color="text-alwaysWhite" font="Body / 12 Bold">
              {t('bonus coupon')}
            </Typography>
          </StyledBonusType>

          <LateralTrapezoidForBonusType rotateDeg={180} />
        </StyledBonusTypeWrapper> */}
      </StyledBonusCardImageWrapper>
      <StyledBonusCardContent>
        <StyledBonusCardDesc>
          <Typography color="text-primary" font="Title / 24 Bold">
            {bonusAmount}
          </Typography>

          <Typography color="text-secondary-2" font="Hint / 11 Medium">
            {t('bonus will expire', {
              dateValue: dayjs(bonusExpiredAt).format('YYYY-MM-DD | HH:mm')
            })}
          </Typography>
        </StyledBonusCardDesc>

        <Button onClick={handleRedirectList(ERoutes.LiveRedirect)}>
          {t('select rate')}
        </Button>
      </StyledBonusCardContent>
    </StyledBonusCard>
  )
}
