import React from 'react'

import { LayoutDocument } from 'layouts/document'

import { AboutPage } from './AboutPage'

export const PageAbout = () => (
  <LayoutDocument pageTitle={{ text: 'olimpbet bookmaker' }}>
    <AboutPage />
  </LayoutDocument>
)

export default PageAbout
