import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { Promotions } from './promotions'

export const PagePromotions = () => (
  <LayoutProfile
    pageTitle={{ text: 'promotions', titleSurfaceColor: 'surface-base' }}
  >
    <Promotions />
  </LayoutProfile>
)

export default PagePromotions
