import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { NotificationsLegacy } from 'widgets/notifications'

import { NOTIFICATION_TYPE_MAP } from '../../notifications.constants'

export const NotificationsPageContainer: FC = () => {
  const { pathname } = useLocation()

  const type = NOTIFICATION_TYPE_MAP[pathname] || 'all'

  return <NotificationsLegacy type={type} />
}
