import { notificationsApi } from 'entities/legacy-api'
import { INavMenuItemCounter } from 'features/profile-nav-menu/profile-nav-menu.type'

const { useGetNotificationsCountQuery } = notificationsApi

export const NotificationCounter: INavMenuItemCounter = ({ children }) => {
  const { data } = useGetNotificationsCountQuery()

  const count = data?.data?.notread || 0

  return children ? children(count) : null
}
