import React, { memo } from 'react'

import { ProfileContent } from 'features/profile-content'

import { StyledProfileContent } from './Profile.styled'

export const Profile = memo(() => {
  return (
    <StyledProfileContent>
      <ProfileContent />
    </StyledProfileContent>
  )
})
