import {
  IdentificationConstraints,
  getConstraint
} from 'entities/constration/model'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { BlockBetsScreen } from '../blockBets-screen'

import { StyledLimitWrapper } from './LimitTab.styled'
import { LimitContent } from './ui'

export const LimitTab = () => {
  const constraint = useAppSelector((state) =>
    getConstraint(state, IdentificationConstraints.BETS)
  )

  return (
    <StyledLimitWrapper>
      {constraint ? <BlockBetsScreen /> : <LimitContent />}
    </StyledLimitWrapper>
  )
}
