import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledBalanceControlsWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    background-color: ${tokens.colors.surface['surface-default']};
    border-radius: ${tokens.alias['border-radius']['border-radius-m-8']}px;
    display: grid;
    padding: ${tokens.alias.space['space-s-12']}px;
    padding-top: ${tokens.alias.space['space-xs-8']}px;
    row-gap: ${tokens.alias.space['space-xs-8']}px;
  `}
`

export const BalanceValueContainer = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: space-between;
`

export const BalanceButtonContainer = styled.div`
  ${({ theme }) => css`
    column-gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}
`

export const StyledBalanceTitle = styled.div`
  ${typography['Hint / 10 EXTRABOLD']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
  `}
`
