import React, { FC } from 'react'

import { GlobalSearch } from 'widgets/global-search'
import { LayoutEventsTable } from 'layouts/events-table'

export const PageSearch: FC = () => (
  <LayoutEventsTable>
    <GlobalSearch />
  </LayoutEventsTable>
)

export default PageSearch
