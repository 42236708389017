import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import { Typography } from 'shared/ui/typography'

import { useCountdown } from '../../lib'

import { StyledTextButton } from './TimeLeft.styled'

export const TimeLeft: FC<{ sendCode: () => void; SMSts: string }> = ({
  sendCode,
  SMSts
}) => {
  const [t] = useTranslation()

  const timeLeft = useCountdown(SMSts || '')

  return (
    <>
      {timeLeft === '00:00' ? (
        <StyledTextButton onClick={sendCode}>
          {t('receive a new code')}
        </StyledTextButton>
      ) : (
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {t('you can receive a new code after', {
            time: timeLeft
          })}
        </Typography>
      )}
    </>
  )
}
