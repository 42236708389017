import { LayoutTheme } from 'betweb-openapi-axios'
import styled, { css } from 'styled-components'

import { HeaderWrapper } from 'features/events-table/ui/table-header/event-table-header.styled'
import { MAIN_HEADER_HEIGHT } from 'shared/lib/layout'

export const EventsGroupWrapper = styled.div<{ isIframe: boolean }>`
  ${({ theme, isIframe }) => css`
    border-radius: 8px;
    contain: paint;
    position: relative;

    &:after {
      content: '';
      border-radius: 8px;
      outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
      outline-offset: -1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
    }

    ${HeaderWrapper} {
      top: ${isIframe ? 8 : MAIN_HEADER_HEIGHT + 8}px;
    }
  `}
`

export const StyledTR = styled.tr`
  height: 28px;
  position: absolute;
  right: 0;
  top: 14px;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-2']};
  `}
`

export const EventsTableGroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 8px 32px;
`

export const StyledHeaderWrapper = styled.div<{ isIframe: boolean }>`
  height: 8px;
  margin-top: -8px;
  position: sticky;
  width: 100%;
  z-index: 2;

  ${({ theme, isIframe }) => css`
    top: ${isIframe ? 0 : MAIN_HEADER_HEIGHT}px;

    &::before {
      border-left: 1px solid ${theme.tokens.colors.divider['divider-primary']};
      border-right: 1px solid ${theme.tokens.colors.divider['divider-primary']};
      border-top: 1px solid ${theme.tokens.colors.divider['divider-primary']};
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      bottom: -8px;
      box-shadow: 0px -8px 0px 8px ${theme.tokens.colors.surface['surface-page']};
      content: '';
      height: 8px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }

    ${!isIframe &&
    theme.typeLayoutTheme === LayoutTheme.Dark &&
    css`
      &::before {
        box-shadow: none;
      }
    `}
  `}
`
