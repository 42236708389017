import styled from 'styled-components'

import { StyledGameCardImg } from 'features/game-card/game-card.styled'

export const StyledDartsImg = styled(StyledGameCardImg)`
  @container game-card (width >= 98px) {
    right: -35px;
  }

  @container game-card (width >= 117px) {
    right: -27px;
  }

  @container game-card (width >= 135px) {
    right: -17px;
  }

  @container game-card (width >= 144px) {
    right: -12px;
  }

  @container game-card (width >= 147px) {
    right: -11px;
  }

  @container game-card (width >= 170px) {
    right: 0;
  }

  @container game-card (width >= 266px) {
    right: 49px;
  }
`
