import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'shared/ui/typography'

import {
  StyledAboutPageArticle,
  StyledAboutPageWrapper
} from './AboutPage.styled'

export const AboutPage: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledAboutPageWrapper>
      <StyledAboutPageArticle>
        <Typography
          style={{
            whiteSpace: 'pre-wrap'
          }}
          color="text-secondary"
          dangerouslySetInnerHTML={{ __html: t('about us first article') }}
          font="Body / 14 Medium"
        />
      </StyledAboutPageArticle>
      <StyledAboutPageArticle>
        <Typography font="Title / 18 Bold">{t('license')}</Typography>
        <Typography
          style={{
            whiteSpace: 'pre-wrap'
          }}
          color="text-secondary"
          dangerouslySetInnerHTML={{ __html: t('about us second article') }}
          font="Body / 14 Medium"
        />
      </StyledAboutPageArticle>
      <StyledAboutPageArticle>
        <Typography font="Title / 18 Bold">
          {t('contact information')}
        </Typography>
        <Typography
          style={{
            whiteSpace: 'pre-wrap'
          }}
          color="text-secondary"
          dangerouslySetInnerHTML={{ __html: t('about us third article') }}
          font="Body / 14 Medium"
        />
      </StyledAboutPageArticle>
    </StyledAboutPageWrapper>
  )
}
