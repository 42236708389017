import { FC, PropsWithChildren, useState } from 'react'

import { IconPlusOutline16 } from 'shared/ui/Icon/General/IconPlusOutline16'
import { IconMinusOutline16 } from 'shared/ui/Icon/General/IconMinusOutline16'

import {
  StyledAccordionContent,
  StyledAccordionTitle,
  StyledAccordionWrapper
} from './Accordion.styled'

export const Accordion: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children
}) => {
  const [active, setActive] = useState(false)

  return (
    <StyledAccordionWrapper>
      <StyledAccordionTitle onClick={() => setActive((prev) => !prev)}>
        {title}
        {active ? <IconMinusOutline16 /> : <IconPlusOutline16 />}
      </StyledAccordionTitle>
      {active && <StyledAccordionContent>{children}</StyledAccordionContent>}
    </StyledAccordionWrapper>
  )
}
