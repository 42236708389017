import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledFAQContainer = styled.main`
  padding: 8px 16px;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
  `}
`

export const StyledFAQList = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-width: 720px;
  overflow: hidden;
  width: 100%;
`

export const StyledFAQListItem = styled(Link)`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 8px 12px;
  transition: all 0.3s;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};

    &:hover {
      background: ${theme.tokens.colors.surface['surface-hover']};
    }

    & svg {
      color: ${theme.tokens.colors.icon['icon-secondary-2']};
    }
  `}
`
