import styled from 'styled-components'

export const StyledCap = styled.div`
  align-items: flex-start;
  bottom: -8px;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: -1;
`

export const StyledGradientRect = styled.div`
  background: linear-gradient(90deg, #222832 0%, #1d232b 100%);
  height: 9px;
  width: 100%;
`
