import {
  getMessagesPageFetchItems,
  getMessagesPageHasMore,
  getMessagesPagePagination,
  messagesProviderActions
} from 'astra-core/containers/MessagesProvider'

import { ERoutes } from 'shared/types/routes'
import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS: DataListWithReduxPaginationProps['pagination'] =
  {
    setPaginationAction: messagesProviderActions.setPaginationPage,
    selectorHasMore: getMessagesPageHasMore,
    selectorPagination: getMessagesPagePagination,
    selectorRequestStatus: getMessagesPageFetchItems
  }

export const NOTIFICATIONS_LINKS = [
  {
    to: ERoutes.NewNotifications,
    titleKey: 'notifications.new'
  },
  {
    to: ERoutes.ReadNotifications,
    titleKey: 'notifications.read'
  }
]

export const NOTIFICATION_TYPE_MAP: Partial<Record<ERoutes, string>> = {
  [ERoutes.NewNotifications]: 'notread',
  [ERoutes.ReadNotifications]: 'readed'
}
