import styled from 'styled-components'

export const Layout = styled.div`
  display: grid;
  grid-template-areas:
    '. content'
    'sports-menu content';
  grid-template-columns: 240px 1fr;
  grid-template-rows: 48px 1fr;
  height: 100%;
  position: relative;
`
