import React from 'react'

import { LayoutError } from 'layouts/error'

import { OfflinePage } from './offline-page'

export const PageOffline = () => (
  <LayoutError>
    <OfflinePage />
  </LayoutError>
)

export default PageOffline
