import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconPanel12: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 13 12"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M0.990802 9.84244C0.781278 9.6538 0.666992 9.39657 0.666992 9.12219V2.87781C0.666992 2.612 0.781278 2.3462 0.990802 2.15756L1.93366 1.30011C2.14318 1.11147 2.4289 1 2.72413 1H10.6098C10.9051 1 11.1908 1.11147 11.4003 1.30011L12.3432 2.15756C12.5527 2.3462 12.667 2.60343 12.667 2.87781V9.12219C12.667 9.388 12.5527 9.6538 12.3432 9.84244L11.4003 10.6999C11.1908 10.8885 10.9051 11 10.6098 11H2.72413C2.4289 11 2.14318 10.8885 1.93366 10.6999L0.990802 9.84244ZM9.28402 6.32503C9.52402 6.18069 9.52402 5.81985 9.28402 5.67551L5.62808 3.47681C5.38808 3.33247 5.08808 3.51289 5.08808 3.80157V8.19898C5.08808 8.48765 5.38808 8.66807 5.62808 8.52374L9.28402 6.32503Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
