import { useMemo } from 'react'

import { responsibleGamblingApi } from 'entities/legacy-api/lib/responsible-gambling'

const { useGetPeriodsQuery, useGetStatusQuery } = responsibleGamblingApi

export const useGetLimitData = () => {
  const { data: dataPeriods, isLoading: periodsLoading } = useGetPeriodsQuery(
    {}
  )
  const { data: dataStatus, isLoading: statusLoading } = useGetStatusQuery({})

  const limit = useMemo(
    () => (dataPeriods ? dataPeriods?.data.limit : []),
    [dataPeriods]
  )

  const limitOptions = useMemo(
    () =>
      limit.map((item) => {
        return {
          value: item.key,
          label: item.period
        }
      }),
    [limit]
  )

  const limitStatus = useMemo(() => dataStatus?.data?.limit, [dataStatus])

  const periodLimitLabel = useMemo(
    () =>
      limitOptions.find((item) => item.value === limitStatus?.sum_period)
        ?.label,
    [limitOptions, limitStatus]
  )

  return {
    periodLimitLabel,
    limitStatus,
    limitOptions,
    statusLoading,
    periodsLoading
  }
}
