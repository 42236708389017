import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const BalanceReportWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const BalanceReportBlock = styled.div`
  display: flex;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-default']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xxxl-40']}px;
    padding: ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledItemWrapper = styled.div`
  display: grid;
  flex: 1;
  grid-auto-rows: min-content;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxxs-2']}px;
  `}
`

export const StyledItemTitle = styled.h3`
  ${typography['Hint / 10 BOLD']}
  ${({ theme }) =>
    css`
      color: ${theme.tokens.colors.text['text-secondary-2']};
    `}
`

export const StyledItemBottomWrapper = styled.div`
  align-items: flex-end;
  color: ${(props) => props.theme.colors.default.primary[30]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
`

export const StyledItemValue = styled.div`
  ${typography['Body / 16 Bold']}
  display: flex;
  ${({ theme }) =>
    css`
      color: ${theme.tokens.colors.text['text-primary']};
      gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    `}
`
export const Divider = styled.div`
  width: 1px;
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.divider['divider-primary']};
  `}
`
