import {
  selectFilterIsLiveResults,
  selectResultsBySportIdAndByTournamentId
} from 'astra-core/containers/ResultsProvider'
import { createSelector } from 'reselect'

import { filterChampionshipsByEndedStatus } from './constants'

export const selectFilteredChampionships = createSelector(
  [selectFilterIsLiveResults, selectResultsBySportIdAndByTournamentId],
  (isLiveResults, results) => {
    if (isLiveResults) {
      return results
    }
    return filterChampionshipsByEndedStatus(results)
  }
)

export const selectFilteredChampionshipsLength = createSelector(
  [selectFilteredChampionships],
  (results) => results.length
)
