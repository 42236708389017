import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconCoef: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_1134)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM16 16.25L14 14.25H18L16 16.25ZM14 10L16 8L18 10H14Z"
          fill="currentColor"
        />
        <path d="M7 7.60938H12V16.6094H10V9.60938H8L7 7.60938Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_16_1134">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
)
