import { useState } from 'react'

import { ShowPassword } from './show-password'

export const useShowPassword = () => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClick = () => setShowPassword((prev) => !prev)

  const Component = <ShowPassword value={showPassword} onClick={handleClick} />
  return {
    showPassword,
    setShowPassword,
    ShowPasswordComponent: Component
  }
}
