import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { Notifications } from './notifications'

export const PageNotification = () => (
  <LayoutProfile pageTitle={{ text: 'notifications' }} isHeaderFilled>
    <Notifications />
  </LayoutProfile>
)

export default PageNotification
