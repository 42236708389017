export enum EServicePage {
  NotFound = 'not-found',
  UnderDevelopment = 'under-development',
  Offline = 'offline'
}

export type ServicePageToken = {
  title: string
  desc: string
}

export const SERVICE_PAGE_MAP: Record<EServicePage, ServicePageToken> = {
  [EServicePage.NotFound]: {
    title: 'error 404',
    desc: 'sorry, page was not found'
  },
  [EServicePage.UnderDevelopment]: {
    title: 'this section is under construction',
    desc: 'everything will work very soon'
  },
  [EServicePage.Offline]: { title: 'no internet', desc: 'check your internet' }
}
