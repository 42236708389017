import React from 'react'

import { useIsSmall, useIsTabletOrMobile } from 'shared/lib/styled'
import { BasketButtonOpenBasketForSmallWindow } from 'features/basket'

import { ResultTabs } from '../result-tabs'
import { ResultCalendar } from '../result-calendar'
import { ResultSearchInput } from '../result-search-input'

import {
  StyledHeaderWrapper,
  StyledSearchDivider
} from './result-header.styled'

export const ResultHeader = () => {
  const isTabletOrMobile = useIsTabletOrMobile()
  const isSmall = useIsSmall()

  return (
    <StyledHeaderWrapper>
      <ResultSearchInput />

      <StyledSearchDivider />

      <ResultTabs />

      <ResultCalendar />

      {(isTabletOrMobile || isSmall) && (
        <BasketButtonOpenBasketForSmallWindow isSmall />
      )}
    </StyledHeaderWrapper>
  )
}
