import { useTranslation } from 'react-i18next'

import { IconNotification } from 'shared/ui/Icon/General/icon-notification'

import {
  EmptyContent,
  EmptyDescription,
  EmptyText,
  EmptyTitle,
  EmptyWrapper
} from './empty.styled'

export const NotificationsEmpty = () => {
  const { t } = useTranslation()

  return (
    <EmptyWrapper>
      <EmptyContent>
        <IconNotification colorToken="icon-secondary-3" size={40} />
        <EmptyText>
          <EmptyTitle font="Body / 16 SemiBold">
            {t('notifications.empty.title')}
          </EmptyTitle>
          <EmptyDescription font="Body / 14 Medium">
            {t('notifications.empty.description')}
          </EmptyDescription>
        </EmptyText>
      </EmptyContent>
    </EmptyWrapper>
  )
}
