import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { AvailablePanel } from 'shared/ui/AvailablePanel'
import { LayoutGame } from 'layouts/game'

import { TwainGames } from './TwainGames'

export const PageGamesTwain = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.TwainSport)

  return (
    <LayoutGame>{isAvailable ? <TwainGames /> : <AvailablePanel />}</LayoutGame>
  )
}

export default PageGamesTwain
