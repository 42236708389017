import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useGetCurrencyIcon } from 'astra-core'

import { Typography } from 'shared/ui/typography'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { IconWarningTriangle } from 'shared/ui/Icon/General/IconWarningTriangle'

import { useGetBreakData, useGetLimitData } from '../lib'

import {
  StyledBreakInfo,
  StyledBreakInfoItem,
  StyledLimitInfo,
  StyledLimitInfoItem,
  StyledLimitInfoWrapper,
  StyledModalIconWrapper,
  StyledModalWarning
} from './RestrictionInfo.styled'

export const RestrictionInfo = () => {
  const [t] = useTranslation()
  const { periodLimitLabel, limitStatus } = useGetLimitData()
  const { periodBlockLabel, blockStatus } = useGetBreakData()

  return (
    <StyledLimitInfoWrapper>
      {periodLimitLabel && (
        <LimitInfo
          limitStatus={limitStatus}
          periodLimitLabel={periodLimitLabel}
        />
      )}
      {periodBlockLabel && (
        <BreakInfo
          blockStatus={blockStatus}
          periodBlockLabel={periodBlockLabel}
        />
      )}

      <StyledModalWarning>
        <StyledModalIconWrapper>
          <IconWarningTriangle colorToken="icon-warning" />
        </StyledModalIconWrapper>

        <Typography color="text-primary-2" font="Body / 14 Medium">
          {t('breal limit warning')}
        </Typography>
      </StyledModalWarning>
    </StyledLimitInfoWrapper>
  )
}

const LimitInfo = ({ limitStatus, periodLimitLabel }) => {
  const [t] = useTranslation()
  const currencyIcon = useGetCurrencyIcon()

  return (
    <StyledLimitInfo>
      <StyledLimitInfoItem>
        <Typography color="text-secondary-2" font="Hint / 10 BOLD">
          {t('current limit')}
        </Typography>
        <Typography color="text-primary" font="Body / 16 Bold">
          {`${formatMonetaryAmount(
            limitStatus.sum_limit
          )} ${currencyIcon} на ${periodLimitLabel}`}
        </Typography>
      </StyledLimitInfoItem>

      <StyledLimitInfoItem>
        <Typography color="text-secondary-2" font="Hint / 10 BOLD">
          {t('expires')}
        </Typography>
        <Typography color="text-primary" font="Body / 16 Bold">
          {dayjs(limitStatus.expire_date).format('DD.MM.YYYY HH:mm')}
        </Typography>
      </StyledLimitInfoItem>
    </StyledLimitInfo>
  )
}

const BreakInfo = ({ periodBlockLabel, blockStatus }) => {
  const [t] = useTranslation()

  return (
    <StyledBreakInfo>
      <StyledBreakInfoItem>
        <Typography color="text-secondary-2" font="Hint / 10 BOLD">
          {t('break period')}
        </Typography>
        <Typography color="text-primary" font="Body / 16 Bold">
          {periodBlockLabel || '-'}
        </Typography>
      </StyledBreakInfoItem>

      <StyledBreakInfoItem>
        <Typography color="text-secondary-2" font="Hint / 10 BOLD">
          {t('expires')}
        </Typography>
        <Typography color="text-primary" font="Body / 16 Bold">
          {dayjs(blockStatus.expire_date).format('DD.MM.YYYY HH:mm')}
        </Typography>
      </StyledBreakInfoItem>
    </StyledBreakInfo>
  )
}
