import styled, { css } from 'styled-components'

export const EventCardProbabilitiesWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    padding: 0 ${theme.tokens.alias.space['space-xxxs-4']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`
