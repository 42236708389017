import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { betsHistoryProviderActions } from 'astra-core/containers/BetsHistoryProvider'
import { BetsApi, BetStatus } from 'betweb-openapi-axios'
import { ApiManager } from 'astra-core/api'

import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'
import { NotificationClearHistorySuccess } from 'widgets/bets-history/components/History/components/Filter/components/NotificationClearHistory'
import { Button } from 'shared/ui/Button'
import { IconTrashFilled } from 'shared/ui/Icon/General/IconTrashFilled'
import { Modal } from 'shared/ui/modal'
import { ModalRef } from 'shared/ui/modal/modal.types'
import { useAppDispatch } from 'shared/lib/@reduxjs'

import { StyledButtonWrapper } from './button-clear-history.styled'

export const ButtonClearHistory: FC = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const modalRef = useRef<ModalRef>(null)
  const betsApi = ApiManager.getApi(BetsApi)
  const [hasCalculatedBets, setHasCalculatedBets] = useState(false)

  useEffect(() => {
    betsApi
      .getBets({
        limit: 1,
        offset: 0,
        status: BetStatus.Computed
      })
      .then((result) => setHasCalculatedBets(!!result.data.items.length))
      .catch(() => setHasCalculatedBets(false))
  }, [betsApi])

  const notifyClearHistorySuccess = usePopUpNotification({
    content: {
      children: (props) => <NotificationClearHistorySuccess {...props} />
    },
    options: {
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: 5000
    }
  })

  const handleConfirm = () => {
    dispatch(betsHistoryProviderActions.deleteBets())
    notifyClearHistorySuccess()
    modalRef.current?.close()
  }

  const handleCancel = () => {
    modalRef.current?.close()
  }

  return (
    <>
      <Button
        disabled={hasCalculatedBets}
        icon={IconTrashFilled}
        view="outline"
        onClick={() => modalRef.current?.open()}
      >
        {t('to clear history')}
      </Button>
      <Modal
        ref={modalRef}
        text={t('with deletion of bets history all the data will be lost')}
        title={t('bets history deletion')}
      >
        <StyledButtonWrapper>
          <Button view="secondary-extra-large" onClick={handleConfirm}>
            {t('to confirm')}
          </Button>
          <Button view="primary-extra-large" onClick={handleCancel}>
            {t('to cancel')}
          </Button>
        </StyledButtonWrapper>
      </Modal>
    </>
  )
}
