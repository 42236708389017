import React, { useEffect } from 'react'
import {
  betsHistoryProviderActions,
  selectBetsHistoryDataBetsFetchItems,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'

import { FooterOfPage } from 'widgets/footer-of-page'
import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'
import {
  Filter,
  SectionSelect
} from 'widgets/bets-history/components/History/components'
import {
  StyledColumn,
  StyledHistory,
  StyledListWrapper
} from 'widgets/bets-history/components/History/History.styled'
import { HistoryList } from 'widgets/bets-history/components/History/components/list'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { BetsHistoryContextProvider } from 'entities/bet-history/lib/bet-history.context'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { PAGINATION_SETTINGS } from './constants'

export const History = () => {
  const dispatch = useAppDispatch()
  const betsListTableData = useAppSelector(selectBetsHistoryPageValues)
  const betsFetchItems = useAppSelector(selectBetsHistoryDataBetsFetchItems)

  useFetchBetsHistoryPage()

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  return (
    <BetsHistoryContextProvider betIds={betsListTableData}>
      <StyledHistory>
        <SectionSelect />
        <Filter />

        <StyledColumn>
          {betsListTableData.length ? (
            <StyledListWrapper pagination={PAGINATION_SETTINGS}>
              <HistoryList />
            </StyledListWrapper>
          ) : (
            <>
              <>
                {betsFetchItems.loading ? <LoaderSpinner /> : <HistoryList />}
              </>
              <FooterOfPage />
            </>
          )}
        </StyledColumn>
      </StyledHistory>
    </BetsHistoryContextProvider>
  )
}
