import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledBalanceValue = styled.span`
  ${typography['Body / 14 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    white-space: nowrap;
  `}
`
