import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconLock: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.437 7.114v.354h-2.13v-.354c0-1.718-1.484-3.116-3.306-3.116h-.01c-1.823 0-3.306 1.398-3.306 3.116v2.454h8.348c.498 0 .827.16 1.155.47l1.314 1.398c.328.31.498.62.498 1.089v6.518c0 .31-.17.78-.499 1.089l-1.314 1.398c-.328.31-.657.47-1.155.47H6.967c-.498 0-.827-.16-1.155-.47l-1.314-1.398c-.328-.31-.498-.62-.498-1.089v-6.518c0-.31.17-.78.499-1.089l1.314-1.399c.23-.218.462-.361.752-.427V7.114C6.565 4.287 9.002 2 12.001 2s5.425 2.287 5.435 5.114"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
