import React from 'react'

import { Carousel } from 'shared/ui/Carousel'
import { EventCardLoader } from 'features/event-card'

import {
  SLIDES_GAP,
  SLIDES_HEIGHT,
  SLIDES_MAX_WIDTH,
  SLIDES_MIN_WIDTH
} from './top-events.constants'

export const TopEventsLoader = () => {
  return (
    <Carousel
      childHeight={SLIDES_HEIGHT}
      childMaxWidth={SLIDES_MAX_WIDTH}
      childMinWidth={SLIDES_MIN_WIDTH}
      leftOffset={0}
      rightOffset={0}
      slidesGap={SLIDES_GAP}
    >
      <EventCardLoader />
      <EventCardLoader />
      <EventCardLoader />
      <EventCardLoader />
      <EventCardLoader />
      <EventCardLoader />
      <EventCardLoader />
      <EventCardLoader />
    </Carousel>
  )
}
