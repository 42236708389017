import styled, { css } from 'styled-components'

export const StyledChampionshipsWrapper = styled.div``

export const StyledResultChampionshipWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias.space['space-xs-8']}px;
    outline: 1px solid ${theme.tokens.colors.divider['divider-primary']};
    outline: -1px;

    > div:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    > div:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: none;
    }
  `}
`

export const StyledResultChampionshipTitle = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.tokens.colors.surface['surface-event-title']};
    box-shadow: ${theme.tokens.colors.divider['divider-primary']} 0px -1px inset;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    justify-content: space-between;
    padding: ${theme.tokens.alias.space['space-xxs-6']}px
      ${theme.tokens.alias.space['space-m-16']}px;
    position: sticky;
    top: 0;
  `}
`

export const StyledResultTitleAndIcon = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledSportIconWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px;

    & > span {
      height: 12px;
      width: 12px;
    }
  `}
`
