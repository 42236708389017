import React, { FC, memo, PropsWithChildren } from 'react'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { NotificationsPanel } from 'widgets/notifications-panel'
import { FooterOfPage } from 'widgets/footer-of-page'
import { SportsMenu } from 'widgets/sports-menu'
import { ColumnLayout, ColumnLayoutScroll } from 'shared/lib/layout'
import { Basket } from 'widgets/basket'
import { useAppSelector } from 'shared/lib/@reduxjs'

import * as S from './external-feature-provider.styled'

export const ExternalFeatureProvider: FC<PropsWithChildren> = memo(
  ({ children }) => {
    const config = useAppSelector(selectConfig)

    return (
      <ColumnLayout>
        <SportsMenu />

        <NotificationsPanel />

        <ColumnLayoutScroll>
          <S.Content hideHeader={config.IS_IFRAME}>{children}</S.Content>

          <FooterOfPage />
        </ColumnLayoutScroll>

        <Basket />
      </ColumnLayout>
    )
  }
)
