import styled, { css } from 'styled-components'

import { ButtonDeprecated } from 'shared/ui/ButtonDeprecated'
import { IStyledSectionItem } from 'widgets/bets-history/components/History/components/SectionSelect/SectionSelect.types'

export const StyledSectionItem = styled(ButtonDeprecated)<IStyledSectionItem>`
  border-radius: 0;
  color: ${(props) =>
    props.isActiveItem
      ? props.theme.colors.default.primary[90]
      : props.theme.colors.custom.primary[17]};
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: auto;
  margin-right: 5px;
  padding: 10px 5px;
  position: relative;

  &::after {
    ${(props) => (props.isActiveItem ? `content: '';` : '')};
    background: ${(props) => props.theme.colors.default.primary[90]};
    border-radius: 1px;
    bottom: 0;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

export const NavTabsWrapper = styled.nav`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px ${theme.tokens.colors.border['border-default-gray']};
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px;
  `}
`
