import React from 'react'

import { MyBonuses } from 'widgets/my-bonuses'
import { LayoutProfile } from 'layouts/profile'

export const PageMyBonuses = () => (
  <LayoutProfile
    pageTitle={{ text: 'my bonuses', titleSurfaceColor: 'surface-base' }}
  >
    <MyBonuses />
  </LayoutProfile>
)

export default PageMyBonuses
