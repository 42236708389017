import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledMainPageEventsWrapper = styled.div`
  display: grid;
`

export const StyledMainPageEventsHeaderWrapper = styled.div`
  ${({ theme: { tokens } }) => css`
    padding: ${tokens.alias.space['space-xl-24']}px
      ${tokens.alias.space['space-m-16']}px
      ${tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledMainPageEventsHeader = styled.div`
  flex-grow: 1;
  ${typography['Title / 20 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const StyledMainPageEventsControlsWrapper = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding-left: 0.5rem;

  & > button {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }
`

export const StyledEventsTable = styled.div`
  display: grid;
`

export const StyledEventsCarouselWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledSportListWrapper = styled.div`
  ${({ theme }) => css`
    overflow: auto;
    padding-bottom: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`
