import { useCallback, useEffect } from 'react'
import { Sport } from 'betweb-openapi-axios'

import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import { SportTabs } from 'features/sport-tabs'
import { StyledSportListWrapper } from 'pages/page-main/Main/components/live-events/live-events.styled'
import { Skeleton } from 'shared/ui/skeleton'
import { SportTabsLoader } from 'features/sport-tabs/sport-tabs.loader'
import {
  selectLiveEventsCurrentSportId,
  selectLiveEventsFilters,
  selectLiveEventsLoaded,
  selectLiveSportsCounters
} from 'pages/page-main/MainPageContainer/selectors'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

export const MainPageSportList = () => {
  const dispatch = useAppDispatch()
  const isLoaded = useAppSelector(selectLiveEventsLoaded)
  const filters = useAppSelector(selectLiveEventsFilters)

  const activeSport = useAppSelector(selectLiveEventsCurrentSportId)
  const sportsLiveCountersList = useAppSelector(selectLiveSportsCounters)

  const onSportChange = useCallback(
    (sportId: Sport['id']) => {
      dispatch(mainPageContainerActions.selectLiveEventsSport({ sportId }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      sportsLiveCountersList.length &&
      !sportsLiveCountersList.map((item) => item.id).includes(activeSport)
    ) {
      onSportChange(sportsLiveCountersList[0]?.id)
    }
  }, [activeSport, filters.isTop, onSportChange, sportsLiveCountersList])

  return (
    <StyledSportListWrapper>
      <Skeleton isLoading={!isLoaded} styledFallback={<SportTabsLoader />}>
        <SportTabs
          activeSport={activeSport}
          items={sportsLiveCountersList}
          onChange={onSportChange}
        />
      </Skeleton>
    </StyledSportListWrapper>
  )
}
