import styled, { css } from 'styled-components'

export const StyledButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    margin-top: ${theme.tokens.alias.space['space-xl-24']}px;
    padding: 0 ${theme.tokens.alias.space['space-xxl-32']}px;
  `}
`
