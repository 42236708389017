import styled, { css } from 'styled-components'

export const StyledAboutPageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-xl-24']}px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledAboutPageArticle = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  ${({ theme }) => css`
    gap: ${theme.tokens.alias.space['space-m-16']}px;

    a,
    a:visited {
      color: ${theme.tokens.colors.text['text-linkAccent-default']};
    }
  `}
`
