import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  resultsProviderActions,
  selectResultsFilterInputSearchText
} from 'astra-core/containers/ResultsProvider'
import debounce from 'lodash/debounce'

import { EInputSize } from 'shared/ui/Input'
import { IconClearSearch } from 'shared/ui/Icon/General/icon-clear-search'
import { IconSearch } from 'shared/ui/Icon/General/icon-search'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledIconRightButton,
  StyledResultSearch,
  StyledResultSearchInput
} from './result-search-input.styled'

export const ResultSearchInput = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const valueSearch = useAppSelector(selectResultsFilterInputSearchText) || ''

  const handlerResultInputSearch = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(
            resultsProviderActions.setSearchResultsFilter({ text: value })
          ),
        10
      ),
    [dispatch]
  )

  const debouncedChangeResultInputSearch = useCallback(
    (event) => handlerResultInputSearch(event.target.value),
    [handlerResultInputSearch]
  )

  useEffect(() => {
    return () => {
      dispatch(resultsProviderActions.setSearchResultsFilter({ text: '' }))
    }
  }, [dispatch])

  const clearResultInputSearch = useCallback(
    () => dispatch(resultsProviderActions.setSearchResultsFilter({ text: '' })),
    [dispatch]
  )

  return (
    <StyledResultSearch>
      <StyledResultSearchInput
        customSize={EInputSize.L}
        icon={IconSearch}
        iconColor="icon-secondary-2"
        placeholder={t('search in the results')}
        rightPadding={28}
        value={valueSearch}
        onChange={debouncedChangeResultInputSearch}
      />

      {!!valueSearch.length && (
        <StyledIconRightButton onClick={clearResultInputSearch}>
          <IconClearSearch colorToken="icon-secondary-2" size={16} />
        </StyledIconRightButton>
      )}
    </StyledResultSearch>
  )
}
