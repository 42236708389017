import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  resultsProviderActions,
  selectFilterIsLiveResults,
  selectResultsFilterInputSearchText
} from 'astra-core/containers/ResultsProvider'

import { Typography } from 'shared/ui/typography'
import { Button } from 'shared/ui/Button'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledResultEmptyDesc,
  StyledResultEmptySearchValueWrapper,
  StyledResultEmptyWrapper
} from './result-empty.styled'

export const ResultEmpty = () => {
  const [t] = useTranslation()
  const dispatch = useAppDispatch()
  const isLiveResults = useAppSelector(selectFilterIsLiveResults)
  const valueSearch = useAppSelector(selectResultsFilterInputSearchText) || ''
  const isValueSearch = useMemo(() => !!valueSearch.length, [valueSearch])

  const clearResultInputSearch = useCallback(
    () => dispatch(resultsProviderActions.setSearchResultsFilter({ text: '' })),
    [dispatch]
  )

  return (
    <StyledResultEmptyWrapper>
      {isValueSearch ? (
        <StyledResultEmptySearchValueWrapper>
          <StyledResultEmptyDesc>
            <Typography color="text-primary" font="Body / 16 SemiBold">
              {t('no matching events')}
            </Typography>
            <Typography
              color="text-secondary-3"
              font="Body / 14 Medium"
              style={{ textAlign: 'center' }}
            >
              {t(
                'check if there are any typos, or try to change the search terms'
              )}
            </Typography>
          </StyledResultEmptyDesc>

          <Button view="secondary-extra-large" onClick={clearResultInputSearch}>
            {t('reset filter')}
          </Button>
        </StyledResultEmptySearchValueWrapper>
      ) : (
        <Typography color="text-secondary-3" font="Body / 14 Medium">
          {isLiveResults
            ? t('live events are coming soon')
            : t('there are no completed events on the selected date')}
        </Typography>
      )}
    </StyledResultEmptyWrapper>
  )
}
