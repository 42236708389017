import styled, { css } from 'styled-components'

export const NavTabsWrapper = styled.nav`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px ${theme.tokens.colors.border['border-default-gray']};
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px;
    position: sticky;
    top: 0;
  `}
`

export const StyledTabLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > div:first-child {
    flex-grow: 1;
  }
`

export const StyledTabScrollable = styled.div<{ isUser: boolean }>`
  ${({ isUser }) => css`
    ${isUser ? 'height: calc(100% - 48px)' : 'height: 100%'};
  `}
`
