import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { Profile } from './Profile'

export const PageProfile = () => (
  <LayoutProfile pageTitle={{ text: 'profile' }}>
    <Profile />
  </LayoutProfile>
)

export default PageProfile
