import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { typography } from 'shared/lib/theme/typography'
import { AppLink } from 'shared/lib/app-link'

export const StyledGameCardLink = styled(AppLink)`
  ${({ theme }) =>
    theme.typeLayoutTheme === LayoutTheme.Light
      ? css`
          background: linear-gradient(
            101.73deg,
            rgba(108, 121, 147, 0.2) 28.69%,
            #a7afbe 99.9%
          );
        `
      : css`
          background: linear-gradient(
            101.73deg,
            rgba(158, 204, 250, 0.25) 28.69%,
            #4c5567 99.9%
          );
        `}

  container: game-card / size;
  display: block;
  height: 80px;
  overflow: hidden;
  position: relative;
`

export const StyledGameCardText = styled.span`
  ${({ theme }) => css`
    ${typography['Body / 12 EXTRABOLD']}
    color: ${theme.tokens.colors.text['text-primary']};
    left: 8px;
    position: absolute;
    top: 10px;
    white-space: pre-line;
    z-index: 1;
  `}
`

export const StyledGameCardBgImg = styled.img`
  height: 80px;
  object-fit: contain;
  object-position: left top;
  position: absolute;
  width: 272px;
`

export const StyledGameCardImg = styled.img`
  height: 80px;
  position: absolute;
  top: 0px;
  z-index: 1;
`
