import styled from 'styled-components'

import { ColumnLayoutScroll } from 'shared/lib/layout'

export const LayoutScroll = styled(ColumnLayoutScroll)`
  .rcs-positioning .rcs-custom-scrollbar {
    margin-top: 0;
  }
  .rcs-positioning .rcs-inner-handle {
    height: calc(100% - 12px);
  }
`
