import styled from 'styled-components'

export const Page = styled.div`
  display: grid;
  grid-template-areas: 'content';
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  overflow: hidden;
`

export const PageContent = styled.div`
  display: grid;
  grid-area: content;
  grid-template-rows: 1fr auto;
`
