import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledServiceContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
`

export const StyledServiceTitle = styled.h1`
  ${typography['Body / 16 SemiBold']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    margin-top: ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledServiceDesc = styled.p`
  ${typography['Body / 14 Medium']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    margin-bottom: ${theme.tokens.alias.space['space-m-16']}px;
    margin-top: ${theme.tokens.alias.space['space-xxxs-4']}px;
    text-align: center;
    white-space: pre-wrap;
  `}
`
