import {
  MainOutcomeTypes,
  selectHotProbabilitiesGroups,
  selectHotProbabilitiesGroupsSports
} from 'astra-core/containers/CommonDataProvider'
import { HotProbabilityMarketType } from 'betweb-openapi-axios'

import { useAppSelector } from 'shared/lib/@reduxjs'

const EVENT_CARD_MARKETS_MAP = {
  [HotProbabilityMarketType.MatchWinnerX3]: [
    MainOutcomeTypes.W1,
    MainOutcomeTypes.WX,
    MainOutcomeTypes.W2
  ],
  [HotProbabilityMarketType.MatchWinnerX2]: [
    MainOutcomeTypes.W1_X2,
    MainOutcomeTypes.W2_X2
  ]
}

const EVENT_CARD_MARKET_TYPES = [
  HotProbabilityMarketType.MatchWinnerX3,
  HotProbabilityMarketType.MatchWinnerX2
]

const DEFAULT_GROUP = 1

export const useEventCardHotProbs = ({
  sportId
}: {
  sportId: number
}): MainOutcomeTypes[] => {
  const sportsGroups = useAppSelector(selectHotProbabilitiesGroupsSports)
  const groups = useAppSelector(selectHotProbabilitiesGroups)

  if (!groups || !sportsGroups) {
    return []
  }

  const { groupId } = sportsGroups.find(
    (item) => item.sportId === sportId && item.desktop
  ) ?? { groupId: DEFAULT_GROUP }

  const groupMarket = groups[groupId]?.markets.find((name) =>
    EVENT_CARD_MARKET_TYPES.includes(name)
  )

  if (!groupMarket || !EVENT_CARD_MARKETS_MAP[groupMarket]) {
    return EVENT_CARD_MARKETS_MAP[HotProbabilityMarketType.MatchWinnerX3]
  }

  return EVENT_CARD_MARKETS_MAP[groupMarket]
}
