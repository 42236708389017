import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

export const StyledBetGamesPanel = styled.div``

export const StyledBetGamesTitleWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.tokens.alias.space['space-xl-24']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`

export const StyledBetGamesTitle = styled.div`
  ${typography['Title / 20 Bold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding: ${theme.tokens.alias.space['space-xxxs-4']}px 0;
  `}
`

export const StyledCarouselWrapper = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledCard = styled.div`
  background: #000;
  height: 100%;
`
