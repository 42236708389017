import React from 'react'
import { VirtualGameVendor } from 'betweb-openapi-axios'
import { useGameVendorSettings } from 'astra-core'

import { AvailablePanel } from 'shared/ui/AvailablePanel'
import { LayoutGame } from 'layouts/game'

import { BetGames } from './BetGames'

export const PageGamesBet = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.BetGames)

  return (
    <LayoutGame>{isAvailable ? <BetGames /> : <AvailablePanel />}</LayoutGame>
  )
}

export default PageGamesBet
