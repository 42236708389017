export interface IPageSportGamesParams {
  productId?: string
}

export enum ESportGamesProductIds {
  DARTS = 'darts',
  BRIDGE = 'bridge',
  BOXING = 'boxing',
  SHOOTOUT = 'shootout',
  CRASH_BOXING = 'crash-boxing',
  NARD = 'nard'
}
