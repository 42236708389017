import React, { memo, useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { Scrollable } from 'shared/ui/scrollable'

import { NavLinksWrapper, StyledPromotions } from './promotions.styled'
import {
  PromotionsCurrentContent,
  PromotionsPastContent
} from './components/promotions-content/promotions-content'

export const Promotions = memo(() => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === ERoutes.Promotions) {
      history.push(ERoutes.PromotionsCurrent)
    }
  }, [history, location.pathname])

  return (
    <StyledPromotions>
      <NavLinksWrapper>
        {/* <NavTabs isControlled={false} items={PROMOTIONS_LINKS} /> */}
      </NavLinksWrapper>
      <Scrollable>
        <Switch>
          <Route
            component={PromotionsCurrentContent}
            path={ERoutes.PromotionsCurrent}
          />
          <Route
            component={PromotionsPastContent}
            path={ERoutes.PromotionsPast}
          />
        </Switch>
      </Scrollable>
    </StyledPromotions>
  )
})
