import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Scrollable } from 'shared/ui/scrollable'

import { StyledDocumentFrame } from './DocumentFrame.styled'
import { getDocumentLink } from './constants'

export const DocumentFrame: FC<{ documentName: string }> = ({
  documentName
}) => {
  const { i18n } = useTranslation()

  const docLink = getDocumentLink(documentName, i18n.language)

  return (
    <Scrollable>
      <StyledDocumentFrame>
        <embed
          height="100%"
          src={`${docLink}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`}
          type="application/pdf"
          width="1000"
        />
      </StyledDocumentFrame>
    </Scrollable>
  )
}
