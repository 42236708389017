import React, { memo } from 'react'

// import { IconPhone } from 'shared/ui/Icon/General/IconPhone'
import { SupportContent, SupportHeader } from 'widgets/support'

// import { SUPPORT_BANNER } from './constants'
import {
  // StyledBanner,
  // StyledBannerContent,
  // StyledBannerLabel,
  // StyledBannerPhone,
  // StyledBannerValue,
  // StyledIconSupportUnion,
  // StyledItem,
  // StyledItemContent,
  StyledSupportPage
  // StyledItemLabel,
  // StyledItemDescr,
  // StyledItemRout
} from './SupportPage.styled'
// import { ISupport } from './SupportPage.types'

export const SupportPage = memo(() => {
  return (
    <>
      <StyledSupportPage>
        <SupportHeader />
        <SupportContent />
        {/* <Banner /> */}
        {/* {SUPPORT.map((item) => (
        <Item {...item} key={item.id} />
      ))} */}
      </StyledSupportPage>
    </>
  )
})

// const Banner = () => {
//   return (
//     <StyledBanner key={SUPPORT_BANNER.id}>
//       <StyledBannerContent>
//         <StyledBannerLabel>{SUPPORT_BANNER.label}</StyledBannerLabel>
//         <StyledBannerPhone>
//           <IconPhone size={32} />
//           {SUPPORT_BANNER.phoneNumber}
//         </StyledBannerPhone>
//         <StyledBannerValue>{SUPPORT_BANNER.value}</StyledBannerValue>
//         <StyledIconSupportUnion />
//       </StyledBannerContent>
//     </StyledBanner>
//   )
// }

// const Item = ({
//   id,
//   label,
//   value,
//   valueBtn,
//   routes = '',
//   onClick,
//   Icon
// }: ISupport) => {
//   return (
//     <StyledItem id={id}>
//       <StyledItemContent>
//         <StyledItemLabel>
//           {Icon !== undefined && <Icon size={32} />} {label}
//         </StyledItemLabel>
//         <StyledItemDescr>{value}</StyledItemDescr>
//         <StyledItemRout to={routes} onClick={onClick}>
//           {valueBtn}
//         </StyledItemRout>
//       </StyledItemContent>
//     </StyledItem>
//   )
// }
