import styled, { css } from 'styled-components'
import isEqual from 'react-fast-compare'
import { memo } from 'react'

import { CarouselTrack } from 'shared/ui/Carousel/Carousel.styled'
import { AppLink } from 'shared/lib/app-link'

import { StyledBannerProps } from './Banners.types'

export const StyledCarouselWrapper = memo(
  styled.div<{ bannersExist: boolean }>`
    ${({ theme, bannersExist }) => css`
      overflow: hidden;

      padding: ${theme.tokens.alias.space['space-m-16']}px 0px
        ${theme.tokens.alias.space['space-xs-8']}px;

      ${CarouselTrack} {
        overflow: ${bannersExist ? 'auto' : 'hidden'};
      }
    `}
  `,
  isEqual
)

export const StyledBannersLink = styled(AppLink)``

export const StyledBanner = styled.div<StyledBannerProps>`
  ${({ theme, imageUrl, childHeight }) => css`
    background: ${imageUrl
        ? `url(${imageUrl})`
        : theme.tokens.colors.surface['surface-active-4']}
      no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    height: ${childHeight || 170}px;
    width: 414px;
  `}
`
