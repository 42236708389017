import React from 'react'

import { SportsMenu } from 'widgets/sports-menu'
import { NotificationsPanel } from 'widgets/notifications-panel'
import {
  ColumnLayout,
  ColumnLayoutContent,
  ColumnLayoutScroll
} from 'shared/lib/layout'
import { FooterOfPage } from 'widgets/footer-of-page'
import { Basket } from 'widgets/basket'

import { MainPageContainer } from './MainPageContainer'

export const PageMain = () => (
  <ColumnLayout>
    <SportsMenu />

    <NotificationsPanel />

    <ColumnLayoutScroll>
      <ColumnLayoutContent>
        <MainPageContainer />

        <FooterOfPage />
      </ColumnLayoutContent>
    </ColumnLayoutScroll>

    <Basket />
  </ColumnLayout>
)

export default PageMain
