export const getDeclinationType = (number: number) => {
  const cases = [2, 0, 1, 1, 1, 2]

  return number % 100 > 4 && number % 100 < 20
    ? 2
    : cases[number % 10 < 5 ? number % 10 : 5]
}

export const formattedPhoneNumber = (phoneNumber: string): string => {
  // Clear the number of all characters except numbers
  const cleaned = `${phoneNumber}`.replace(/\D/g, '')

  // Cut out the country code (+N) and add it to the result
  const country = cleaned.slice(0, 1)
  const area = cleaned.slice(1, 4)
  const firstPart = '***'
  const secondPart = `*${cleaned.slice(8, 9)}`
  const lastPart = cleaned.slice(9, 11)

  return `+${country}(${area})${firstPart}-${secondPart}-${lastPart}`
}
