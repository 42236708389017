import {
  StyledAccordionsWrapper,
  StyledInfoTabContainer,
  StyledInfoTabContent,
  StyledInfoTabMain,
  StyledInfoTabText,
  StyledInfoTabWrapper
} from './InfoTab.styled'
import { CARDS, InfoTabLocales } from './lib'
import { Accordion } from './ui'

export const InfoTab = () => {
  return (
    <StyledInfoTabWrapper>
      <StyledInfoTabContent>
        <StyledInfoTabMain>
          <StyledInfoTabText>{InfoTabLocales['paragraph-1']}</StyledInfoTabText>
          <StyledInfoTabContainer>
            {InfoTabLocales['paragraph-accent']}
          </StyledInfoTabContainer>
          <StyledInfoTabText>{InfoTabLocales['paragraph-2']}</StyledInfoTabText>
          <StyledInfoTabText>{InfoTabLocales['paragraph-3']}</StyledInfoTabText>
        </StyledInfoTabMain>

        <StyledAccordionsWrapper>
          {CARDS.map(({ title, card }) => (
            <Accordion key={title} title={title}>
              {card}
            </Accordion>
          ))}
        </StyledAccordionsWrapper>
      </StyledInfoTabContent>
    </StyledInfoTabWrapper>
  )
}
