import React, {
  FC,
  memo,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { range } from 'lodash'
import debounce from 'lodash/debounce'
import useMeasure from 'react-use-measure'

import { IconArrowLeft } from 'shared/ui/Icon/General/IconArrowLeft'
import { IconArrowRight } from 'shared/ui/Icon/General/IconArrowRight'
import {
  CarouselProps,
  ScrollDirection,
  TCarouselDotsProps
} from 'shared/ui/Carousel/Carousel.types'
import {
  CarouselContainer,
  CarouselDot,
  CarouselDotsWrapper,
  CarouselItem,
  CarouselTrack,
  CarouselTrackWrapper,
  CarouselWrapper,
  ControlButton
} from 'shared/ui/Carousel/Carousel.styled'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'

const MemoizedCarouselDot = memo(CarouselDot)

const CarouselDots: FC<TCarouselDotsProps> = memo(
  ({ pagesToScroll, pageIndex }) => (
    <CarouselDotsWrapper>
      {range(pagesToScroll + 1).map((item, index) => (
        <MemoizedCarouselDot isActive={index === pageIndex} key={item} />
      ))}
    </CarouselDotsWrapper>
  )
)

export const Carousel: FC<PropsWithChildren<CarouselProps>> = ({
  children,
  childHeight,
  childMinWidth,
  childMaxWidth = Infinity,
  childAspectRatio = undefined,
  slidesGap,
  autoplayInterval = 4000,
  infinite = false,
  isBlockScrolling = false,
  leftOffset = 16,
  rightOffset = 16,
  hideDots
}) => {
  const [wrapperRef, wrapperRect] = useMeasure()
  const hydrated = useHydrated()

  const childrenArray = useMemo(
    () => React.Children.toArray(children) as ReactElement[],
    [children]
  )

  const slidesToShow = Math.max(
    Math.min(
      Math.floor((wrapperRect.width + slidesGap) / (childMinWidth + slidesGap)),
      childrenArray.length
    ),
    1
  )

  const childWidth = Math.max(
    childMinWidth,
    Math.min(
      infinite
        ? childMinWidth
        : (wrapperRect.width - (slidesToShow - 1) * slidesGap) / slidesToShow,
      childMaxWidth
    )
  )

  const carouselRef = useRef<HTMLDivElement>(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [isHovered, setIsHovered] = useState(false)

  const autoplayRef = useRef<NodeJS.Timeout | null>(null)

  const widthToScroll = isBlockScrolling
    ? wrapperRect.width
    : childWidth + slidesGap

  const startPos = infinite
    ? Math.round(childrenArray.length * widthToScroll)
    : 0

  const pagesToScroll = useMemo(() => {
    if (infinite) {
      return childrenArray.length - 1
    }

    if (isBlockScrolling && slidesToShow > 0) {
      return Math.ceil(childrenArray.length / slidesToShow) - 1
    }
    return childrenArray.length - slidesToShow
  }, [childrenArray.length, infinite, isBlockScrolling, slidesToShow])

  const isInfiniteScrollable =
    infinite &&
    wrapperRect.width < childrenArray.length * widthToScroll - slidesGap

  const infinityArray = useMemo(
    () => [
      ...childrenArray,
      ...childrenArray.map((child) => {
        return React.cloneElement(child, { key: `${child.key}_clone` })
      })
    ],
    [childrenArray]
  )

  const extraSpace = wrapperRect.width - slidesToShow * widthToScroll

  useEffect(() => {
    if (carouselRef.current) {
      if (infinite) {
        carouselRef.current.scrollLeft = startPos
      }
    }
  }, [infinite, childWidth, startPos])

  const scrollLeftTo = (scrollTo: number) => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        left: scrollTo,
        behavior: 'smooth'
      })
    }
  }

  const handleArrowClick = (direction: ScrollDirection) => {
    if (carouselRef.current) {
      const { scrollWidth, scrollLeft } = carouselRef.current
      let newScrollLeft = scrollLeft + direction * widthToScroll

      if (newScrollLeft > scrollWidth) {
        carouselRef.current.scrollLeft = scrollLeft - startPos
        newScrollLeft = scrollLeft - startPos + direction * widthToScroll
      } else if (scrollLeft === 0) {
        carouselRef.current.scrollLeft = startPos
        newScrollLeft = startPos + direction * widthToScroll
      }

      scrollLeftTo(newScrollLeft)
    }
  }

  const autoplayScrolling = useRef(() => {
    if (autoplayRef.current) {
      clearTimeout(autoplayRef.current)
    }

    autoplayRef.current = setTimeout(() => {
      handleArrowClick(ScrollDirection.NEXT)
      autoplayScrolling.current()
    }, autoplayInterval)
  })

  useEffect(() => {
    if (!infinite) return

    const startAutoplay = () => {
      if (!isHovered) {
        autoplayScrolling.current()
      } else if (autoplayRef.current) {
        clearTimeout(autoplayRef.current)
      }
    }

    startAutoplay() // Initial autoplay state

    return () => {
      if (autoplayRef.current) {
        clearTimeout(autoplayRef.current)
      }
    }
  }, [autoplayInterval, infinite, isHovered])

  const adjustInfiniteItems = debounce(() => {
    if (carouselRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current
      const maxScrollLeft = scrollWidth - clientWidth

      // scroll teleport
      if (infinite) {
        if (scrollLeft === maxScrollLeft) {
          carouselRef.current.scrollLeft =
            childWidth * (childrenArray.length - slidesToShow) + 1 - extraSpace
        }

        if (scrollLeft === 0) {
          carouselRef.current.scrollLeft = startPos
        }
      }
    }
  }, 10)

  const handleScroll = () => {
    if (carouselRef.current) {
      const { scrollLeft } = carouselRef.current

      setPageIndex(
        Math.round((scrollLeft + leftOffset) / widthToScroll) %
          (pagesToScroll + 1)
      )
    }
    adjustInfiniteItems()
  }

  const showNavDots =
    hydrated &&
    !hideDots &&
    wrapperRect.width !== 0 &&
    ((!infinite && pagesToScroll >= 1) || isInfiniteScrollable)

  const isCardSnapping = (index: number) => {
    if (isBlockScrolling) {
      return index % slidesToShow === 0
    }
    return true
  }

  return (
    <CarouselContainer>
      <CarouselWrapper
        onTouchEnd={() => {
          setIsHovered(false)
        }}
        onTouchStart={() => {
          setIsHovered(true)
        }}
      >
        <CarouselTrackWrapper ref={(ref) => wrapperRef(ref)}>
          <CarouselTrack
            childMaxWidth={childMaxWidth}
            childMinWidth={childMinWidth}
            fillPage={isBlockScrolling}
            isCommonSize={infinite}
            itemsLength={childrenArray.length}
            leftOffset={leftOffset}
            ref={carouselRef}
            rightOffset={rightOffset}
            slidesGap={slidesGap}
            onScroll={handleScroll}
          >
            {(isInfiniteScrollable ? infinityArray : childrenArray).map(
              (child, index) => (
                <CarouselItem
                  childAspectRatio={childAspectRatio}
                  childHeight={childHeight}
                  isSnapping={isCardSnapping(index)}
                  key={(child as React.ReactElement).key}
                >
                  {child}
                </CarouselItem>
              )
            )}
          </CarouselTrack>
        </CarouselTrackWrapper>

        {((!infinite && pagesToScroll > 0) || isInfiniteScrollable) && (
          <>
            {(pageIndex !== 0 || infinite) && (
              <ControlButton
                data-dir="prev"
                icon={IconArrowLeft}
                offset={leftOffset}
                view="outline-m"
                onClick={() => handleArrowClick(ScrollDirection.PREV)}
              />
            )}

            {(pageIndex < pagesToScroll || infinite) && (
              <ControlButton
                data-dir="next"
                icon={IconArrowRight}
                offset={rightOffset}
                view="outline-m"
                onClick={() => handleArrowClick(ScrollDirection.NEXT)}
              />
            )}
          </>
        )}
      </CarouselWrapper>

      {showNavDots && (
        <CarouselDots pageIndex={pageIndex} pagesToScroll={pagesToScroll} />
      )}
    </CarouselContainer>
  )
}
