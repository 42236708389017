import { useTranslation } from 'react-i18next'

import { basePromoAPi } from 'entities/legacy-api'

import { EPromoFilterConditions, EPromoType } from './constants'

const { useGetPromotionsQuery } = basePromoAPi

export const useGetPromotions = (type: EPromoType) => {
  const { data, isLoading } = useGetPromotionsQuery({})
  const { i18n } = useTranslation()
  const currentLang = i18n.language === 'kz' ? 'kk' : i18n.language

  if (isLoading || !data?.promo?.length) return { promoData: [], isLoading }

  const filteredPromoData = filterPromotions(data.promo)

  const promoDataWithLang = filteredPromoData
    .map((promo) => {
      const promoWithCorrectLang = promo.html.find(
        (item) => item.lang === currentLang
      )
      return promoWithCorrectLang
        ? { ...promo, html: promoWithCorrectLang }
        : null
    })
    .filter(Boolean)

  const currentTime = Date.now() / 1000

  const filteredPromoDataByDate = promoDataWithLang.filter((promo) => {
    const isWithinDateRange =
      type === EPromoType.CURRENT
        ? currentTime >= promo.startAt && currentTime <= promo.endsIn
        : currentTime > promo.endsIn

    return isWithinDateRange
  })

  return { promoData: filteredPromoDataByDate, isLoading }
}

const filterPromotions = (promotions) => {
  return promotions.filter((promo) => {
    const hasCorrectPosition =
      promo.position === EPromoFilterConditions.POSITION

    const hasCorrectLinkType =
      promo.link_type === EPromoFilterConditions.LINK_TYPE

    const hasCorrectProject = promo.project === EPromoFilterConditions.PROJECT

    return hasCorrectPosition && hasCorrectLinkType && hasCorrectProject
  })
}
