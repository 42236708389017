import { useEventsPoll } from 'entities/event/api'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import { mainPageContainerActions } from './slice'
import { selectLiveEventsFilters } from './selectors'

export const useMainPageTopEventsData = () => {
  const dispatch = useAppDispatch()

  useEventsPoll({
    fetch: () => {
      dispatch(mainPageContainerActions.fetchTopEvents())
    },
    pollingInterval: 5000
  })
}

export const useMainPageLiveEventsData = () => {
  const dispatch = useAppDispatch()
  const { isTop } = useAppSelector(selectLiveEventsFilters)

  useEventsPoll({
    fetch: () => {
      dispatch(mainPageContainerActions.fetchLiveEvents())
    },
    top: isTop,
    pollingInterval: 5000
  })
}
