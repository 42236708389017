import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { range } from 'lodash'

import { InfoTabLocales } from 'features/responsible-gambling/info-tab/lib'
import {
  useGetBreakData,
  useGetLimitData
} from 'features/responsible-gambling/lib'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { Typography } from 'shared/ui/typography'
import { RestrictionInfo } from 'features/responsible-gambling/restriction-info'
import { ESelectView } from 'shared/ui/select/select.types'
import { Select } from 'shared/ui/select'
import { ConfirmModal } from 'features/responsible-gambling/confirm-modal'
import { EConfirmView } from 'features/responsible-gambling/confirm-modal/lib/types'
import { Button } from 'shared/ui/Button'

import {
  StyledBreakAction,
  StyledBreakContainer,
  StyledBreakContent,
  StyledBreakInfoWrapper
} from './BreakContent.styled'

export const BreakContent = () => {
  const [t] = useTranslation()
  const tFunc = (key) => InfoTabLocales[key]

  const [selectedOption, setSelectedOption] = useState({ value: 0, label: '' })

  const handleOptionChange = useCallback(
    (value) => setSelectedOption(value),
    []
  )

  const { limitStatus } = useGetLimitData()
  const { periodsLoading, statusLoading, blockOptions, blockStatus } =
    useGetBreakData()

  useEffect(() => {
    if (blockOptions.length) {
      setSelectedOption(blockOptions[0])
    }
  }, [blockOptions])

  return (
    <StyledBreakContainer maxHeight={statusLoading || periodsLoading}>
      {periodsLoading || statusLoading ? (
        <LoaderLogo />
      ) : (
        <StyledBreakContent>
          <Typography color="text-primary" font="Title / 18 Bold">
            {t('game break')}
          </Typography>

          {blockStatus?.expire_date || limitStatus?.expire_date ? (
            <StyledBreakInfoWrapper>
              <RestrictionInfo />
            </StyledBreakInfoWrapper>
          ) : (
            <StyledBreakAction>
              <Select
                label="period"
                options={blockOptions}
                value={selectedOption}
                view={ESelectView.SECONDARY}
                onChange={handleOptionChange}
              />

              <ConfirmModal
                period={+selectedOption.value}
                view={EConfirmView.BREAK}
              >
                <Button view="primary-extra-large">{t('apply')}</Button>
              </ConfirmModal>
            </StyledBreakAction>
          )}

          <div>
            <p>{tFunc('break-p-1')}</p>
            <br />
            <p>{tFunc('break-p-2')}</p>
            <ul>
              {range(4).map((idx) => (
                <li key={idx}>
                  <span>{tFunc(`break-li-${idx + 1}`)}</span>
                </li>
              ))}
            </ul>
            <br />
            <p>{tFunc('break-p-3')}</p>
            <br />
            <p>{tFunc('break-p-4')}</p>
          </div>
        </StyledBreakContent>
      )}
    </StyledBreakContainer>
  )
}
