import React from 'react'

import {
  StyledFilter,
  StyledFilterList,
  StyledFilterWrap
} from './Filter.styled'
import {
  ButtonToggleCollapse,
  ButtonClearHistory,
  SelectBetTypes
} from './components'
import { BetStatus } from './components/bet-status'

export const Filter = () => {
  return (
    <StyledFilter>
      <StyledFilterList>
        <BetStatus />
        <SelectBetTypes />
      </StyledFilterList>

      <StyledFilterWrap>
        <ButtonClearHistory />
        <ButtonToggleCollapse />
      </StyledFilterWrap>
    </StyledFilter>
  )
}
