import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { TFunction } from 'i18next'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

type TodayValueParams = {
  date: Date
  addText?: string
  t: TFunction
}

export const getIsTodayValue = ({
  date,
  t,
  addText = ''
}: TodayValueParams): string => {
  const isToday = (date: Date) => {
    const today = new Date()

    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  if (isToday(date)) {
    return `${t('today')}${addText}`
  }
  return ''
}

export const isDateToday = (eventDate: Date | string) => {
  return dayjs(eventDate).isToday()
}

export const isDateYesterday = (eventDate: Date | string) => {
  return dayjs(eventDate).isYesterday()
}

export const isCurrentYear = (date) => {
  const inputDate = dayjs(date)
  const currentYear = dayjs().year()

  return inputDate.year() === currentYear
}
