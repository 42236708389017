import styled, { css } from 'styled-components'

export const StyledLimitInfo = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
  `}
`

export const StyledLimitInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
`

export const StyledLimitInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StyledBreakInfo = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-default']};
  `}
`

export const StyledBreakInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
`

export const StyledModalIconWrapper = styled.div`
  padding-top: 2px;
`

export const StyledModalWarning = styled.div`
  display: flex;

  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-warning']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    outline: 1px solid ${theme.tokens.colors.border['border-warning-opacity']};
    outline-offset: -1px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px
      ${theme.tokens.alias.space['space-s-12']}px;
  `}
`
