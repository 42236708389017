import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { ERoutes } from 'shared/types/routes'

export enum EPromoFilterConditions {
  POSITION = 'PROMO',
  LINK_TYPE = 3,
  PROJECT = 'new.olimpbet.kz'
}

export enum EPromoType {
  PAST = 'past',
  CURRENT = 'current'
}

export const PROMOTIONS_LINKS = [
  {
    to: ERoutes.PromotionsCurrent,
    titleKey: EPromoType.CURRENT
  }
  // {
  //   to: ERoutes.PromotionsPast,
  //   titleKey: EPromoType.PAST
  // }
]

export const formatPromoDateRange = (
  startAt: number,
  endsIn: number
): string => {
  const startDate = dayjs.unix(startAt).format('D MMMM')
  const endDate = dayjs.unix(endsIn).format('D MMMM')

  return `${startDate} - ${endDate}`
}
