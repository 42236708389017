import styled, { css } from 'styled-components'

export const StyledPromotions = styled.div`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`

export const NavLinksWrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-base']};
    box-shadow: inset 0 -1px ${theme.tokens.colors.border['border-default-gray']};
    padding: 0 ${theme.tokens.alias.space['space-m-16']}px;
  `}
`

export const StyledPromotionsContent = styled.div`
  ${({ theme }) => css`
    overflow: auto;
    padding: ${theme.tokens.alias.space['space-l-20']}px;
  `}
`

export const StyledPromotionsContentWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: ${theme.tokens.alias.space['space-l-20']}px;
    grid-auto-rows: min-content;

    @media only screen and (max-width: 1059px) {
      grid-template-columns: repeat(1, min-content);
    }

    @media only screen and (min-width: 1060px) and (max-width: 1439px) {
      grid-template-columns: repeat(2, min-content);
    }

    @media only screen and (min-width: 1440px) and (max-width: 1819.9px) {
      grid-template-columns: repeat(3, min-content);
    }

    @media only screen and (min-width: 1820px) {
      grid-template-columns: repeat(4, min-content);
    }
  `}
`

export const StyledPromotionsEmptyContent = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.tokens.colors.surface['surface-page']};
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-s-12']}px;
    height: 100%;
    justify-content: center;
  `}
`

export const StyledPromotionsEmptyDescWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
    justify-content: center;
    text-align: center;
    width: 227px;
  `}
`

export const StyledPromotionsEmptyIconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    path {
      fill: ${theme.tokens.colors.icon['icon-secondary-3']};
    }
  `}
`
