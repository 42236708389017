import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TCustomToastContentProps, ToastifyContent } from 'shared/lib/Toastify'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'

export const NotificationClearSuccess: FC<TCustomToastContentProps> = () => {
  const [t] = useTranslation()

  return (
    <ToastifyContent
      leftContent={<IconCheckCircleFilled colorToken="icon-positive" />}
    >
      {t('favorites cleared')}
    </ToastifyContent>
  )
}
