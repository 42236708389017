import { getCurrentLng } from 'astra-core'

import { i18nLocalToLegacyLang } from 'shared/lib/locale'

import { baseLegacyApi } from '../api'

import {
  GetPeriodsRequest,
  GetPeriodsResponse,
  GetStatusRequest,
  GetStatusResponse,
  PostBreakRequest,
  PostBreakResponse,
  PostLimitRequest,
  PostLimitResponse
} from './responsible-gambling.types'

export const responsibleGamblingApi = baseLegacyApi.injectEndpoints({
  endpoints: (builder) => ({
    getPeriods: builder.query<GetPeriodsResponse, GetPeriodsRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/self_exclusion/periods',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    }),
    getStatus: builder.query<GetStatusResponse, GetStatusRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/self_exclusion/status',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    }),
    postLimit: builder.mutation<PostLimitResponse, PostLimitRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/self_exclusion/limit',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    }),
    postBreak: builder.mutation<PostBreakResponse, PostBreakRequest>({
      query: (args) => ({
        method: 'POST',
        url: '/user/self_exclusion/block',
        body: {
          ...args,
          session: '__session__',
          platforma: 'NEWOLIMPBET',
          lang_id: i18nLocalToLegacyLang(getCurrentLng())
        }
      })
    })
  })
})
