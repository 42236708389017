import styled from 'styled-components'

export const StyledEmptyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  justify-content: center;
`

export const StyledEmptyIcon = styled.div`
  margin-bottom: 8px;
`

export const StyledEmptyAdvice = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
`
