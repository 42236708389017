import React, { FC, PropsWithChildren } from 'react'

import { FooterOfPage } from 'widgets/footer-of-page'

import * as S from './error.styled'

export const Error: FC<PropsWithChildren> = ({ children }) => {
  return (
    <S.Error>
      <S.Content>
        {children}
        <FooterOfPage />
      </S.Content>
    </S.Error>
  )
}
