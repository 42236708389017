import React from 'react'
import { useParams } from 'react-router-dom'

import { FAQDetailsParams } from 'entities/faq'
import { useFAQTranslation } from 'entities/faq/lib/utils'

import { StyledFAQDetailsPage } from './FAQDetailsPage.styled'

export const FAQDetailsPage = () => {
  const { faqId } = useParams<FAQDetailsParams>()
  const translation = useFAQTranslation(faqId)

  return (
    <StyledFAQDetailsPage>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: translation?.html
        }}
      />
    </StyledFAQDetailsPage>
  )
}
