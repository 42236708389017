import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { BetsHistoryPageContainer } from './BetsHistoryPageContainer'

export const PageBetsHistory = () => {
  return (
    <LayoutProfile pageTitle={{ text: 'history bets' }} isHeaderFilled>
      <BetsHistoryPageContainer />
    </LayoutProfile>
  )
}

export default PageBetsHistory
