import React from 'react'

import { StyledMyBonuses } from './my-bonuses.styled'
import { MyBonusesContent, MyBonusesForm } from './components'

export const MyBonuses = () => {
  return (
    <StyledMyBonuses>
      <MyBonusesForm />
      <MyBonusesContent />
    </StyledMyBonuses>
  )
}
