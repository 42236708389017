import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ConfirmModal } from 'features/responsible-gambling/confirm-modal'
import { EConfirmView } from 'features/responsible-gambling/confirm-modal/lib/types'
import { InfoTabLocales } from 'features/responsible-gambling/info-tab/lib'
import {
  useGetBreakData,
  useGetLimitData
} from 'features/responsible-gambling/lib'
import { Button } from 'shared/ui/Button'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { Select } from 'shared/ui/select'
import { Typography } from 'shared/ui/typography'
import { RestrictionInfo } from 'features/responsible-gambling/restriction-info'
import { ESelectView } from 'shared/ui/select/select.types'

import { LimitInput } from '../limit-input'

import {
  StyledLimitAction,
  StyledLimitContainer,
  StyledLimitContent,
  StyledLimitInfoWrapper,
  StyledOpenChatBtn
} from './LimitContent.styled'

export const LimitContent = () => {
  const [t] = useTranslation()
  const tFunc = (key) => InfoTabLocales[key]

  const [limitSum, setLimitSum] = useState('')
  const [selectedOption, setSelectedOption] = useState({
    value: 0,
    label: ''
  })

  const handleOptionChange = useCallback(
    (value) => setSelectedOption(value),
    []
  )

  const { blockStatus } = useGetBreakData()
  const { periodsLoading, statusLoading, limitOptions, limitStatus } =
    useGetLimitData()

  const [errorMessage, setErroMessage] = useState('')

  const handleValidateInput = useCallback(() => {
    if (!limitSum) {
      setErroMessage('_')
    }
  }, [limitSum])

  useEffect(() => {
    if (limitOptions.length) {
      setSelectedOption(limitOptions[0])
    }
  }, [limitOptions])

  return (
    <StyledLimitContainer maxHeight={statusLoading || periodsLoading}>
      {periodsLoading || statusLoading ? (
        <LoaderLogo />
      ) : (
        <StyledLimitContent>
          <Typography color="text-primary" font="Title / 18 Bold">
            {t('betting limit')}
          </Typography>

          {limitStatus?.expire_date || blockStatus?.expire_date ? (
            <StyledLimitInfoWrapper>
              <RestrictionInfo />
            </StyledLimitInfoWrapper>
          ) : (
            <StyledLimitAction>
              <LimitInput errorMessage={errorMessage} onChange={setLimitSum} />
              <Select
                label="period"
                options={limitOptions}
                value={selectedOption}
                view={ESelectView.SECONDARY}
                onChange={handleOptionChange}
              />

              <ConfirmModal
                period={+selectedOption.value}
                sum={+limitSum}
                view={EConfirmView.LIMIT}
              >
                <Button
                  view="primary-extra-large"
                  onClick={handleValidateInput}
                >
                  {t('apply')}
                </Button>
              </ConfirmModal>
            </StyledLimitAction>
          )}

          <div>
            <Trans
              components={[
                <StyledOpenChatBtn
                  onClick={() => {
                    window.LC_API.open_chat_window()
                  }}
                />
              ]}
              i18nKey="limit-text"
              t={tFunc}
            />
          </div>
        </StyledLimitContent>
      )}
    </StyledLimitContainer>
  )
}
