import React, { useCallback, useRef } from 'react'
import {
  accountProviderActions,
  selectAccountUser,
  TTypeAuthModal
} from 'astra-core/containers/AccountProvider'
import { useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { formattedPhoneNumber } from 'shared/lib/format'
import { EAdditionalAuthModalsAndPages } from 'entities/auth/model'
import { ModalRef } from 'shared/ui/modal/modal.types'
import { Modal } from 'shared/ui/modal'
import { userApi } from 'entities/legacy-api'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

import {
  StyledContentDataWrapper,
  StyledDataTitle,
  StyledDataValue,
  StyledDataWrapper,
  StyledProfileChangePasswordButton,
  StyledProfileContentWrapper
} from './profile-content.styled'
import { ProfileChangePasswordModal } from './profile-change-password-modal'

const { useLazyGetUserIdentificationStatusQuery } = userApi

export const ProfileContent = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const user = useAppSelector(selectAccountUser)
  const modalRef = useRef<ModalRef>(null)
  const config = useAppSelector(selectConfig)
  const [checkTokenValidity] = useLazyGetUserIdentificationStatusQuery()

  const userLogin = user?.login
  const userPhoneNumber = user?.phone ? formattedPhoneNumber(user.phone) : null

  const handleOpenModal = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    modalRef.current?.open()
  }, [])

  const handleChangePasswordClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (config.IS_TRANSIT) {
      handleOpenModal(e)
    } else {
      dispatch(
        accountProviderActions.setTypeAuthModal(
          EAdditionalAuthModalsAndPages.ChangePassword as unknown as TTypeAuthModal
        )
      )
    }
  }

  const handleModalClose = () => {
    modalRef.current?.close()
    checkTokenValidity()
  }

  return (
    <>
      <StyledProfileContentWrapper>
        <StyledContentDataWrapper>
          <StyledDataWrapper>
            <StyledDataTitle>{t('login')}</StyledDataTitle>
            <StyledDataValue>{userLogin}</StyledDataValue>
          </StyledDataWrapper>
          <StyledDataWrapper>
            <StyledDataTitle>{t('phone')}</StyledDataTitle>
            <StyledDataValue>{userPhoneNumber}</StyledDataValue>
          </StyledDataWrapper>
        </StyledContentDataWrapper>

        <StyledProfileChangePasswordButton
          view="secondary-extra-large"
          onClick={handleChangePasswordClick}
        >
          {t('change user password')}
        </StyledProfileChangePasswordButton>
      </StyledProfileContentWrapper>
      <Modal ref={modalRef} title={t('changing password')}>
        <ProfileChangePasswordModal onClose={handleModalClose} />
      </Modal>
    </>
  )
}
