import styled from 'styled-components'

export const StyledTestCoin = styled.main`
  display: flex;
  flex-direction: column;
`

export const TestCoinContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
`
