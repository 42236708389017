import React from 'react'

import { LayoutProfile } from 'layouts/profile'

import { SupportPage } from './SupportPage'

export const PageSupport = () => (
  <LayoutProfile pageTitle={{ text: 'support' }} isHeaderFilled>
    <SupportPage />
  </LayoutProfile>
)

export default PageSupport
