import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'
import { DropdownContainer } from 'shared/ui/select/select.styled'

export const StyledBreakContainer = styled.div<{ maxHeight: boolean }>`
  ${({ theme, maxHeight }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: 8px;
    padding: 16px;

    ${maxHeight &&
    css`
      background: none;
      height: 100%;
    `}
  `}
`

export const StyledBreakAction = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  ${DropdownContainer} {
    max-height: 330px;
    overflow-y: auto;
  }
`

export const StyledBreakContent = styled.div`
  ${typography['Body / 14 Medium']}
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 720px;

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary']};
  `}

  & ul {
    padding-left: 8px;
  }

  & p {
    white-space: break-spaces;
  }

  & li {
    list-style-position: inside;
    list-style-type: disc;

    & span {
      margin-left: -6px;
    }
  }
`

export const StyledBreakInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
