import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconDocFilled: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M15.35 2.43C15.07 2.15 14.7 2 14.3 2H7.16C7.16 2 6.3 2.18 5.99 2.49L4.49 4.02C4.18 4.34 4 4.77 4 5.21V18.78C4 18.89 4.01 19 4.03 19.11C4.09 19.43 4.25 19.73 4.48 19.97L5.99 21.51C6.3 21.83 6.72 22 7.16 22H16.84C17.28 22 17.7 21.82 18.01 21.51L19.52 19.97C19.75 19.73 19.91 19.43 19.97 19.11C19.99 19 20 18.89 20 18.78V7.7C20 7.31 19.84 6.93 19.57 6.65L15.35 2.43ZM16 10H8C7.44771 10 7 10.4477 7 11C7 11.5523 7.44771 12 8 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10ZM8 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H8C7.44771 16 7 15.5523 7 15C7 14.4477 7.44771 14 8 14Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </Icon>
  )
}
