import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { LayoutProfile } from 'layouts/profile'

import { DocumentsPage } from './DocumentsPage'
import { DOCUMENTS } from './constants'
import { DocumentFrame } from './DocumentFrame'

export const PageDocuments = () => {
  const location = useLocation()

  const documentName = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('name')
  }, [location.search])

  const documentTitle = useMemo(() => {
    return DOCUMENTS.find((item) => item.name === documentName)?.title
  }, [documentName])

  return (
    <LayoutProfile
      buttonBack={{
        to: documentName ? ERoutes.Documents : null
      }}
      pageTitle={{ text: documentTitle || 'documents' }}
    >
      {documentName ? (
        <DocumentFrame documentName={documentName} />
      ) : (
        <DocumentsPage />
      )}
    </LayoutProfile>
  )
}

export default PageDocuments
