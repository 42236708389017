import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconSupport: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m19.614 6.399-3.513-3.513A2.98 2.98 0 0 0 13.985 2h-3.97c-.795 0-1.56.315-2.116.886L4.386 6.399A2.98 2.98 0 0 0 3.5 8.515v.454h-.74a.87.87 0 0 0-.483.137l-.577.393c-.128.088-.2.206-.2.33v4.31c0 .124.072.243.2.33l.577.394a.87.87 0 0 0 .483.136h1.48a.87.87 0 0 0 .483-.137l.577-.393c.128-.087.2-.206.2-.33V8.515c0-.253.096-.499.3-.702L9.322 4.29l.01-.01A.95.95 0 0 1 10.015 4h3.97c.254 0 .499.096.702.3l3.522 3.522.01.01c.174.17.281.414.281.683v6.97a.98.98 0 0 1-.299.7l-.001.002-3.536 3.521-.01.011a.95.95 0 0 1-.684.281H12.5a1 1 0 1 0 0 2h1.47c.795 0 1.56-.315 2.116-.886l3.528-3.513c.57-.57.886-1.32.886-2.116v-.486h.74a.87.87 0 0 0 .483-.137l.577-.393c.128-.087.2-.206.2-.33v-4.31c0-.124-.072-.243-.2-.33l-.577-.394a.87.87 0 0 0-.483-.136h-.74v-.454c0-.795-.315-1.56-.886-2.116"
        fill="currentColor"
      />
      <path
        d="M9.858 5.68c-.878.883-2.214 2.252-2.776 2.828a.93.93 0 0 0-.264.65v5.45c0 .23.086.453.24.624l2.794 3.107a.93.93 0 0 0 .693.31h2.869a.93.93 0 0 0 .693-.31l2.794-3.107a.93.93 0 0 0 .24-.623V9.157a.93.93 0 0 0-.264-.65 534 534 0 0 0-2.776-2.828.93.93 0 0 0-.658-.273h-2.927a.93.93 0 0 0-.658.273"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
