import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconFavoritesFilled40: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.72958 14.3452C3.00772 13.4504 3.80545 12.8446 4.70554 12.8446H12.5929C13.493 12.8446 14.2907 12.2388 14.5688 11.3441L17.007 3.50056C17.2852 2.6058 18.0829 2 18.983 2H21.0128C21.9129 2 22.7107 2.6058 22.9888 3.50056L25.427 11.3441C25.7052 12.2388 26.5029 12.8446 27.403 12.8446H35.2955C36.1956 12.8446 36.9933 13.4504 37.2715 14.3452L37.8983 16.3616C38.1764 17.2563 37.8717 18.2365 37.1435 18.7895L30.7583 23.6385C30.0301 24.1915 29.7254 25.1717 30.0036 26.0664L32.442 33.9107C32.7202 34.8054 32.4155 35.7856 31.6873 36.3386L30.0455 37.5854C29.3173 38.1384 28.3313 38.1384 27.6031 37.5854L21.2191 32.7374C20.4909 32.1844 19.5049 32.1844 18.7767 32.7374L12.3954 37.5835C11.6672 38.1364 10.6811 38.1364 9.95293 37.5835L8.30954 36.3354C7.58135 35.7825 7.27665 34.8023 7.55479 33.9075L9.99225 26.0664C10.2704 25.1717 9.96569 24.1915 9.2375 23.6385L2.85652 18.7927C2.12833 18.2397 1.82362 17.2595 2.10177 16.3648L2.72958 14.3452Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
