import { useCallback } from 'react'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider/slice'
import { selectFavouriteEventsFetchItemsData } from 'astra-core/containers/FavouritesProvider/selectors'

import { useInterval } from 'hooks'
import { useAppDispatch, useAppSelector } from 'shared/lib/@reduxjs'

const FAVOURITE_POLLING_INTERVAL = 5000

export const useFavouriteContainerData = () => {
  const dispatch = useAppDispatch()
  const { loading } = useAppSelector(selectFavouriteEventsFetchItemsData)

  const fetchFavourites = useCallback(() => {
    dispatch(eventsProviderActions.fetchFavouriteEvents())
  }, [dispatch])

  const intervalDelay = loading ? null : FAVOURITE_POLLING_INTERVAL

  useInterval(fetchFavourites, intervalDelay, true)
}
