import { getCurrentLng } from 'astra-core/utils'

export const iframeStyles = {
  width: '100%',
  height: '100%',
  border: 'none',
  outline: 'none',
  font: 'inherit',
  verticalAlign: 'baseline'
}

export const iframeId = 'sportgames-iframe'

export const getIframeData = ({ srcData: { token, userId, launchUrl } }) => {
  const langName = getCurrentLng()
  const tokenParam = userId && token ? `&sessionToken=${token}` : ''

  const src = `${launchUrl}?cid=astrabetcom&productId=sport-games-lobby${tokenParam}&lang=${langName}`

  return {
    id: iframeId,
    styles: iframeStyles,
    src
  }
}
