import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProvider, useFormik } from 'formik'
import { accountProviderActions } from 'astra-core/containers/AccountProvider'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { Button } from 'shared/ui/Button'
import { useRegPromocodesMutation } from 'entities/legacy-api/lib/bonuses'
import { TextInputFormik } from 'features/formik/ui/text-input-formik'
import { UserRegPromocodesResponse } from 'entities/legacy-api/lib/bonuses/bonuses.types'
import { layoutContainerActions } from 'containers/LayoutContainer/slice'
import {
  StyledMyBonusesFormWrapper,
  StyledInputWrapper
} from 'widgets/my-bonuses/my-bonuses.styled'
import { useAppDispatch } from 'shared/lib/@reduxjs'

interface Values {
  newBonus: string
}
export const MyBonusesForm = () => {
  const dispatch = useAppDispatch()
  const [t] = useTranslation()
  const [regPromocode, { isLoading, error }] = useRegPromocodesMutation()

  let errorMessageDesc: string | undefined

  if (error && 'data' in error) {
    const fetchError = error as FetchBaseQueryError
    const errorData = fetchError.data as UserRegPromocodesResponse
    errorMessageDesc = errorData.error.err_desc || undefined
  }

  const form = useFormik<Values>({
    initialValues: {
      newBonus: ''
    },
    onSubmit: async (value, { resetForm }) => {
      const result = await regPromocode({
        platforma: 'NEWOLIMPBET',
        promo: value.newBonus
      })

      if ('data' in result) {
        dispatch(
          layoutContainerActions.setIsShowMyBonusesToastifyNotification(true)
        )
        dispatch(accountProviderActions.fetchLoyalty())
        resetForm()
      }
    },
    validateOnChange: false
  })
  return (
    <FormikProvider value={form}>
      <StyledMyBonusesFormWrapper>
        <StyledInputWrapper>
          <TextInputFormik
            autoComplete="off"
            error={errorMessageDesc}
            errorMessage={errorMessageDesc}
            label={t('promo code')}
            name="newBonus"
            type="text"
            withoutTouched
          />
        </StyledInputWrapper>

        <Button isLoading={isLoading} type="submit" view="primary-extra-large">
          {t('apply')}
        </Button>
      </StyledMyBonusesFormWrapper>
    </FormikProvider>
  )
}
