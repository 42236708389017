import styled, { css } from 'styled-components'

import { IconButton } from 'shared/ui/buttons/IconButton'

import { ILateralTrapezoidSVG } from './bonus-card.types'

export const StyledBonusCard = styled.div`
  ${({ theme }) => css`
    background: ${theme.tokens.colors.surface['surface-base']};
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-l-12'
    ]}px;
    display: flex;
    flex-direction: column;
    overflow: clip;
  `}
`

export const StyledBonusCardImageWrapper = styled.div`
  ${({ theme }) => css`
    height: 158px;
    padding: ${theme.tokens.alias.space['space-xs-8']}px;
    position: relative;
  `}
`

export const StyledDashes = styled.div`
  ${({ theme }) => css`
    background: repeating-linear-gradient(
      to right,
      ${theme.tokens.colors.border['border-primary']},
      ${theme.tokens.colors.border['border-primary']} 6px,
      transparent 6px,
      transparent 12px
    );
    bottom: 0;
    height: 1px;
    left: 9px;
    position: absolute;
    width: 100%;
  `}
`

export const StyledTooltipWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`

export const BonusesFAQButton = styled(IconButton)`
  ${({ theme }) => css`
    border: 1px solid ${theme.tokens.colors.border['border-default-gray']};
  `}
`

export const StyledPromoCardImage = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.tokens.alias['border-radius'][
      'border-radius-m-8'
    ]}px;
    height: 142px;
    outline: 1px solid none;
    outline-offset: -1px;
    width: 344px;
  `}
`

export const StyledBonusTypeWrapper = styled.div`
  bottom: -10px;
  display: flex;
  height: 20px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
`

export const StyledBonusType = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.tokens.colors.surface['surface-brand-default']};
    display: flex;
    margin-left: -1px;
    margin-right: -1px;
    padding: ${theme.tokens.alias.space['space-xxxxs-2']}px
      ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledLateralTrapezoidForBonusType = styled.svg<ILateralTrapezoidSVG>`
  ${({ theme, fillColor, rotateDeg }) => css`
    transform: rotateY(${rotateDeg ? `${rotateDeg}deg` : '0deg'});

    path,
    rect {
      fill: ${fillColor
        ? theme.tokens.colors.surface[fillColor]
        : theme.tokens.colors.surface['surface-brand-default']};
    }
  `}
`

export const StyledBonusCardContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-m-16']}px;
    padding: ${theme.tokens.alias.space['space-l-20']}px
      ${theme.tokens.alias.space['space-m-16']}px
      ${theme.tokens.alias.space['space-m-16']}px;
    position: relative;

    &::before,
    &::after {
      background: ${theme.tokens.colors.surface['surface-page']};
      border-radius: 50%;
      content: '';
      height: 12px;
      position: absolute;
      width: 12px;
      z-index: 1;
    }

    &::before {
      left: -6px;
      top: -6px;
    }

    &::after {
      right: -6px;
      top: -6px;
    }
  `}
`

export const StyledBonusCardDesc = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xs-8']}px;
    text-align: center;
  `}
`
