import React, { FC, useEffect } from 'react'
import {
  selectAccountUser,
  selectBetGamesToken,
  selectFetchBetGamesTokenRequest,
  selectFetchUserRequest
} from 'astra-core/containers/AccountProvider'
import { Config, selectConfig } from 'astra-core/containers/ConfigProvider'
import { VirtualGamesToken } from 'betweb-openapi-axios'
import { getCurrentLng } from 'astra-core/utils'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { useAppSelector } from 'shared/lib/@reduxjs'

import { StyledLoaderPointsWrapper } from './BetGames.styled'

const BET_GAMES_DOM_NODE = 'betgames-iframe-content'

const removeBetGames = (betGamesScript) => {
  document.head.removeChild(betGamesScript)
  document.getElementById(BET_GAMES_DOM_NODE)?.firstElementChild?.remove() // To remove the iFrame added by BetGames script
}

const loadBetGames = (
  config: Config,
  betGamesToken?: VirtualGamesToken['token']
) => {
  const clientUrl = config.BETGAMES_CLIENT_URL
  const script = document.createElement('script')

  script.onload = () => {
    window.BetGames.setup({
      containerId: BET_GAMES_DOM_NODE,
      clientUrl,
      apiUrl: config.BETGAMES_SERVER,
      partnerCode: config.BETGAMES_PARTNER_CODE,
      partnerToken: betGamesToken || '-',
      language: getCurrentLng(),
      timezone: new Date().getTimezoneOffset() / 60
    })
  }

  script.type = 'text/javascript'
  script.src = `${clientUrl}/public/betgames.js?${new Date()}`

  document.head.appendChild(script)

  return script
}

export const BetGames: FC = () => {
  const user = useAppSelector(selectAccountUser)

  const { loading: fetchUserLoading } = useAppSelector(selectFetchUserRequest)

  const { loading: fetchBetGamesTokenLoading } = useAppSelector(
    selectFetchBetGamesTokenRequest
  )

  const loaded =
    fetchUserLoading === false && (!user || fetchBetGamesTokenLoading === false)

  // console.log('loaded', loaded)

  return loaded ? (
    <BetGamesScript />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const BetGamesScript = () => {
  const betGamesToken = useAppSelector(selectBetGamesToken)
  const config = useAppSelector(selectConfig)

  useEffect(() => {
    // console.log('--LOAD', betGamesToken?.token)
    const betGamesScript = loadBetGames(config, betGamesToken?.token)
    return () => {
      // console.log('--REMOVE', betGamesToken?.token)
      removeBetGames(betGamesScript)
    }
  }, [betGamesToken?.token, config])

  return <div id={BET_GAMES_DOM_NODE} />
}
