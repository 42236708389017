import { IconBalanceOfFilled24 } from 'shared/ui/Icon/General/IconBalanceOfFilled24'
import { IconCoef } from 'shared/ui/Icon/General/IconCoef'
import { IconProfile } from 'shared/ui/Icon/General/IconProfile'
import { IconTabbarCouponFilled24 } from 'shared/ui/Icon/General/IconTabbarCouponFilled24'
import { IconTabbarLineFilled24 } from 'shared/ui/Icon/General/IconTabbarLineFilled24'
import { IconWallet } from 'shared/ui/Icon/General/IconWallet'

export const SUPPORT_CARDS = [
  {
    icon: IconWallet,
    name: 'support.faq.cards.account.heading',
    text: 'support.faq.cards.account.description',
    categoryId: 1
  },
  {
    icon: IconBalanceOfFilled24,
    name: 'support.faq.cards.topup.heading',
    text: 'support.faq.cards.topup.description',
    categoryId: 2
  },
  {
    icon: IconTabbarCouponFilled24,
    name: 'support.faq.cards.bets_info.heading',
    text: 'support.faq.cards.bets_info.description',
    categoryId: 3
  },
  {
    icon: IconTabbarLineFilled24,
    name: 'support.faq.cards.bets_types.heading',
    text: 'support.faq.cards.bets_types.description',
    categoryId: 4
  },
  {
    icon: IconCoef,
    name: 'support.faq.cards.bets_calc.heading',
    text: 'support.faq.cards.bets_calc.description',
    categoryId: 5
  },
  {
    icon: IconProfile,
    name: 'support.faq.cards.security.heading',
    text: 'support.faq.cards.security.description',
    categoryId: 6
  },
  {
    icon: IconTabbarLineFilled24,
    name: 'support.faq.cards.responsible_gambling.heading',
    text: 'support.faq.cards.responsible_gambling.description',
    categoryId: 7
  }
]
