import { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'

import { ColumnLayoutContent, ColumnLayoutTitle } from 'shared/lib/layout'
import { NavTabsWrapper } from 'widgets/bets-history-legacy/components/History/components/SectionSelect/SectionSelect.styled'
import { StyledFilter } from 'widgets/bets-history-legacy/components/History/components/Filter/Filter.styled'

import { ProfileProps } from './profile.types'

export const StyledContentProfile = styled(ColumnLayoutContent)<{
  pageTitle?: string
}>`
  ${({ pageTitle, theme }) => css`
    grid-template-rows: ${pageTitle ? 'auto minmax(0, 1fr)' : 'minmax(0, 1fr)'};

    ${NavTabsWrapper} {
      position: sticky;
      top: 112px;
      z-index: 10;
    }

    ${StyledFilter} {
      background-color: ${theme.tokens.colors.surface['surface-page']};
      position: sticky;
      top: 160px;
      z-index: 10;
    }
  `}
`

export const StyledContentTitleProfile = styled(ColumnLayoutTitle)<
  PropsWithChildren<Pick<ProfileProps, 'isHeaderFilled'>>
>`
  ${({ theme, isHeaderFilled }) =>
    css`
      position: sticky;
      top: 48px;
      z-index: 20;

      ${isHeaderFilled &&
      css`
        background-color: ${theme.tokens.colors.surface['surface-base']};
      `}
    `}
`

export const BackLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`
