import React, { useEffect } from 'react'
import { resultsProviderActions } from 'astra-core/containers/ResultsProvider'

import { useAppDispatch } from 'shared/lib/@reduxjs'

import { ResultsMenuList } from './components'
import { StyledResultsMenu } from './results-menu.styled'

export const ResultsMenu = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resultsProviderActions.fetchResultCounters({}))
  }, [dispatch])

  return (
    <StyledResultsMenu>
      <ResultsMenuList />
    </StyledResultsMenu>
  )
}
