import { FC } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import {
  EVENT_CARD_DATE_FORMAT,
  EVENT_CARD_TIME_FORMAT
} from '../../../../entities/date/lib'

import { EventCardLineContentProps } from './event-card-line-content.types'
import * as S from './event-card-line-content.styled'

export const EventCardLineContent: FC<EventCardLineContentProps> = ({
  event
}) => {
  return (
    <S.LineContentWrapper>
      <S.EventDate>
        {dayjs(event.eventDate).format(EVENT_CARD_DATE_FORMAT)}
      </S.EventDate>
      <S.EventTime>
        {dayjs(event.eventDate).format(EVENT_CARD_TIME_FORMAT)}
      </S.EventTime>
    </S.LineContentWrapper>
  )
}
