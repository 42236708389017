import { useCallback, useEffect, useState } from 'react'

export const useSourceLoaded = (source: string) => {
  const [sourceLoaded, setSourceLoaded] = useState<string>('')

  const setImage = useCallback(() => setSourceLoaded(source), [source])

  useEffect(() => {
    const img = new Image()
    img.addEventListener('load', setImage, false)
    img.src = source
    img.onerror = () => setSourceLoaded('')
    return () => {
      img.removeEventListener('load', setImage)
    }
  }, [source, setImage])

  return sourceLoaded
}

export const useSourcesLoaded = (sourceImages: string[]) => {
  const [images, setImages] = useState<string[]>(sourceImages.map(() => ''))

  const setImageLoaded = useCallback(
    (index: number) => () => {
      setImages((prev) => {
        return Object.assign([], prev, {
          [index]: sourceImages[index]
        })
      })
    },
    [sourceImages]
  )

  useEffect(() => {
    sourceImages.forEach((image, index) => {
      const img = new Image()
      img.addEventListener('load', setImageLoaded(index), false)
      img.src = image
      img.onerror = () => ''
      return () => {
        img.removeEventListener('load', setImageLoaded(index))
      }
    })
  }, [setImageLoaded, sourceImages])

  return { images, loaded: images.every(Boolean) }
}
