import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { eventsProviderActions } from 'astra-core/containers/EventsProvider'
import { selectCurrentLangCode } from 'astra-core/containers/LanguagesProvider'
import { ErrorType } from 'astra-core'
import {
  BannersApi,
  EntityTag,
  Sport,
  TagFilterOperation
} from 'betweb-openapi-axios'
import { ApiManager } from 'astra-core/api'
import { selectSports } from 'astra-core/containers/CommonDataProvider'
import { PayloadAction } from '@reduxjs/toolkit'

import { eventsProviderSideEffects } from 'shared/lib/eventsProviderSideEffects'
import {
  getLiveEventsCurrentSportId,
  getMainPageLiveEventsIds,
  getTopEventsCurrentSportId,
  getTopEventsIds,
  selectLiveEventsFilters
} from 'pages/page-main/MainPageContainer/selectors'
import { selectEntityTags } from 'entities/event/model'
import { getTopTagIds } from 'entities/event/model/constants'

import { mainPageContainerActions } from './slice'
import { FetchTopSuccessPayload } from './types'

function* handleFetchBanners() {
  try {
    const bannersApi = ApiManager.getApi(BannersApi)
    const currentLangCode = yield select(selectCurrentLangCode)

    const { data } = yield call(() =>
      bannersApi.getBanners({ locale: currentLangCode })
    )

    yield put(mainPageContainerActions.fetchBannersSuccess(data))
  } catch (e) {
    console.log('Error occurred while fetching bets statistics', e)
    yield put(
      mainPageContainerActions.fetchBannersError({
        type: ErrorType.RESPONSE_ERROR,
        message: (e as Error).message,
        code: e.code,
        title: '',
        reasons: e.reasons
      })
    )
  }
}

function* handleFetchTopEvents() {
  const currentLangCode = yield select(selectCurrentLangCode)
  const entityTags: Dictionary<EntityTag> = yield select(selectEntityTags)

  if (!getTopTagIds(entityTags).length) {
    yield put(
      eventsProviderSideEffects.mainPageTopEvents.onSuccess({
        resData: {
          items: []
        }
      })
    )
    return
  }

  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        tagIds: getTopTagIds(entityTags),
        locale: currentLangCode,
        tagFilterOperation: TagFilterOperation.Loose
      },
      sideEffects: eventsProviderSideEffects.mainPageTopEvents
    })
  )
}

function* handleFetchTopEventsSuccess(
  action: PayloadAction<FetchTopSuccessPayload>
) {
  const topEventsIds = yield select(getTopEventsIds)
  const topEventsCurrentSportId = yield select(getTopEventsCurrentSportId)
  const { items } = action.payload.resData
  const sports: Dictionary<Sport> = yield select(selectSports)
  const eventIdsBySport: Dictionary<number[]> = {}
  items.forEach((item) => {
    let { sportId } = item.tournament
    const sport = sports[item.tournament.sportId]
    if (sport.genericSportId) {
      sportId = sport.genericSportId
    }
    if (!eventIdsBySport[sportId]) {
      eventIdsBySport[sportId] = []
    }
    eventIdsBySport[sportId].push(item.id)
  })

  if (
    !topEventsIds.length ||
    topEventsIds.length !== eventIdsBySport[topEventsCurrentSportId].length
  ) {
    yield put(mainPageContainerActions.setTopEvents(eventIdsBySport))
  }
}

function* handleFetchLiveEvents() {
  const filters = yield select(selectLiveEventsFilters)
  const currentLangCode = yield select(selectCurrentLangCode)
  const entityTags: Dictionary<EntityTag> = yield select(selectEntityTags)

  // TODO: handle with translation
  yield put(
    eventsProviderActions.fetchEvents({
      reqData: {
        live: true,
        tagIds: filters.isTop ? getTopTagIds(entityTags) : [],
        locale: currentLangCode,
        tagFilterOperation: TagFilterOperation.Loose
      },
      sideEffects: eventsProviderSideEffects.mainPageLiveEvents
    })
  )
}

function* handleFetchLiveEventsSuccess(
  action: PayloadAction<FetchTopSuccessPayload>
) {
  const liveEventsIdsList = yield select(getMainPageLiveEventsIds)
  const currentSportId = yield select(getLiveEventsCurrentSportId)
  const { items } = action.payload.resData
  const sports: Dictionary<Sport> = yield select(selectSports)
  const eventIdsBySport: Dictionary<number[]> = {}
  items.forEach((item) => {
    let { sportId } = item.tournament
    const sport = sports[item.tournament.sportId]
    if (sport.genericSportId) {
      sportId = sport.genericSportId
    }
    if (!eventIdsBySport[sportId]) {
      eventIdsBySport[sportId] = []
    }
    eventIdsBySport[sportId].push(item.id)
  })

  if (liveEventsIdsList.length !== eventIdsBySport[currentSportId].length) {
    yield put(mainPageContainerActions.setLiveEvents(eventIdsBySport))
  }
}

export function* mainPageContainerSaga() {
  yield all([
    takeLatest(mainPageContainerActions.fetchBanners, handleFetchBanners),
    takeLatest(mainPageContainerActions.fetchTopEvents, handleFetchTopEvents),
    takeLatest(mainPageContainerActions.fetchLiveEvents, handleFetchLiveEvents),
    takeLatest(
      mainPageContainerActions.fetchLiveEventsSuccess,
      handleFetchLiveEventsSuccess
    ),
    takeLatest(
      mainPageContainerActions.fetchTopEventsSuccess,
      handleFetchTopEventsSuccess
    )
  ])
}
